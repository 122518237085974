import apiUrl from "../../pages/config";
import ApiRequestType from "../apiRequestType";
import ApiRequestor from "../apiRequestor";
import axios from "../axios";

const ZodiacService = () => {
    const requestor = new ApiRequestor(axios);
    const getZodiacs = async () => {
        const url = `${apiUrl}/horoscope/zodiacs`;
        const apiResponse = await requestor.makeRequest(ApiRequestType.GET, url)
        return apiResponse;
    }
    return { getZodiacs };
}

export default ZodiacService
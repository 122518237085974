import apiUrl from "../../pages/config";
import ApiRequestType from "../apiRequestType";
import ApiRequestor from "../apiRequestor";
import axios from "../axios";

const KundliService = () => {
  const requestor = new ApiRequestor(axios);

  const getKundliResponse = async (urlKey, data) => {
    const url = `${apiUrl}/ext/astrology/${urlKey}`;
    const apiResponse = await requestor.makeRequest(
      ApiRequestType.POST,
      url,
      data
    );
    if (apiResponse.result) return apiResponse.data;
    return null;
  };

  const getKundliChart = async (apiKey, data) => {
    const url = `${apiUrl}/ext/astrology/horo_chart_image/${apiKey}`;
    const apiResponse = await requestor.makeRequest(
      ApiRequestType.POST,
      url,
      data
    );
    return apiResponse;
  };

  return {
    getKundliResponse,
    getKundliChart,
  };
};

export default KundliService;

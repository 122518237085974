const ApiRequestType = Object.freeze({
  GET: "get",
  GET_FILE: "get_file",
  POST: "post",
  PUT: "put",
  PUT_FILE: "put_file",
  PATCH: "patch",
});

export default ApiRequestType;

import { useNavigate } from "react-router-dom";
import React, { useEffect, useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ZodiacSlider from "../../../components/ZodiacSlider";
import { scrollToTop } from "../../../utils/scrollToTop";
import { useVisible } from "../../../hooks/useVisible";

const WeeklyHoroscopeZodiac = () => {
  const navigate = useNavigate();
  const homeHoroscopeRef = useRef();
  const { isVisible, observerRef } = useVisible(homeHoroscopeRef);

  useEffect(() => {
    if (isVisible) observerRef.current.unobserve(homeHoroscopeRef.current);
  }, [isVisible]);

  const handleZodiacClick = (details, selectedSlideNumber) => {
    navigate("/horoscope_daily", {
      state: {
        initialSelectedZodiacDetails: details,
        initialSlideNumber: selectedSlideNumber,
      },
    });
    scrollToTop();
  };

  return (
    <>
      <section
        className="category-section-2 tw-mb-10 lg:tw-mb-14"
        ref={homeHoroscopeRef}
      >
        <div className="container-fluid-lg">
          <div className="title">
            <h2 className="main-heading">Daily Horoscope</h2>
          </div>
          {isVisible && (
            <ZodiacSlider
              zodiacSelectHandler={handleZodiacClick}
              setZodiacOnLoad={false}
              initialSlideNumber={null}
            />
          )}
        </div>
      </section>
    </>
  );
};

export default WeeklyHoroscopeZodiac;

import useAxiosPrivateHook from "../../hooks/useAxiosPrivate";
import apiUrl from "../../pages/config";
import ApiRequestType from "../apiRequestType";
import ApiRequestor from "../apiRequestor";
import axios from "../axios";

const NeushopService = () => {
  const requestor = new ApiRequestor(axios);
  const axiosPrivate = useAxiosPrivateHook();
  const authenticatedRequestor = new ApiRequestor(axiosPrivate);

  const getProductsList = async (page) => {
    const url = `${apiUrl}/neu_shop/product?page=${page}`;
    const apiResponse = await requestor.makeRequest(ApiRequestType.GET, url);
    return apiResponse;
  };

  const getProductsListWithSearchFilter = async (page, productName) => {
    const url = `${apiUrl}/neu_shop/product/search?page=${page}&productName=${productName}`;
    const apiResponse = await requestor.makeRequest(ApiRequestType.GET, url);
    return apiResponse;
  };

  const getProductsListWithCategoryFilter = async (page, category) => {
    const url = `${apiUrl}/neu_shop/product/filter?page=${page}&category=${category}`;
    const apiResponse = await requestor.makeRequest(ApiRequestType.GET, url);
    return apiResponse;
  };

  const getAllProductCategory = async () => {
    const url = `${apiUrl}/neu_shop/product/category`;
    const apiResponse = await requestor.makeRequest(ApiRequestType.GET, url);
    return apiResponse;
  };

  const getProductDetail = async (productId) => {
    const url = `${apiUrl}/neu_shop/product/${productId}`;
    const apiResponse = await requestor.makeRequest(ApiRequestType.GET, url);
    return apiResponse;
  };

  const addProduct = async (reqData) => {
    const url = `${apiUrl}/neu_shop/cart/product/add`;
    const apiResponse = await authenticatedRequestor.makeRequest(
      ApiRequestType.POST,
      url,
      reqData
    );
    return apiResponse;
  };

  const removeProduct = async (reqData) => {
    const url = `${apiUrl}/neu_shop/cart/product/remove`;
    const apiResponse = await authenticatedRequestor.makeRequest(
      ApiRequestType.POST,
      url,
      reqData
    );
    return apiResponse;
  };

  const updateCartProduct = async (reqData) => {
    const url = `${apiUrl}/neu_shop/cart/product/update`;
    const apiResponse = await authenticatedRequestor.makeRequest(
      ApiRequestType.POST,
      url,
      reqData
    );
    return apiResponse;
  };

  const getCartProducts = async () => {
    const url = `${apiUrl}/neu_shop/cart`;
    const apiResponse = await authenticatedRequestor.makeRequest(
      ApiRequestType.GET,
      url
    );
    return apiResponse;
  };

  const orderProducts = async (reqData) => {
    const url = `${apiUrl}/neu_shop/order/`;
    const apiResponse = await authenticatedRequestor.makeRequest(
      ApiRequestType.POST,
      url,
      reqData
    );
    return apiResponse;
  };

  const postAddress = async (orderId, reqData) => {
    const url = `${apiUrl}/neu_shop/order/${orderId}/address`;
    const apiResponse = await authenticatedRequestor.makeRequest(
      ApiRequestType.POST,
      url,
      reqData
    );
    return apiResponse;
  };

  const getOrderPaymentDetails = async (reqData) => {
    const url = `${apiUrl}/neu_shop/order/payment/`;
    const apiResponse = await authenticatedRequestor.makeRequest(
      ApiRequestType.POST,
      url,
      reqData
    );
    return apiResponse;
  };

  const checkoutOrder = async (transactionId) => {
    const url = `${apiUrl}/neu_shop/order/payment/${transactionId}/initialize`;
    const apiResponse = await authenticatedRequestor.makeRequest(
      ApiRequestType.POST,
      url
    );
    return apiResponse;
  };

  const getOrderedProductList = async () => {
    const url = `${apiUrl}/neu_shop/order`;
    const apiResponse = await authenticatedRequestor.makeRequest(
      ApiRequestType.GET,
      url
    );
    return apiResponse;
  };

  const getOrderDetails = async (orderId) => {
    const url = `${apiUrl}/neu_shop/order/${orderId}`;
    const apiResponse = await authenticatedRequestor.makeRequest(
      ApiRequestType.GET,
      url
    );
    return apiResponse;
  };

  const getDeliveryCities = async () => {
    const url = `${apiUrl}/neu_shop/delivery_city`;
    const apiResponse = await authenticatedRequestor.makeRequest(
      ApiRequestType.GET,
      url
    );
    return apiResponse;
  };

  const getOrderDeliveryDetails = async (orderId) => {
    const url = `${apiUrl}/neu_shop/order_delivery/customer/${orderId}`;
    const apiResponse = await authenticatedRequestor.makeRequest(
      ApiRequestType.GET,
      url
    );
    return apiResponse;
  };

  return {
    getProductsList,
    getProductsListWithSearchFilter,
    getProductsListWithCategoryFilter,
    getAllProductCategory,
    getProductDetail,
    addProduct,
    removeProduct,
    updateCartProduct,
    getCartProducts,
    orderProducts,
    postAddress,
    getOrderPaymentDetails,
    checkoutOrder,
    getOrderedProductList,
    getOrderDetails,
    getDeliveryCities,
    getOrderDeliveryDetails,
  };
};

export default NeushopService;

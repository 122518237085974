import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    items: [],
    totalAmount: 0,
    totalItems: 0,
  },
  reducers: {
    addItem: (state, action) => {
      state.totalItems += 1;
      for (let item of state.items) {
        if (item._id === action.payload._id) {
          item.count = item.count + 1;
          state.totalAmount = state.totalAmount + Number(item.price?.amount);
          return;
        }
      }
      state.items.push(action.payload);
      state.totalAmount =
        state.totalAmount + Number(action.payload.price?.amount);
    },
    removeItem: (state, action) => {
      state.items = state.items.filter((item) => {
        if (item._id === action.payload) {
          state.totalItems -= 1;
          if (item.count === 1) {
            state.totalAmount = state.totalAmount - Number(item.price?.amount);
            return false;
          }
          item.count--;
          state.totalAmount = state.totalAmount - Number(item.price?.amount);
        }
        return true;
      });
    },
    deleteItems: (state, action) => {
      const deletedItem = state.items.find(
        (item) => item._id === action.payload
      );
      if (deletedItem) {
        state.totalAmount =
          state.totalAmount -
          Number(deletedItem.price?.amount * deletedItem.count);
        state.totalItems -= Number(deletedItem.count);
        state.items = state.items.filter((item) => item._id !== action.payload);
      }
    },

    hydrateCart: (state, action) => {
      state.items = action.payload?.products;
      state.totalAmount = action.payload.totalCost;
      state.totalItems = action.payload?.products?.reduce(
        (acc, currentItem) => {
          return (acc += currentItem.quantity);
        },
        0
      );
    },
  },
});

export default cartSlice.reducer;
export const { addItem, removeItem, deleteItems, hydrateCart } =
  cartSlice.actions;

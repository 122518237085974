import React, { useEffect, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import HoroscopeService from "../../../api/services/horoscopeService";

const DailyZodiacHoroscopeDetails = ({ zodiacDetail, period }) => {
  const { getZodiac } = HoroscopeService();
  const [horoscopeDetails, setHoroscopeDetails] = useState(null);

  const fetchDailyZodiacDetails = async () => {
    if (!zodiacDetail) return;
    try {
      const { result, data } = await getZodiac(period, zodiacDetail.id);
      if (!result) {
        setHoroscopeDetails("Coming soon!");
        return;
      }
      setHoroscopeDetails(data);
    } catch (error) {
      setHoroscopeDetails("Coming soon!");
    }
  };

  useEffect(() => {
    fetchDailyZodiacDetails();
  }, [zodiacDetail]);

  return (
    <Tabs defaultIndex={1}>
      <section className="seller-grid-section">
        <div className="container-fluid-lg">
          <div className="row seller-grid-box">
            <div className="col-12">
              <TabList className="tablist-cont p-0 horoscope-tab-shadow">
                <Tab
                  selectedClassName="selected-tab-item tw-bg-theme-color"
                  className="horoscope-tab-item"
                >
                  Yesterday
                </Tab>
                <Tab
                  selectedClassName="selected-tab-item tw-bg-theme-color"
                  className="horoscope-tab-item"
                >
                  Today
                </Tab>
                <Tab
                  selectedClassName="selected-tab-item tw-bg-theme-color"
                  className="horoscope-tab-item"
                >
                  Tomorrow
                </Tab>
              </TabList>
            </div>
            <div className="!tw-flex !tw-gap-x-2 tw-py-4">
              <img
                className="img2 lazyload"
                src={`../assets/images/astro/${zodiacDetail?.name.toLowerCase()}.png`}
                alt="zodiac"
                loading="lazy"
                width="130"
                height="130"
              />
              <TabPanel>
                <p>{horoscopeDetails?.yesterday}</p>
              </TabPanel>
              <TabPanel>
                <p>{horoscopeDetails?.today}</p>
              </TabPanel>
              <TabPanel>
                <p>{horoscopeDetails?.tomorrow}</p>
              </TabPanel>
            </div>
          </div>
        </div>
      </section>
    </Tabs>
  );
};

export default DailyZodiacHoroscopeDetails;

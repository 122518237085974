import React from "react";
import KundaliMilanBanner from "./components/KundaliMilanBanner";
import useHashFragment from "../../hooks/useHashFragment";
import KundaliMilanForm from "./components/KundaliMilanForm";
import KundliMilanBottomText from "./components/KundliMilanBottomText";
import FeaturePanel from "../../components/FeaturePanel";
import Breadcrumb from "../../components/Breadcrumb";

function KundaliMilan() {
  useHashFragment(130);
  const routes = [{ text: "Kundli Milan" }];

  return (
    <>
      <section className="section-b-space shop-section tw-p-0">
        <div className="container-fluid-lg">
          <Breadcrumb routes={routes} />
        </div>
      </section>
      <KundaliMilanBanner />
      <KundaliMilanForm />
      <br />
      <KundliMilanBottomText />
      <br />
      <FeaturePanel />
      <br />
      <br />
    </>
  );
}

export default KundaliMilan;

import React, { useEffect, useRef, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import getAddress from "../../utils/googleAddress";
import useRefreshToken from "../../hooks/useRefreshToken";
import VerifyOtp from "./VerifyOtp";
import LoginForm from "./LoginForm";
import { scrollToTop } from "../../utils/scrollToTop";
import Breadcrumb from "../../components/Breadcrumb";

function LoginPage() {
  const location = useLocation();
  const from = location.state?.pathname || "/";
  const navigate = useNavigate();
  const otpId = useRef(null);
  const [isSentOtp, setIsSentOtp] = useState(false);
  const refresh = useRefreshToken();
  const inputValue = useRef({
    mobileNo: "",
    countryCode: "",
    countryFlag: "IN",
  });
  const routes = [{ text: "Login" }];

  useEffect(() => {
    const _refreshTokenAndNavigate = async () => {
      var accessToken = await refresh();
      if (accessToken == null) {
        return;
      }
      navigate(from, { replace: true, state: location.state });
    };
    scrollToTop();
    _refreshTokenAndNavigate();
  }, []);

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          let address = await getAddress(latitude, longitude);

          secureLocalStorage.setItem("latitude", latitude);
          secureLocalStorage.setItem("longitude", longitude);
          if (address) secureLocalStorage.setItem("browserAddress", address);
        },
        (_error) => {
          ///console.error("Error getting geolocation:", error.message);
        }
      );
    } else {
      //console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  return (
    <>
      <ToastContainer />
      {/* <!-- Breadcrumb Section Start --> */}
      <section className="section-b-space shop-section tw-p-0">
        <div className="container-fluid-lg">
          <Breadcrumb routes={routes} />
        </div>
      </section>

      {/* <!-- log in section start --> */}
      <section className="log-in-section otp-section section-b-space">
        <div className="container-fluid-lg w-100">
          <div className="row">
            <div className="col-xxl-6 col-xl-5 col-lg-5 d-lg-block d-none ms-auto">
              <div className="image-contain">
                <img
                  src="../assets/images/inner-page/loginSignUp.jpeg"
                  className="img-fluid lazyload"
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>

            <div className="col-xxl-4 col-xl-5 col-lg-6 col-md-8 col-sm-9 col-12 mx-auto ">
              <div className="log-in-box d-flex align-items-center justify-content-center h-100 bg-gradient-pink card-shadow">
                <div className="log-in-title">
                  <h3>Welcome to NeuAstro</h3>
                  {isSentOtp ? (
                    <VerifyOtp inputValue={inputValue} otpId={otpId} />
                  ) : (
                    <LoginForm
                      setIsSentOtp={setIsSentOtp}
                      otpId={otpId}
                      inputValue={inputValue}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- log in section end --> */}
    </>
  );
}

export default LoginPage;

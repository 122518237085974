import {createSlice} from "@reduxjs/toolkit"

const astrologerSlice = createSlice({
    name : "astrologer",
    initialState : {
        groupCount : 0
    },
    reducers : {
        addGroupCount : (state, action) => {
           state.groupCount = action.payload;
        }
    }
})

export default astrologerSlice.reducer;
export const {addGroupCount} = astrologerSlice.actions;
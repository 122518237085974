import formatDigitOfTime from "./formatTimeDateDigit";

export const formatDate = (day, month, year) => {
  return `${day < 10 ? "0" + day : day}-${
    month < 10 ? "0" + month : month
  }-${year}`;
};

export const formatTime = (time) => {
  if (typeof time === "string") {
    const timeArr = time.split(":").slice(0, 2);
    for (let i = 0; i < timeArr.length; i++) {
      if (Number(timeArr[i]) < 10 && String(timeArr[i]).length < 2) {
        timeArr[i] = "0" + timeArr[i];
      }
    }
    return timeArr.join(":");
  }
};

export const changeTimeFormat = (timeString, splitString) => {
  if (splitString === " ") {
    const timeStr = timeString.split(splitString).slice(1, 2)[0];
    return formatTime(timeStr);
  }
  const timeArr = timeString.split(splitString).slice(0, 2);
  const timeStart = formatTime(timeArr[0]);
  const timeEnd = formatTime(timeArr[1]);
  return timeStart + " to " + timeEnd;
};

export const formatTimeWithHour = (timeInSeconds) => {
  const hours = Math.floor(timeInSeconds / 3600000);
  const minutes = Math.floor((timeInSeconds % 3600000) / 60000);
  const seconds = Math.floor((timeInSeconds % 60000) / 1000);

  return `${formatDigitOfTime(hours)}:${formatDigitOfTime(
    minutes
  )}:${formatDigitOfTime(seconds)}`;
};

export const dateInDDMMYYYY = (unformatedDate) => {
  const formatedDate = unformatedDate.split("-").reverse().join("-");
  return formatedDate;
};

export const formatConversationDuration = (timeInSeconds) => {
  let duration = "";
  const hours = Math.floor(timeInSeconds / 3600);
  const minutes = Math.floor((timeInSeconds % 3600) / 60);
  const seconds = Math.floor(timeInSeconds % 60);

  if (hours) {
    duration += hours.toString() + "h ";
  }
  if (minutes) {
    duration += minutes.toString() + "m ";
  }
  if (seconds) {
    duration += seconds.toString() + "s";
  }

  return duration;
};

export const convertSecondsInMinSec = (timeInSeconds) => {
  const minutes = Math.floor((timeInSeconds % 3600) / 60);
  const seconds = Math.floor(timeInSeconds % 60);

  return `${formatDigitOfTime(minutes)}:${formatDigitOfTime(seconds)}`;
};

import React from "react";

const BottomDetailSection = () => {
  return (
    <section>
      <div className="container-fluid-lg">
        <div className="title" style={{ padding: " 5px" }}>
          <h2>Understanding Panchang</h2>
          <p>
            Accuracy of attributes depending upon the Moon&apos;s motions were
            considered most crucial for the reliability of a Panchang, because
            the Moon is the fastest among all heavenly entities shown in
            traditional Panchang. Tithi, Nakshatra, Rasi, Yoga, and Karana
            depend upon Moon&apos;s motions, which are five in number. Panchang
            is a Sanskrit word it means &quot; five limbs&quot;. If these five
            limbs which represents five attributes depending upon Moon, are
            accurate, an almanac is considered accurate, because it&apos;s easy
            to calculate other elements due to their slow rates of change.
          </p>
          <p>The five components of the Panchang are:</p>
          <ul className="shifted-list">
            <li>
              <b>Tithi (Lunar Day):</b> The phase of the Moon, with 30 Tithis in
              a lunar month. Each Tithi has a specific influence on rituals and
              activities.
            </li>
            <li>
              <b>Vara (Day of the Week):</b> There are seven days in a week,
              each associated with a specific planet and considered auspicious
              or inauspicious for certain activities.
            </li>
            <li>
              <b>Nakshatra (Lunar Mansion):</b> The Moon&apos;s position in one
              of the 27 Nakshatras or star constellations, each influencing
              different aspects of life.
            </li>
            <li>
              <b>Yoga:</b> A combination of the Sun and Moon&apos;s longitudes,
              dividing the zodiac into 27 parts.
            </li>
            <li>
              <b>Karana:</b> Half of a Tithi, resulting in 11 Karanas in a lunar
              month. Each Karana is associated with a specific influence.
            </li>
          </ul>
          <p>
            The Panchang also includes additional information such as the
            sunrise and sunset times, moonrise and moonset times, the position
            of the planets, and specific planetary combinations or yoga, which
            are considered auspicious or inauspicious.
          </p>
          <p>
            Panchangs are consulted for various purposes, including determining
            auspicious times for ceremonies, festivals, and important life
            events. They play a crucial role in aligning activities with
            favorable celestial influences according to Hindu traditions and
            astrology. Many people refer to Panchangs for guidance in planning
            their daily activities in alignment with astrological principles.
          </p>
        </div>
      </div>
    </section>
  );
};

export default BottomDetailSection;

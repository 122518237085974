import React from "react";

const Loader = () => {
  return (
    <span className="sm:tw-w-4 tw-w-3 tw-animate-spin tw-h-3 sm:tw-h-4 tw-relative tw-z-20 tw-border-4 tw-rounded-full tw-border-gray-400 tw-border-solid tw-border-t-gray-100 tw-inline-block"></span>
  );
};

export const SpinnerLoader = () => {
  return (
    <div className="custom-spinner-loader tw-w-[30px] tw-h-[30px] sm:tw-w-[40px] sm:tw-h-[40px] tw-rounded-full"></div>
  );
};

export default Loader;

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import HomeBannerSecond from "../HomeBannerSecond";
import ZodiacHoroscopeDetails from "../../components/ZodiacHoroscopeDetails";
import ZODIAC_PERIOD from "../../constants/zodiacPeriods";
import ZodiacSlider from "../../components/ZodiacSlider";
import HoroscopeLinksComponent from "./component/HoroscopeLinksComponent";
import FeaturePanel from "../../components/FeaturePanel";
import { scrollToTop } from "../../utils/scrollToTop";
import AllHoroscopeBottomList from "./component/AllHoroscopeBottomList";

const HoroscopeWeekly = () => {
  const location = useLocation();
  const initialSlideNumber = location.state?.initialSlideNumber;

  const [zodiacDetail, setZodiacDetail] = useState(() => {
    return location.state?.initialSelectedZodiacDetails;
  });

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleZodiacClick = (detail, _) => {
    setZodiacDetail(detail);
  };

  return (
    <>
      <section className="home-section tw-mt-6 md:tw-mt-10">
        <div className="container-fluid-lg">
          <img
            align="right"
            src="../assets/images/logo/weekly-horoscope.webp"
            width="100%"
            alt="weekly-horoscope"
            loading="lazy"
          />
          <div className="row g-4"></div>
        </div>
      </section>
      {/* Top detailed Paragraph starts */}
      <section>
        <div className="container-fluid-lg">
          <div className="title">
            <h2 style={{ textAlign: "center" }}>Weekly Horoscope</h2>
            <p>
              Weekly horoscopes are typically prepared by astrologers who
              analyze the movements and positions of celestial bodies such as
              planets and stars in relation to the zodiac signs. Astrologers
              consider factors such as the current planetary transits, aspects
              between planets, and the overall astrological climate to provide
              insights into the upcoming week for each zodiac sign. The process
              involves interpreting how these astrological influences might
              impact different areas of life such as love, career,
              relationships, and health for individuals born under each zodiac
              sign. Astrologers use their knowledge of astrology, symbolism, and
              intuition to craft the weekly horoscope forecasts tailored to the
              characteristics and tendencies associated with each zodiac sign.
            </p>
            <p>
              While some astrologers rely solely on traditional astrological
              techniques, others may incorporate modern interpretations or
              additional elements such as Tarot cards or planetary movements in
              their forecasting process. Ultimately, the preparation of a weekly
              horoscope involves a combination of astrological expertise,
              intuition, and an understanding of current astrological trends.
            </p>
          </div>
        </div>
      </section>
      {/* Top detailed Paragraph ends */}

      <section className="category-section-2">
        <div className="container-fluid-lg">
          <HoroscopeLinksComponent selectedComponent="weekly" />
          <ZodiacSlider
            zodiacSelectHandler={handleZodiacClick}
            setZodiacOnLoad={zodiacDetail == null}
            initialSlideNumber={initialSlideNumber}
          />
        </div>
      </section>

      <ZodiacHoroscopeDetails
        zodiacDetail={zodiacDetail}
        period={ZODIAC_PERIOD.WEEKLY}
      />

      <HomeBannerSecond />
      <section>
        <div className="container-fluid-lg">
          <div className="title" style={{ padding: " 5px" }}>
            <h2>Benefits of Weekly Horoscope</h2>
            <span className="title-leaf">
              <svg className="icon-width">
                <Link to="https://themes.pixelstrap.com/fastkart/assets/svg/leaf.svg#leaf"></Link>
              </svg>
            </span>

            <p>
              Weekly horoscope predictions are based on constellation of star,
              planetary transits and movements as per astrology. This
              constellation is then deciphered and interpreted by esteemed
              astrologers to give you the best & near accurate predictions /
              guidance for the week.Reading weekly horoscope can help us in
              guiding our actions throughout the week.
            </p>
            <p>
              A horoscope is a forecast of a person&apos;s future based on the
              positions of celestial bodies (such as planets and stars) at a
              specific time, usually the time of their birth. It is commonly
              associated with astrology and is used to provide insights into
              various aspects of life, including personality traits,
              relationships, and potential future events. Horoscopes are often
              divided into twelve astrological signs, each representing
              different periods of the year. People often read horoscopes to
              gain a better understanding of themselves or to get predictions
              about their future.
            </p>
            <p>
              Weekly horoscopes provide a brief astrological forecast for a
              specific time frame—usually a week. Here&apos;s how they can be
              perceived to help:
            </p>
            <ul className="shifted-list">
              <li>
                <b>Guidance: </b>Weekly horoscopes can offer guidance on
                potential opportunities and challenges in the upcoming week,
                allowing individuals to make informed decisions.
              </li>
              <li>
                <b>Awareness: </b>By reading a weekly horoscope, people might
                become more aware of their emotions, reactions, and the
                influences of celestial bodies. This self-awareness can
                contribute to personal growth.
              </li>
              <li>
                <b>Preparation: </b>Forewarned is forearmed. Knowing about
                potential challenges in advance can help individuals prepare
                mentally and emotionally, making it easier to navigate
                situations.
              </li>
              <li>
                <b>Reflection: </b>Horoscopes often encourage reflection on
                personal goals, relationships, and life choices. This
                introspection can be valuable for individuals seeking to align
                their actions with their aspirations.
              </li>
            </ul>
          </div>
        </div>
      </section>
      <AllHoroscopeBottomList />
      <FeaturePanel />
      <br />
    </>
  );
};

export default HoroscopeWeekly;

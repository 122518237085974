import React from "react";

export const dayOptionsGenerator = () => {
  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  return days.map((day) => <option key={day}>{day}</option>);
};

export const monthOptionsGenerator = () => {
  return Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
    <option key={month} value={month}>
      {new Date(0, month - 1).toLocaleString("default", { month: "long" })}
    </option>
  ));
};

export const yearOptionsGenerator = (maxYear) => {
  const currentYear = new Date().getFullYear();
  const years = Array.from(
    { length: (maxYear || currentYear) - 1950 + 1 },
    (_, i) => (maxYear || currentYear) - i
  );
  return years.map((year) => <option key={year}>{year}</option>);
};

export const hourOptionsGenerator = () => {
  const hours = Array.from({ length: 24 }, (_, i) => i);
  return hours.map((hour) => (
    <option key={hour}>{hour.toString().padStart(2, "0")}</option>
  ));
};

export const minuteOptionsGenerator = () => {
  const minutes = Array.from({ length: 60 }, (_, i) => i);
  return minutes.map((minute) => (
    <option key={minute}>{minute.toString().padStart(2, "0")}</option>
  ));
};

import React from "react";
import { useNavigate } from "react-router-dom";

const LiveAstrologerCard = ({ astrologerData }) => {
  const navigate = useNavigate();
  const handleLiveAstrologer = () => {
    navigate("/streaming", {
      state: {
        liveStreamAppId: astrologerData?.liveStreamAppId,
        liveStreamRoomId: astrologerData?.liveStreamRoomId,
        liveStreamToken: astrologerData?.liveStreamToken,
        liveStreamingId: astrologerData?.liveStreamingId,
      },
    });
  };

  return (
    <div
      className="product-box-4 tw-p-0 tw-cursor-pointer tw-flex tw-flex-col tw-relative tw-overflow-hidden"
      onClick={handleLiveAstrologer}
    >
      <div className="grid-image tw-pt-3">
        <div className="image tw-w-full">
          <div className="tw-border-[3px] tw-mx-auto tw-border-solid tw-border-red-500 tw-rounded-full tw-relative tw-w-[90px] tw-h-[90px]">
            <div className="tw-w-[85px] tw-h-[85px] tw-rounded-full tw-overflow-hidden">
              <img
                src={astrologerData.astrologerDetails?.profilePic}
                className="img-fluid tw-w-full tw-h-full tw-rounded-full lazyload"
                alt="profile-pic"
                loading="lazy"
              />
            </div>
            <div className="tw-absolute -tw-translate-x-1/2 tw-bottom-0 tw-left-1/2">
              <button className="tw-bg-red-500 tw-text-[11px] tw-tracking-wider tw-rounded-xl tw-border-none tw-text-white tw-px-3 tw-py-[2px] tw-flex tw-justify-center tw-items-center">
                LIVE
                <li className="tw-ml-1 flex-item-center">
                  <i className="fa-solid fa-circle tw-text-[8px] tw-text-white"></i>
                </li>
              </button>
            </div>
          </div>
          <h3 className="tw-text-base sm:tw-text-lg overflow-ellipsis tw-my-2">
            {astrologerData.astrologerDetails?.name}
          </h3>
        </div>
      </div>
      <div className="tw-py-1.5 tw-w-full tw-bg-theme-color tw-text-black">
        <h3 className="tw-font-bold tw-text-base sm:tw-text-[20px]">
          Watch Now
        </h3>
      </div>
    </div>
  );
};

export default LiveAstrologerCard;

import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import FeaturePanel from "../components/FeaturePanel";
import Breadcrumb from "../components/Breadcrumb";

function Numerology() {
  const [error, setError] = useState("");
  const navigate = useNavigate("");
  const [formData, setFormData] = useState({
    name: "",
    day: "",
    month: "",
    year: "",
    placeOfBirth: "",
  });
  const routes = [{ text: "Numerology" }];

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.day) {
      setError("Please Select a day.");
      return;
    }

    if (!formData.month) {
      setError("Please a Month .");
      return;
    }

    if (!formData.year) {
      setError("Please select a year.");
      return;
    }
    navigate("/ruling", {
      state: {
        selectedDay: formData.day,
        selectedMonth: formData.month,
        selectedYear: formData.year,
      },
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const generateDayOptions = () => {
    const days = Array.from({ length: 31 }, (_, i) => i + 1);
    return days.map((day) => <option key={day}>{day}</option>);
  };

  const generateMonthOptions = () => {
    return Array.from({ length: 12 }, (_, i) => i + 1).map((month) => (
      <option key={month} value={month}>
        {new Date(0, month - 1).toLocaleString("default", { month: "long" })}
      </option>
    ));
  };

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = Array.from(
      { length: currentYear - 1950 + 1 },
      (_, i) => currentYear - i
    );
    return years.map((year) => <option key={year}>{year}</option>);
  };

  return (
    <div>
      <section className="tw-mb-0 tw-mt-0 tw-pt-7">
        <div className="container-fluid-lg tw-mb-0">
          <Breadcrumb routes={routes} />
        </div>
      </section>
      <section className="blog-section section-b-space">
        <div className="container-fluid-lg">
          <div className="row g-4">
            <div className="col-xxl-12 col-xl-12 col-lg-12 order-lg-2">
              <div className="row g-4 ratio_65">
                <div>
                  <h2
                    style={{
                      color: "#fc0",
                      fontSize: "32px",
                      fontWeight: "700",
                    }}
                    className="text-center font_yellow text-uppercase"
                  >
                    Numerology
                  </h2>
                </div>

                <div className="title">
                  <p>
                    Numerology is the study of the mystical significance of
                    numbers and their influence on human life. It often involves
                    assigning specific meanings to numbers and analyzing their
                    patterns to gain insights into various aspects of a
                    person&apos;s existence, such as personality, relationships,
                    and life events.
                  </p>
                  <p>
                    Most of the Numerology Predictions are based on your ruling
                    number and it plays a key role in deciding your destiny.
                    Your ruling number helps you decide what&apos;s best for
                    you. Make career choices based on your ruling number.
                  </p>

                  <p>
                    {" "}
                    This will surely help you overcome obstacles on the way and
                    achieve success in life. Numerology can also help you find
                    the perfect match for you. You can get accurate numerology
                    readings by finding out what your ruling number is. Your
                    ruling number is calculated by adding the numbers of your
                    birth date. The numbers are added till a single digit is
                    obtained, which becomes the numerology or ruling number.
                    Your numerology number consists of the blueprint to your
                    life, and the various aspects in it.
                  </p>
                </div>

                <div className="col-xxl-4 col-sm-12 tw-py-4 sm:tw-px-4 tw-border tw-border-solid tw-border-gold-color tw-rounded-md sm:tw-w-3/4 lg:tw-w-1/2 tw-mx-auto">
                  <form onSubmit={handleSubmit}>
                    <div className="custom-form">
                      <div className="sm:tw-text-xl tw-text-base tw-mb-2.5">
                        Get Ruling Number Predictions
                      </div>
                      <div className="tw-flex tw-items-center tw-justify-between">
                        <div className="tw-grid tw-grid-cols-3 tw-gap-x-2 tw-w-[calc(100%-108px)] sm:tw-w-[calc(100%-140px)]">
                          <select
                            name="day"
                            value={formData.day}
                            onChange={handleInputChange}
                            className="tw-h-[35px] tw-cursor-pointer form-control tw-py-0"
                          >
                            <option>Day</option>
                            {generateDayOptions()}
                          </select>
                          <select
                            name="month"
                            value={formData.month}
                            onChange={handleInputChange}
                            className="tw-h-[35px] tw-cursor-pointer form-control tw-py-0"
                          >
                            <option>Month</option>
                            {generateMonthOptions()}
                          </select>
                          <select
                            name="year"
                            value={formData.year}
                            onChange={handleInputChange}
                            className="tw-h-[35px] tw-cursor-pointer form-control tw-py-0"
                          >
                            <option>Year</option>
                            {generateYearOptions()}
                          </select>
                        </div>
                        <div>
                          <button
                            type="submit"
                            className="btn tw-bg-theme-color btn-md fw-bold tw-rounded-lg tw-text-white tw-shadow-lg hover:tw-shadow-sm tw-h-[35px] tw-ml-2 sm:tw-ml-5 hover:tw-bg-opacity-80 flex-item-center tw-w-[100px] sm:tw-w-[120px]"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                      <div className="tw-w-full">
                        <font>
                          {error && <p className="tw-text-red-600">{error}</p>}
                        </font>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="title">
                  <p>
                    Numerology and astrology are distinct practices, but they
                    share the common idea that cosmic forces or energies
                    influence aspects of our lives.
                    <ul className="shifted-list">
                      <li>
                        <b>Numerology:</b> Involves assigning mystical meanings
                        to numbers and analyzing patterns derived from names or
                        birthdates to gain insights into an individual&apos;s
                        characteristics and life path.
                      </li>
                      <li>
                        <b>Astrology:</b> Focuses on the positions and movements
                        of celestial bodies, such as planets and stars, at the
                        time of a person&apos;s birth. Astrologers use birth
                        charts to interpret how these celestial configurations
                        might influence an individual&apos;s personality,
                        relationships, and future.
                      </li>
                    </ul>
                  </p>
                  <p>
                    Numerology involves various concepts, each associated with
                    specific meanings and interpretations. Here are a few key
                    concepts in numerology:
                    <ul className="shifted-list">
                      <li>
                        <b>Life Path Number:</b> Calculated from the date of
                        birth, it represents the main characteristics and traits
                        that an individual carries throughout their life.
                      </li>{" "}
                      <li>
                        <b>Expression (Destiny) Number:</b> Derived from the
                        letters in a person&apos;s full birth name, it reflects
                        one&apos;s talents, strengths, and potential life path.
                      </li>{" "}
                      <li>
                        <b>Soul Urge (Heart&apos;s Desire) Number:</b>{" "}
                        Calculated from the vowels in the full birth name, it
                        reveals a person&apos;s inner motivations, desires, and
                        emotional needs.
                      </li>{" "}
                      <li>
                        <b>Personality Number:</b> Determined from the
                        consonants in the full birth name, it reflects the
                        outward traits and characteristics that others perceive.
                      </li>{" "}
                      <li>
                        <b>Birth Day Number:</b> Derived from the day of the
                        month on which a person is born, it can provide
                        additional insights into personality traits.
                      </li>{" "}
                      <li>
                        <b>Master Numbers:</b> In numerology, 11, 22, and 33 are
                        considered &quot;master numbers,&quot; and they are not
                        reduced to a single-digit. They are believed to carry a
                        higher level of spiritual significance.
                      </li>{" "}
                      <li>
                        <b>Numerology Chart:</b> A chart or analysis that
                        incorporates various numerology numbers to provide a
                        comprehensive overview of an individual&apos;s
                        characteristics and life path.
                      </li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FeaturePanel />
      <br />
    </div>
  );
}

export default Numerology;

import React from "react";

const LazyLoadUI = () => {
  return (
    <div className="container-fluid-lg tw-py-6 container-fluid py-0 my-0">
      <div className="tw-bg-gray-200 tw-w-full pulse-animation tw-rounded-lg tw-h-[70vh]"></div>
    </div>
  );
};

export default LazyLoadUI;

import React from "react";

const AstrologerMessage = ({ message }) => {
  return (
    <div className="direct-chat-msg">
      <div className="direct-chat-text tw-w-max tw-max-w-[80%] tw-bg-white tw-ml-0">
        <p className="tw-m-0 tw-py-1">{message}</p>
      </div>
    </div>
  );
};

export default AstrologerMessage;

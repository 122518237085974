import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import {
  dayOptionsGenerator,
  hourOptionsGenerator,
  minuteOptionsGenerator,
  monthOptionsGenerator,
  yearOptionsGenerator,
} from "../../../utils/dobBirthTimeGenerator";
import { scrollToTop } from "../../../utils/scrollToTop";
import {
  defaultPanchangAddress,
  defaultPanchangLat,
  defaultPanchangLng,
} from "../../config";
import KundliService from "../../../api/services/kundliService";
import { useVisible } from "../../../hooks/useVisible";

const Panchang = () => {
  const [responseData, setResponseData] = useState(null);
  const [_kundliResponseData, setKundliResponseData] = useState(null);
  const [address, setAddress] = useState("");
  const [error, setError] = useState("");
  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();
  const navigate = useNavigate();
  const [details, setDetails] = useState();
  const { getKundliResponse } = KundliService();
  const [formData, setFormData] = useState({
    name: "",
    gender: "",
    day: "",
    month: "",
    year: "",
    hour: "",
    min: "",
    am_pm: "",
    placeOfBirth: "",
  });
  const panchangRef = useRef();
  const { isVisible, observerRef } = useVisible(panchangRef);

  const getAdvancePanchangResponse = async () => {
    const apiKey = "advanced_panchang";
    const reqData = {
      day: day,
      month: month,
      year: year,
      hour: 10,
      min: 25,
      lat: details?.lat ? details?.lat : defaultPanchangLat,
      lon: details?.lng ? details?.lng : defaultPanchangLng,
      tzone: 5.5,
    };

    try {
      const apiResponse = await getKundliResponse(apiKey, reqData);
      if (apiResponse) {
        setResponseData({
          ...apiResponse,
          address: defaultPanchangAddress,
        });
      }
    } catch (error) {}
  };

  const getBirthDetailsResponse = async () => {
    const apiKey = "birth_details";
    const reqData = {
      day: Number(formData.day),
      month: Number(formData.month),
      year: Number(formData.year),
      hour: Number(formData.hour),
      min: Number(formData.min),
      lat: details?.lat ? details?.lat : defaultPanchangLat,
      lon: details?.lng ? details?.lng : defaultPanchangLng,
      tzone: 5.5,
      name: formData.name,
      birthplace: address,
      gender: formData.gender,
    };

    try {
      const apiResponse = await getKundliResponse(apiKey, reqData);
      if (apiResponse) {
        setKundliResponseData(apiResponse);
        navigate("/self_kundali", {
          state: {
            selectedLocation: address,
            responseData: apiResponse,
          },
        });
        scrollToTop();
      }
    } catch (error) {}
  };

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setDetails(latLng);
    setAddress(value);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (!isVisible) return;
    getAdvancePanchangResponse();
    observerRef.current.unobserve(panchangRef.current);
  }, [isVisible]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.name) {
      setError("Please Enter a Name.");
      return;
    } else if (!formData.gender) {
      setError("Please select a Gender.");
      return;
    } else if (!formData.day) {
      setError("Please Select a day.");
      return;
    } else if (!formData.month) {
      setError("Please a Month .");
      return;
    } else if (!formData.year) {
      setError("Please select a year.");
      return;
    } else if (!formData.hour) {
      setError("Please select a Hour.");
      return;
    } else if (!formData.min) {
      setError("Please a minute.");
      return;
    } else if (!address) {
      setError("Please Enter Bithplace.");
      return;
    }

    getBirthDetailsResponse();
  };

  return (
    <>
      <section className="blog-section section-b-space" ref={panchangRef}>
        <div className="container-fluid-lg">
          <div className="row g-4">
            <div className="col-xxl-12 col-xl-12 col-lg-12 order-lg-2">
              <div className="row g-4 ratio_65">
                <div className="slider-3 arrow-slider grid-card-container">
                  <div>
                    <div className="blog-box ratio_50">
                      <div className="blog-box-image">
                        <Link to="/panchang_page">
                          <img
                            src="../assets/images/blog/todayPanchang.webp"
                            className="img-fluid bg-img card-image lazyload"
                            alt="panchang-pic"
                            loading="lazy"
                          />
                        </Link>
                      </div>
                      <div className="blog-contain custom-scroll horoscope-card-details card-details-container">
                        <div>
                          <Link to="/panchang_page">
                            <h3 className="card-heading">Panchang</h3>
                          </Link>
                        </div>
                        <div className="panchang-card-details tw-text-[15px]">
                          <Link to="/panchang_page">
                            <ul>
                              <table className="home_table tw-w-full">
                                <tbody>
                                  <tr>
                                    <td scope="row">Day : </td>
                                    <td>
                                      {responseData?.day} {day}/{month}/{year}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td scope="row">Location : </td>
                                    <td>{responseData?.address}</td>
                                    <hr />
                                  </tr>
                                  <tr>
                                    <td scope="row">Tithi : </td>
                                    <td>
                                      {responseData?.tithi.details.tithi_name}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td scope="row">Paksha : </td>
                                    <td> {responseData?.paksha}</td>
                                  </tr>
                                  <tr>
                                    <td scope="row">Sunrise : </td>
                                    <td id="sunRise">
                                      {responseData?.sunrise}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td scope="row">Sunset : </td>
                                    <td id="sunSet">{responseData?.sunset}</td>
                                  </tr>
                                  <tr>
                                    <td scope="row">Moonrise : </td>
                                    <td id="moonRise">
                                      {" "}
                                      {responseData?.moonrise}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td scope="row">Nakshatra : </td>
                                    <td>
                                      {" "}
                                      {responseData?.nakshatra.details.nak_name}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td scope="row">Nakshtra Till : </td>
                                    <td id="nakshtra">
                                      {responseData?.nakshatra.end_time.hour}:
                                      {responseData?.nakshatra.end_time.minute}:
                                      {responseData?.nakshatra.end_time.second}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td scope="row">Yog : </td>
                                    <td>
                                      {responseData?.yog.details.yog_name}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td scope="row">Yog Till : </td>
                                    <td id="yog">
                                      {responseData?.yog.end_time.hour} :{" "}
                                      {responseData?.yog.end_time.minute} :{" "}
                                      {responseData?.yog.end_time.second}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td scope="row">Karan I : </td>
                                    <td>
                                      {responseData?.karan.details.karan_name}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td scope="row">Surya Rashi : </td>
                                    <td>Pisces</td>
                                  </tr>
                                  <tr>
                                    <td scope="row">Chandra Rashi : </td>
                                    <td>Virgo</td>
                                  </tr>
                                  <tr>
                                    <td scope="row">Rahu Kal : </td>
                                    <td id="rahuKal">
                                      {responseData?.rahukaal.start} to{" "}
                                      {responseData?.rahukaal.end}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </ul>
                          </Link>
                          <Link to="/panchang_page">
                            <button className="blog-button flex-item-center">
                              Read More
                              <i className="fa-solid fa-right-long"></i>
                            </button>{" "}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="blog-box ratio_50">
                      <div className="blog-box-image">
                        <Link to="/kundali#kundali-form">
                          <img
                            src="../assets/images/news/ganesh-pothi.webp"
                            className="img-fluid bg-img card-image lazyload"
                            alt="kundli-pic"
                            loading="lazy"
                          />
                        </Link>
                      </div>
                      <div className="blog-contain custom-scroll horoscope-card-details card-details-container">
                        <div className="row">
                          <Link to="/kundali#kundali-form">
                            <h3 className="card-heading">Kundli Making</h3>
                          </Link>
                        </div>
                        <div className="panchang-card-details grid-col-1">
                          <div className="grid-col-2">
                            <div>
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                placeholder="Name"
                                value={formData.name}
                                onChange={handleInputChange}
                              />
                            </div>
                            <div>
                              <select
                                id="gender"
                                name="gender"
                                className="form-select"
                                value={formData.gender}
                                onChange={handleInputChange}
                                placeholder="Gender"
                              >
                                <option value="" disabled selected hidden>
                                  Gender
                                </option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                              </select>
                            </div>
                          </div>
                          <div className="grid-col-3">
                            <div>
                              <select
                                name="day"
                                className="form-select"
                                value={formData.day}
                                onChange={handleInputChange}
                              >
                                <option value="" disabled selected hidden>
                                  Day
                                </option>
                                {dayOptionsGenerator()}
                              </select>
                            </div>
                            <div>
                              <select
                                className="form-select"
                                name="month"
                                value={formData.month}
                                onChange={handleInputChange}
                              >
                                <option value="" disabled selected hidden>
                                  Month
                                </option>
                                {monthOptionsGenerator()}
                              </select>
                            </div>
                            <div>
                              <select
                                className="form-select"
                                name="year"
                                value={formData.year}
                                onChange={handleInputChange}
                              >
                                <option value="" disabled selected hidden>
                                  Year
                                </option>
                                {yearOptionsGenerator()}
                              </select>
                            </div>
                          </div>
                          <div className="grid-col-2">
                            <div>
                              <select
                                name="hour"
                                className="form-select"
                                value={formData.hour}
                                onChange={handleInputChange}
                              >
                                <option value="" disabled selected hidden>
                                  Hour
                                </option>
                                {hourOptionsGenerator()}
                              </select>
                            </div>
                            <div>
                              <select
                                name="min"
                                className="form-select"
                                value={formData.min}
                                onChange={handleInputChange}
                              >
                                <option value="" disabled selected hidden>
                                  Minute
                                </option>
                                {minuteOptionsGenerator()}
                              </select>
                            </div>
                          </div>
                          <div>
                            <div>
                              <div className="mb-0 custom-form">
                                <div className="custom-input">
                                  <PlacesAutocomplete
                                    value={address}
                                    onChange={(newValue) =>
                                      setAddress(newValue)
                                    }
                                    onSelect={handleSelect}
                                  >
                                    {({
                                      getInputProps,
                                      suggestions,
                                      getSuggestionItemProps,
                                      loading,
                                    }) => (
                                      <div>
                                        <input
                                          className="form-control"
                                          {...getInputProps({
                                            placeholder: "Type address",
                                          })}
                                        />
                                        <div>
                                          {loading && <div>Loading...</div>}
                                          {suggestions.map(
                                            (suggestion, index) => {
                                              const style = {
                                                backgroundColor:
                                                  suggestion.active
                                                    ? "#41b6e6"
                                                    : "#fff",
                                              };
                                              return (
                                                <div
                                                  key={index}
                                                  {...getSuggestionItemProps(
                                                    suggestion,
                                                    {
                                                      style,
                                                    }
                                                  )}
                                                >
                                                  {suggestion.description}
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </PlacesAutocomplete>
                                </div>
                              </div>
                              <font style={{ color: "blue" }}>
                                {error && <span>{error}</span>}
                              </font>
                            </div>
                          </div>
                          <div>
                            <div>
                              <button
                                type="submit"
                                className="blog-button flex-item-center"
                                onClick={handleSubmit}
                              >
                                Get Kundli
                                <i className="fa-solid fa-right-long"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="blog-box ratio_50">
                      <div className="blog-box-image">
                        <Link to="/kundali_milan#kundali-milan-form">
                          <img
                            src="../assets/images/news/match.webp"
                            className="img-fluid bg-img card-image lazyload"
                            alt="kundli-making-pic"
                            loading="lazy"
                          />
                        </Link>
                      </div>
                      <div className="blog-contain custom-scroll horoscope-card-details card-details-container md:tw-overflow-hidden">
                        <div className="row">
                          <Link to="/kundali_milan#kundali-milan-form">
                            <h3 className="card-heading">
                              Kundli Match Making
                            </h3>
                          </Link>
                        </div>
                        <div className="panchang-card-details">
                          <Link to="/kundali_milan#kundali-milan-form">
                            <p>
                              Kundli Milan, also known as Kundali Matching, is a
                              traditional Vedic astrology practice in Hindu
                              culture where the birth charts of a couple are
                              compared to assess their compatibility for
                              marriage. It involves analyzing various factors
                              like Guna Milan (matching of characteristics),
                              Manglik Dosha (Mars affliction), and other
                              astrological parameters to determine the
                              compatibility and harmony between the couple.
                            </p>
                          </Link>
                          <Link to="/kundali_milan#kundali-milan-form">
                            <button className="blog-button flex-item-center">
                              Match With Your Partner
                              <i className="fa-solid fa-right-long"></i>
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Panchang;

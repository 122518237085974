import { Link } from "react-router-dom";
import React from "react";
import { PiShoppingCartSimpleThin } from "react-icons/pi";

const FeaturePanel = () => {
  return (
    <>
      {/* <!-- Category Section Start --> */}
      <section>
        <div className="container-fluid-lg">
          <div className="row">
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="all"
                role="tabpanel"
                aria-labelledby="all-tab"
              >
                <div className="live-container">
                  <div className="wow fadeInUp product-box-4">
                    <Link to="/astrologers_list" className="live-link">
                      <div className="product-image">
                        <img
                          src="../assets/images/inner-page/product/chat.png"
                          className="img-fluid lazyload"
                          alt="chat-img"
                          loading="lazy"
                        />
                      </div>
                      <div className="product-detail">
                        <p className="name feature-panel-card-heading tw-m-0 tw-p-0 tw-font-medium md:tw-py-1 tw-text-[11px] sm:tw-text-[15px]">
                          Chat with Astrologer
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div
                    className="wow fadeInUp product-box-4"
                    data-wow-delay="0.05s"
                  >
                    <Link to="/astrologers_list" className="live-link">
                      <div className="product-image">
                        <img
                          src="../assets/images/inner-page/product/call.png"
                          className="img-fluid lazyload "
                          alt="call-img"
                          loading="lazy"
                        />
                      </div>
                      <div className="product-detail">
                        <p className="name feature-panel-card-heading tw-m-0 tw-p-0 tw-font-medium md:tw-py-1 tw-text-[11px] sm:tw-text-[15px]">
                          Call Astrologer
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div
                    className="wow fadeInUp product-box-4"
                    data-wow-delay="0.25s"
                  >
                    <Link to="/neu_shop" className="live-link">
                      <div className="product-image sm:tw-mb-1">
                        <PiShoppingCartSimpleThin
                          alt="neushop-img"
                          className="img-fluid tw-text-[40px] sm:tw-text-[50px] md:tw-text-[60px]  tw-text-[#f3892b] tw-font-light sm:tw-mb-[5px]"
                        />
                      </div>
                      <div className="product-detail">
                        <p className="name feature-panel-card-heading tw-m-0 tw-p-0 tw-font-medium md:tw-py-1 tw-text-[11px] sm:tw-text-[15px]">
                          NeuShop
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div
                    className="wow fadeInUp product-box-4"
                    data-wow-delay="0.45s"
                  >
                    <Link to="/offline_puja" className="live-link">
                      <div className="product-image">
                        <img
                          src="../assets/images/inner-page/product/pujaPath.png"
                          className="img-fluid lazyload"
                          alt="puja-path-img"
                          loading="lazy"
                        />
                      </div>
                      <div className="product-detail">
                        <p className="name feature-panel-card-heading tw-m-0 tw-p-0 tw-font-medium md:tw-py-1 tw-text-[11px] sm:tw-text-[15px]">
                          Puja Path
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Category Section End --> */}
    </>
  );
};

export default FeaturePanel;

import React, { Suspense } from "react";
import LazyLoadUI from "./LazyLoadUI";
import { Outlet } from "react-router-dom";

const SuspenceLayout = () => {
  return (
    <Suspense fallback={<LazyLoadUI />}>
      <Outlet />
    </Suspense>
  );
};

export default SuspenceLayout;

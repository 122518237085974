const formatDigitOfTime = (time) => {
  if (time < 10) {
    return "0" + time;
  } else {
    return time.toString();
  }
};

export const formatTimeInMinSec = (unformatedTime) => {
  const timeArr = unformatedTime.split(":");
  return `${formatDigitOfTime(timeArr[0])}:${formatDigitOfTime(timeArr[1])}`;
};

export default formatDigitOfTime;

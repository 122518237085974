import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { scrollToTop } from "../../utils/scrollToTop";
import AuthContext from "../../context/AuthProvider";

const HeaderModal = ({
  handleHamburgerClick,
  logoutHandler,
  showHamBurger,
}) => {
  const { auth } = useContext(AuthContext);
  const [showHoroscopeDropdown, setShowHoroscopeDropdown] = useState(false);
  const [showPujaDropdown, setShowPujaDropdown] = useState(false);
  const [showKundaliDropdown, setShowKundaliDropdown] = useState(false);
  const [showOthersDropdown, setShowOthersDropdown] = useState(false);

  const closeHeaderSidebar = () => {
    scrollToTop();
    handleHamburgerClick();
    setShowHoroscopeDropdown(false);
    setShowHoroscopeDropdown(false);
    setShowHoroscopeDropdown(false);
  };

  const handleOnRouteClick = (event) => {
    event.stopPropagation();
    closeHeaderSidebar();
  };

  const handleOnClick = () => {
    closeHeaderSidebar();
  };

  return (
    <div
      className={`header-model-container ${
        showHamBurger ? "tw-w-full" : "tw-w-0"
      }`}
      onClick={handleOnClick}
    >
      <div
        className="header-model custom-scroll"
        onClick={(event) => event.stopPropagation()}
      >
        <div className="register-box">
          <Link to="/expert/" target="_blank" onClick={handleOnRouteClick}>
            <button className="chat-btn btn-hover">
              Register as Astrologer
            </button>
          </Link>
        </div>
        <div
          className="flex-item-center cancel-container"
          onClick={handleHamburgerClick}
        >
          <div className="tw-bg-gray-500 cancel"></div>
        </div>
        <ul className="flex-column tw-w-full  target-model-list">
          <li>
            <Link className="nav-link" to="/" onClick={handleOnRouteClick}>
              Home
            </Link>
          </li>
          <li>
            <Link
              className=" nav-link"
              to="/astrologers_list"
              onClick={handleOnRouteClick}
            >
              Hello Astrologer
            </Link>
          </li>
          <li
            className="flex-item-between"
            onClick={() => setShowHoroscopeDropdown(!showHoroscopeDropdown)}
          >
            <a className="nav-link">Horoscope</a>
            <span
              className={`carot 
                ${
                  showHoroscopeDropdown ? "carot-rotate" : "after:-tw-bottom-3"
                }`}
            ></span>
          </li>
          {showHoroscopeDropdown && (
            <ul className="flex-column tw-w-full nested-list">
              <li className="first-list-item tw-pl-2">
                <Link
                  className="nav-link"
                  to="/horoscope_daily"
                  onClick={handleOnRouteClick}
                >
                  Daily Horoscope
                </Link>
              </li>
              <li className="tw-pl-2">
                <Link
                  className="nav-link"
                  to="/horoscope_page"
                  onClick={handleOnRouteClick}
                >
                  Weekly Horoscopes
                </Link>
              </li>

              <li className="tw-pl-2">
                <Link
                  className="nav-link"
                  to="/horoscope_monthly"
                  onClick={handleOnRouteClick}
                >
                  Monthly Horoscope
                </Link>
              </li>

              <li className="last-list-item tw-pl-2">
                <Link
                  className="nav-link"
                  to="/horoscope_yearly"
                  onClick={handleOnRouteClick}
                >
                  Yearly Horoscope
                </Link>
              </li>
            </ul>
          )}
          <li className="nav-item">
            <Link
              className="nav-link"
              to="/panchang_page"
              onClick={handleOnRouteClick}
            >
              Panchang
            </Link>
          </li>
          <li
            className="flex-item-between"
            onClick={() => setShowKundaliDropdown(!showKundaliDropdown)}
          >
            <a className="nav-link">Kundali</a>
            <span
              className={`carot
                ${showKundaliDropdown ? "carot-rotate" : "after:-tw-bottom-3"}`}
            ></span>
          </li>
          {showKundaliDropdown && (
            <ul
              className="
                flex-column tw-w-full nested-list"
            >
              <li className="first-list-item tw-pl-2">
                <Link
                  className="nav-link"
                  to="/kundali#kundali-form"
                  onClick={handleOnRouteClick}
                >
                  Kundli
                </Link>
              </li>
              <li className="last-list-item tw-pl-2">
                <Link
                  className="nav-link"
                  to="/kundali_milan#kundali-milan-form"
                  onClick={handleOnRouteClick}
                >
                  Kundli Milan
                </Link>
              </li>
            </ul>
          )}
          {/* <li
            className="flex-item-between"
            onClick={() => setShowPujaDropdown(!showPujaDropdown)}
          >
            <a className="nav-link">Puja Path</a>
            <span
              className={`carot
                ${showPujaDropdown ? "carot-rotate" : "after:-tw-bottom-3"}`}
            ></span>
          </li>
          {showPujaDropdown && (
            <ul
              className="
                flex-column tw-w-full nested-list"
            >
              <li className="first-list-item tw-pl-2">
                <Link
                  className="nav-link"
                  to="/online_puja"
                  onClick={handleOnRouteClick}
                >
                  Online Puja
                </Link>
              </li>
              <li className="last-list-item tw-pl-2">
                <Link
                  className="nav-link"
                  to="/offline_puja"
                  onClick={handleOnRouteClick}
                >
                  Offline Puja
                </Link>
              </li>
            </ul>
          )} */}

          <li className="nav-item">
            <Link
              className="nav-link"
              to="/offline_puja"
              onClick={handleOnRouteClick}
            >
              Puja Path
            </Link>
          </li>

          <li className="nav-item">
            <Link
              className="nav-link"
              to="/one_card"
              onClick={handleOnRouteClick}
            >
              Tarot
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-link"
              to="/numerology"
              onClick={handleOnRouteClick}
            >
              Numerology
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/neu_shop"
              onClick={handleOnRouteClick}
              className="nav-link"
            >
              NeuShop
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/blog" onClick={handleOnRouteClick}>
              Blog
            </Link>
          </li>
          <li
            className="flex-item-between"
            onClick={() => setShowOthersDropdown(!showOthersDropdown)}
          >
            <a className="nav-link">Others</a>
            <span
              className={`carot
                ${showOthersDropdown ? "carot-rotate" : "after:-tw-bottom-3"}`}
            ></span>
          </li>
          {showOthersDropdown && (
            <ul className="flex-column tw-w-full nested-list">
              <li className="first-list-item tw-pl-2">
                <Link
                  className="nav-link"
                  to="/muhurat"
                  onClick={handleOnRouteClick}
                >
                  Muhurat
                </Link>
              </li>
            </ul>
          )}
          {auth?.userId && (
            <li className="nav-item">
              <Link
                className="nav-link tw-border-b-transparent"
                to="/"
                onClick={logoutHandler}
              >
                Log out
              </Link>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default HeaderModal;

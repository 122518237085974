import { useContext } from "react";
import axios from "../api/axios";
import AuthContext from "../context/AuthProvider";
import { ApplicationType } from "../pages/constants";

const useRefreshToken = () => {
  const { setAuth } = useContext(AuthContext);

  const refresh = async () => {
    try {
      const response = await axios.get(
        `/auth/refresh/user?requestingApplicationType=${ApplicationType.WEB}`,
        {
          withCredentials: true,
        }
      );
      const respData = response?.data;
      if (!respData?.result) {
        return null;
      }
      setAuth({
        userId: respData.data?.userId,
        authToken: respData.data?.authToken,
        customerDetails: respData.data?.customerDetails,
      });
      return respData.data?.authToken;
    } catch (error) {}
    return null;
  };
  return refresh;
};

export default useRefreshToken;

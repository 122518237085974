import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import HomeBannerSecond from "../HomeBannerSecond";
import ZodiacHoroscopeDetails from "../../components/ZodiacHoroscopeDetails";
import ZODIAC_PERIOD from "../../constants/zodiacPeriods";
import ZodiacSlider from "../../components/ZodiacSlider";
import HoroscopeLinksComponent from "./component/HoroscopeLinksComponent";
import FeaturePanel from "../../components/FeaturePanel";
import { scrollToTop } from "../../utils/scrollToTop";
import AllHoroscopeBottomList from "./component/AllHoroscopeBottomList";

const HoroscopeMonthly = () => {
  const [zodiacDetail, setZodiacDetail] = useState(null);

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleZodiacClick = (detail) => {
    setZodiacDetail(detail);
  };

  return (
    <>
      <section className="home-section tw-mt-6 md:tw-mt-10">
        <div className="container-fluid-lg">
          <img
            align="right"
            src="../assets/images/logo/monthly-horoscope.webp"
            width="100%"
            alt="monthly-horoscope"
            loading="lazy"
          />
          <div className="row g-4"></div>
        </div>
      </section>
      {/* <!-- Category Section Start --> */}
      <section>
        <div className="container-fluid-lg">
          <div className="title">
            <h2 style={{ textAlign: "center" }}>Monthly Horoscope</h2>
            <p>
              Reading your Monthly horoscope is one of the best ways to predict
              your future. The foretelling of the future through the monthly
              horoscope is an ancient practice and finds relevance even today.
              The horoscope prediction is about predicting the individual&apos;s
              future based on various astrological aspects such as the position
              of the planets, Nakshatras, Tithi, and much more. However, it’s
              predominantly the movement of planets from one house of the Kundli
              to another that influences the life of the native and thus his
              Daily horoscope. As planets are in motion, their position in the
              chart of the native on a daily basis virtually decides the course
              of his life and fortune.
            </p>
            <p>
              Of all the ancient Vedic practices that we are aware of, horoscope
              reading is one of the most accepted and popular. Horoscope reading
              transgresses boundaries and is a tea-time read for not just the
              astrology-loving Indians but also people residing in western
              countries. In fact, these days there are numerous mediums to bring
              you your Weekly Horoscopes, such as TV, newspapers, magazines, and
              much more. Whatever the medium, the purpose of the Daily horoscope
              remains the same, which is to prepare you for life and make you
              aware of all the upcoming events, so you don’t go blank into life
              as most people do.
            </p>
          </div>
        </div>
      </section>

      <section className="category-section-2">
        <div className="container-fluid-lg">
          <HoroscopeLinksComponent selectedComponent="monthly" />
          <ZodiacSlider
            zodiacSelectHandler={handleZodiacClick}
            setZodiacOnLoad={true}
          />
        </div>
      </section>
      <ZodiacHoroscopeDetails
        zodiacDetail={zodiacDetail}
        period={ZODIAC_PERIOD.MONTHLY}
      />

      <HomeBannerSecond />
      <section>
        <div className="container-fluid-lg">
          <div className="title" style={{ padding: " 5px" }}>
            <h2>Benefits of Monthly Horoscope</h2>
            <span className="title-leaf">
              <svg className="icon-width">
                <Link to="https://themes.pixelstrap.com/fastkart/assets/svg/leaf.svg#leaf"></Link>
              </svg>
            </span>

            <p>
              A horoscope is a forecast of a person&apos;s future based on the
              positions of celestial bodies (such as planets and stars) at a
              specific time, usually the time of their birth. It is commonly
              associated with astrology and is used to provide insights into
              various aspects of life, including personality traits,
              relationships, and potential future events. Horoscopes are often
              divided into twelve astrological signs, each representing
              different periods of the year. People often read horoscopes to
              gain a better understanding of themselves or to get predictions
              about their future.
            </p>
            <p>
              Monthly horoscopes offer a broader perspective compared to weekly
              ones, covering a larger span of time. Here are ways in which
              monthly horoscopes can be perceived to help:
            </p>
            <ul className="shifted-list">
              <li>
                <b>Long-Term Planning:</b> Monthly horoscopes can provide
                insights into trends and themes that may unfold over the course
                of a month. This can aid in making long-term plans or setting
                goals.
              </li>
              <li>
                <b>Emotional Preparation:</b> By understanding potential
                emotional influences and challenges for the month ahead,
                individuals can emotionally prepare themselves for what might
                come, fostering resilience.
              </li>
              <li>
                <b>Relationship Insights:</b> Monthly horoscopes often touch
                upon relationship dynamics. This insight can be valuable for
                improving communication or understanding potential challenges in
                personal and professional relationships.
              </li>
              <li>
                <b>Personal Growth:</b> The monthly forecast may highlight
                opportunities for personal growth or areas where individuals can
                focus their energy for self-improvement.
              </li>
              <li>
                <b>Timing of Events:</b> Astrological interpretations in monthly
                horoscopes might suggest auspicious or challenging periods for
                certain activities. This information can guide the timing of
                important decisions or actions.
              </li>
            </ul>
          </div>
        </div>
      </section>
      <AllHoroscopeBottomList />
      <FeaturePanel />
      <br />
    </>
  );
};

export default HoroscopeMonthly;

import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { scrollToTop } from "../../utils/scrollToTop";
import OfflinePujaService from "../../api/services/offlinePujaService";
import Breadcrumb from "../../components/Breadcrumb";
import { getCurrencySymbol } from "../../utils/getCurrencySymbol";
import SEO from "../../components/SEO";

const PujaDescription = () => {
  const { pujaId } = useParams();
  const [selectedCity, setSelectedCity] = useState("");
  const [errorMessage, SetErrorMessage] = useState("");
  const [selectedPuja, setSelectedPuja] = useState(null);
  const { getPujaDetails } = OfflinePujaService();
  const navigate = useNavigate();
  const routes = [
    { path: "offline_puja", text: "Offline Puja" },
    { text: selectedPuja?.title },
  ];

  useEffect(() => {
    scrollToTop();
    fetchPujaDetails();
  }, []);

  const fetchPujaDetails = async () => {
    try {
      const { result, data } = await getPujaDetails(pujaId);
      if (!result) return;
      if (data) {
        setSelectedPuja(data);
      }
    } catch (error) {}
  };

  const cityChangeHandler = (event) => {
    setSelectedCity(event.target.value);
  };

  const handleOnClickHandler = async () => {
    if (!selectedCity) {
      SetErrorMessage("Please Select City");
      return;
    }
    SetErrorMessage("");
    navigate(`/offline_puja/booking`, {
      state: {
        pujaId,
        selectedCity: selectedPuja?.citiesDetails[selectedCity]?.name,
        pujaPrice: selectedPuja?.citiesDetails[selectedCity]?.price,
        pujaName: selectedPuja.title,
        pujaImage: selectedPuja?.image,
      },
    });
    scrollToTop();
  };

  return (
    <>
      {selectedPuja && (
        <SEO
          title={`Puja Service - ${selectedPuja?.title}`}
          description="Book customized offline puja services through Neuastro. Experience personalized rituals and ceremonies designed to enhance your spiritual journey."
          type="website"
          link={`/offline_puja/${pujaId}`}
          keywords="offline puja, puja services, book puja, personalized rituals, spiritual ceremonies, offline rituals, puja booking, puja path."
        />
      )}
      <section className="section-b-space shop-section tw-p-0">
        <div className="container-fluid-lg">
          <Breadcrumb routes={routes} />
        </div>
      </section>
      <section className="blog-section">
        <div className="container-fluid-lg">
          <div>
            <div className="tw-w-full md:tw-flex  md:tw-w-full  bg-yellow-gradient  tw-rounded-lg tw-ease-out tw-duration-300 tw-shadow-card-shadow tw-py-5 tw-gap-x-4 tw-px-3">
              <div className="md:tw-h-[400px] md:tw-w-[700px] tw-p-3">
                <img
                  src={selectedPuja?.image}
                  alt="puja_image"
                  loading="lazy"
                  className="tw-h-full tw-w-full tw-rounded-lg tw-shadow-box-light lazyload"
                />
              </div>
              <div className="tw-flex tw-flex-col tw-py-5 tw-relative tw-px-3 tw-gap-y-5 tw-w-full">
                <div className="flex-column tw-gap-y-1.5">
                  <h3 className="tw-text-[25px] tw-leading-6 tw-font-bold tw-text-gray-800 main-heading">
                    {selectedPuja?.title}
                  </h3>
                  <p className="tw-text-base policy-desc tw-text-para-color tw-m-0">
                    {selectedPuja?.description}
                  </p>
                </div>
                <div className="tw-w-2/3 tw-mb-2 sm:tw-mb-0">
                  <label
                    className="mr-2 tw-text-[15px] sm:tw-text-[16px] tw-font-bold"
                    htmlFor="dropdown"
                  >
                    Select City in which you want?
                  </label>
                  <br />
                  <select
                    className="tw-w-full border border-gray-300 px-2 py-1 tw-cursor-pointer"
                    name="dropdown"
                    id="dropdown"
                    value={selectedCity}
                    onChange={cityChangeHandler}
                  >
                    <option disabled selected value="">
                      Select a city
                    </option>
                    {selectedPuja?.citiesDetails?.map((cityDetail, index) => {
                      return (
                        <option key={cityDetail?._id} value={index}>
                          {cityDetail?.name}
                        </option>
                      );
                    })}
                  </select>
                  {errorMessage && (
                    <p className="tw-text-red-600 tw-p-2 ">{errorMessage}</p>
                  )}
                </div>
                <div className="tw-flex tw-gap-x-2.5 tw-items-center">
                  {selectedCity && (
                    <>
                      <h4 className="tw-text-gray-800 main-heading tw-font-semibold">
                        Price:{" "}
                      </h4>
                      <p className="tw-text-xl tw-font-semibold main-heading tw-text-gray-800 tw-p-0 tw-m-0">
                        {getCurrencySymbol(
                          selectedPuja?.citiesDetails[selectedCity]?.price
                            ?.currency
                        )}
                        {
                          selectedPuja?.citiesDetails[selectedCity]?.price
                            ?.amount
                        }
                      </p>
                    </>
                  )}
                </div>
                <div className="tw-flex tw-absolute tw-bottom-0 tw-right-0 tw-justify-end tw-mt-6">
                  <button
                    className="tw-py-1 tw-px-3 tw-bg-theme-color tw-rounded-bl-xl tw-rounded-tr-xl tw-font-bold tw-text-base sm:tw-text-lg md:tw-text-[20px] tw-cursor-pointer tw-border-none hover:tw-bg-opacity-80  tw-text-white tw-duration-300 hover:tw-shadow-sm tw-shadow-lg"
                    onClick={handleOnClickHandler}
                  >
                    Book Now
                  </button>
                </div>
              </div>
            </div>
            <div className="tw-w-full tw-mt-8 sm:tw-mt-12 tw-h-0 tw-border-dashed tw-border-gray-500 tw-border-[0.5px]"></div>
            <div className="tw-p-0 tw-py-4 sm:py-8 sm:tw-p-8">
              <h2 className="text-center tw-leading-8 main-heading">
                What are the benefits?
              </h2>
              <p className="tw-text-[18px] tw-leading-relaxed tw-mt-2 sm:tw-mt-3 sub-main-heading">
                Performing a Puja, a sacred act of worship, brings many good
                things into our lives. Its like setting aside a special time to
                talk to the divine, to feel calm and happy. When we are feeling
                sad or scared, Puja can help us feel better, like a warm hug.
                Its also a way to remember where we come from, our culture and
                traditions. Puja brings families and friends together, making us
                feel close and happy. Its a time of joy, comfort, and
                togetherness that makes our lives better.
              </p>
              <div className="tw-w-full tw-my-3 sm:tw-my-6 tw-h-0 tw-border-dashed tw-border-gray-400 tw-border-[0.5px]"></div>
              <h2 className="text-center tw-leading-8 main-heading">
                What should you do after Puja to get maximum benefits?
              </h2>
              <p className="tw-text-[18px] tw-leading-relaxed tw-mt-2 sm:tw-mt-3 sub-main-heading">
                After completing a Puja, its important to maintain a sense of
                mindfulness and reverence. Reflecting on the significance of the
                rituals performed during the Puja can deepen your spiritual
                connection and understanding. Additionally, expressing gratitude
                for the blessings received and setting positive intentions for
                the day ahead can enhance the benefits of the Puja. Engaging in
                acts of kindness, compassion, and service towards others can
                also help carry the positive energy of the Puja into your daily
                life, fostering a sense of fulfillment and harmony. Ultimately,
                integrating the teachings and principles learned during the Puja
                into your thoughts, words, and actions can lead to the maximum
                benefits of the practice.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PujaDescription;

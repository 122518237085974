import React from "react";

const CardShimmerUI = () => {
  return (
    <div className="grid-4-cards">
      <div className="shimmer-card pulse-animation"></div>
      <div className="shimmer-card pulse-animation"></div>
      <div className="shimmer-card pulse-animation"></div>
      <div className="shimmer-card pulse-animation"></div>
      <div className="shimmer-card pulse-animation"></div>
      <div className="shimmer-card pulse-animation"></div>
      <div className="shimmer-card pulse-animation"></div>
      <div className="shimmer-card pulse-animation"></div>
      <div className="shimmer-card pulse-animation"></div>
      <div className="shimmer-card pulse-animation"></div>
      <div className="shimmer-card pulse-animation"></div>
      <div className="shimmer-card pulse-animation"></div>
    </div>
  );
};

export default CardShimmerUI;

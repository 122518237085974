import { useEffect } from "react";
import { compareTwoValues } from "../constants/compareTwoValues";

const useDebounce = (callbackFunc, delay, searchQuery) => {
  useEffect(() => {
    if (compareTwoValues(searchQuery, null)) return;
    const timer = setTimeout(() => {
      callbackFunc(searchQuery);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [searchQuery]);
};

export default useDebounce;

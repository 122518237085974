export const priceFilterData = [
  { type: "1", text: "Under ₹10/min" },
  { type: "2", text: "Under ₹20/min" },
  { type: "3", text: "Under ₹30/min" },
  { type: "4", text: "Under ₹40/min" },
  { type: "5", text: "Under ₹50/min" },
  { type: "6", text: "Above ₹50/min" },
];

export const experienceFilterData = [
  { type: "1", text: "0 - 1 yrs" },
  { type: "2", text: "1 - 3 yrs" },
  { type: "3", text: "3 - 5 yrs" },
  { type: "4", text: "5 - 8 yrs" },
  { type: "5", text: "8 - 10 yrs" },
  { type: "6", text: "More than 10 yrs" },
];

export const filterType = Object.freeze({
  skills: "1",
  languages: "2",
  experience: "3",
  price: "4",
});

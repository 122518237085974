import React from "react";
import { formatDate, formatTime } from "../../../utils/formatDate";

const PanchangBasicDetails = ({ responseData, formData }) => {
    return (
        <div className="mb-4 grid-2-tables justify-content-between">
            <div className="seller-grid-box  col-sm-12">
                <table className="table-bordered w-100 table-container">
                    <tr>
                        <td>
                            <b>Location</b>
                        </td>

                        <td>{responseData?.address}</td>
                    </tr>
                    <tr>
                        <td>
                            <b>Date</b>
                        </td>
                        <td>
                            {formatDate(
                                formData.day,
                                formData.month,
                                formData.year
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Day</b>
                        </td>
                        <td>{responseData?.day}</td>
                    </tr>
                    <tr>
                        <td>
                            <b>Sunrise </b>
                        </td>
                        <td>{formatTime(responseData?.sunrise)}</td>
                    </tr>
                    <tr>
                        <td>
                            <b>SunSet</b>
                        </td>
                        <td>{formatTime(responseData?.sunset)}</td>
                    </tr>
                    <tr>
                        <td>
                            <b>Month (Purnimanta)</b>
                        </td>
                        <td>{responseData?.hindu_maah.purnimanta}</td>
                    </tr>
                    <tr>
                        <td>
                            <b>Paksha</b>
                        </td>
                        <td>{responseData?.paksha}</td>
                    </tr>
                    <tr>
                        <td>
                            <b>Tithi</b>
                        </td>
                        <td>{responseData?.tithi.details.tithi_name}</td>
                    </tr>
                    <tr>
                        <td>
                            <b>Nakshatra</b>
                        </td>
                        <td> {responseData?.nakshatra.details.nak_name} </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Karana</b>
                        </td>
                        <td>{responseData?.karan.details.karan_name}</td>
                    </tr>

                    <tr>
                        <td>
                            <b>Yoga</b>
                        </td>

                        <td>
                            {responseData?.yog.details.yog_name} till{" "}
                            {formatTime(
                                `${responseData?.yog.end_time.hour}:${responseData?.yog.end_time.minute}`
                            )}
                        </td>
                    </tr>

                    <tr>
                        <td>
                            <b>Moonrise</b>
                        </td>
                        <td>{formatTime(responseData?.moonrise)}</td>
                    </tr>

                    <tr>
                        <td>
                            <b>Moonset</b>
                        </td>
                        <td>{formatTime(responseData?.moonset)}</td>
                    </tr>

                    <tr>
                        <td>
                            <b>Ritu</b>
                        </td>
                        <td>{responseData?.ritu}</td>
                    </tr>
                </table>
            </div>
            <div className="col-sm-12 seller-grid-box">
                <table className=" table-bordered w-100 table-container ">
                    <tr>
                        <td className="col-6">
                            <b>Disha Shool</b>
                        </td>
                        <td className="col-6">{responseData?.disha_shool}</td>
                    </tr>
                    <tr>
                        <td>
                            <b>Ayana</b>
                        </td>
                        <td>{responseData?.ayana}</td>
                    </tr>
                    <tr>
                        <td>
                            <b>Sun Sign</b>
                        </td>
                        <td> {responseData?.sun_sign} </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Moon Sign</b>
                        </td>
                        <td>{responseData?.moon_sign}</td>
                    </tr>
                    <tr className="text-center p-0 bg-light-gray">
                        <td colSpan={2} className="py-1">
                            <b className="p-0">Hindu Year & Month</b>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Shaka Samvat</b>
                        </td>
                        <td>{responseData?.shaka_samvat}</td>
                    </tr>

                    <tr>
                        <td>
                            <b>Vikram Samvat</b>
                        </td>
                        <td>{responseData?.vikram_samvat}</td>
                    </tr>
                    <tr>
                        <td>
                            <b>Month (Purnimanta)</b>
                        </td>
                        <td>{responseData?.hindu_maah.purnimanta}</td>
                    </tr>
                    <tr>
                        <td>
                            <b>Month (Amanta)</b>
                        </td>
                        <td>{responseData?.hindu_maah.amanta}</td>
                    </tr>

                    <tr className="text-center bg-light-gray p-0">
                        <td colSpan={2} className="py-1">
                            <b className="p-0">Muhurat</b>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <b>Abhijit (Shubh Muhurat)</b>
                        </td>
                        <td>
                            {formatTime(responseData?.abhijit_muhurta.start)} to{" "}
                            {formatTime(responseData?.abhijit_muhurta.end)}
                        </td>
                    </tr>
                    <tr>
                        <td className="text-danger">
                            <b>Rahu Kal</b>
                        </td>
                        <td className="text-danger">
                            {formatTime(responseData?.rahukaal.start)} to{" "}
                            {formatTime(responseData?.rahukaal.end)}
                        </td>
                    </tr>
                    <tr>
                        <td className="text-danger">
                            <b>GuliKaal </b>
                        </td>
                        <td className="text-danger">
                            {formatTime(responseData?.guliKaal.start)} to{" "}
                            {formatTime(responseData?.guliKaal.end)}
                        </td>
                    </tr>
                    <tr>
                        <td className="text-danger">
                            <b>Yamghant Kaal</b>
                        </td>
                        <td className="text-danger">
                            {formatTime(responseData?.yamghant_kaal.start)} to{" "}
                            {formatTime(responseData?.yamghant_kaal.end)}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    );
};

export default PanchangBasicDetails;

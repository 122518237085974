export const HeadMetaData = Object.freeze({
  DEFAULT: {
    title: "NeuAstro - Online Astrological Guidance by Expert Astrologer.",
    description:
      "Talk to India's top astrologers on NeuAstro and get instant solutions to your problems.Get First chat/call free on NeuAstro. Find genuine products at NeuShop.",
    type: "website",
    keywords:
      "Best astrologers near me, online marriage matching, Best astrologers in India, My horoscope online, Best online astrology website in India, Vastu astrology, Horoscope Astrology, Chat with astrologer, Talk to Astrologer online, Online Astrology, free astrology chat, astroyogi, kundali matching by name and date of birth, horoscope matching for marriage, kundli matching for marriage, kundali matching by date of birth, love calculator, career horoscope.",
  },
  "/astrologers_list": {
    title: "Astrologers List - Chat With Expert Astrologers At NeuAstro.",
    description:
      "View profiles of all available astrologers on Neuastro. Check their expertise, experience, and availability to choose the right astrologer for your needs.",
    type: "website",
    keywords:
      "astrologers list, browse astrologers, astrologer profiles, available astrologers, astrologer expertise, astrologer experience, choose astrologer, call with astrologer, chat with astrologer, talk to astrologer, certified astrologers.",
  },
  "/horoscope_daily": {
    title: "Daily Horoscope - Get Your Free Astrological Forecast at NeuAstro.",
    description:
      "Check your daily horoscope on Neuastro for free. Get personalized astrological predictions and insights to guide your day.",
    type: "website",
    keywords:
      "daily horoscope, free horoscope, daily astrological forecast, daily predictions, daily astrology, personalized horoscope.",
  },
  "/horoscope_page": {
    title:
      "Weekly Horoscope - Your Astrological Predictions for the Week at NeuAstro.",
    description:
      "Explore your weekly horoscope on NeuAstro. Receive personalized astrological insights and predictions to plan your week ahead.",
    type: "website",
    keywords:
      "weekly horoscope, weekly predictions, weekly astrology, astrological insights, personalized horoscope, weekly forecast.",
  },
  "/horoscope_monthly": {
    title:
      "Monthly Horoscope - Astrological Insights for the Month at NeuAstro.",
    description:
      "Read your monthly horoscope on Neuastro. Get detailed astrological predictions and insights to help you navigate the month ahead.",
    type: "website",
    keywords:
      "monthly horoscope, monthly predictions, monthly astrology, astrological insights, personalized horoscope, monthly forecast.",
  },
  "/horoscope_yearly": {
    title:
      "Yearly Horoscope - Your Astrological Forecast for the Year at NeuAstro.",
    description:
      "Discover your yearly horoscope on Neuastro. Receive comprehensive astrological predictions and insights to guide you through the year.",
    type: "website",
    keywords:
      "yearly horoscope, yearly predictions, yearly astrology, astrological insights, personalized horoscope, yearly forecast.",
  },
  "/panchang_page": {
    title: "Neuastro Daily Panchang - Vedic Calendar and Auspicious Timings.",
    description:
      "Access Neuastro's daily Panchang for Vedic calendar details, including auspicious timings, tithi, nakshatra, and important events for the day.",
    type: "website",
    keywords:
      "daily Panchang, Neuastro Panchang, Vedic calendar, auspicious timings, tithi, nakshatra, daily astrology, important events.",
  },
  "/kundali#kundali-form": {
    title: "Create Your Kundali - Generate Your Birth Chart Online",
    type: "website",
    description:
      "Generate your own Kundali by entering your birth details. Create a detailed birth chart online for personalized astrological insights and analysis.",
    keywords:
      "create kundali, online janam kundali report, generate birth chart, online kundali, enter birth details, astrological insights, birth chart online, Kundali creation",
  },
  "/self_kundali": {
    title: "Self Kundali Report- Get Your Birth Chart Report Online",
    description:
      "View the report of your Kundali based on the details you entered. Access your personalized detailed birth chart and astrological insights for a deeper understanding.",
    type: "website",
    keywords:
      "Self Kundali, view Kundali, birth chart results, personalized Kundali, astrological insights, enter details, Kundali results, kundali report, online janam kundali report",
  },
  "/kundali_milan#kundali-milan-form": {
    title: "Kundali Milan - Check Compatibility of Birth Charts.",
    description:
      "Evaluate the compatibility of your Kundalis by entering your birth details. Analyze astrological charts to gain insights into relationship potential and compatibility.",
    type: "website",
    keywords:
      "Kundali Milan, kundali milan report, check compatibility, birth chart compatibility, Kundali comparison, astrological compatibility, relationship insights, enter details, online janam kundali report.",
  },
  "/kundali_report": {
    title: "Kundali Milan Report - Detailed Compatibility Analysis.",
    description:
      "Access your Kundali Milan report to get a detailed analysis of birth chart compatibility. Review insights into relationship potential and astrological harmony.",
    type: "website",
    keywords:
      "Kundali Milan report, compatibility analysis, birth chart report, Kundali comparison, relationship insights, astrological compatibility, detailed report.",
  },
  "/offline_puja": {
    title: "NeuAstro - Puja Path Services",
    description:
      "Book customized offline puja services through Neuastro. Experience personalized rituals and ceremonies designed to enhance your spiritual journey.",
    type: "website",
    keywords:
      "offline puja, puja services, book puja, personalized rituals, spiritual ceremonies, offline rituals, puja booking, puja path.",
  },
  "/offline_puja/booking": {
    title: "NeuAstro - Puja Path Services",
    description:
      "Book customized offline puja services through Neuastro. Experience personalized rituals and ceremonies designed to enhance your spiritual journey.",
    type: "website",
    keywords:
      "offline puja, puja services, book puja, personalized rituals, spiritual ceremonies, offline rituals, puja booking, puja path.",
  },
  "offline_puja/booking/payment": {
    title: "NeuAstro - Puja Path Services",
    description:
      "Book customized offline puja services through Neuastro. Experience personalized rituals and ceremonies designed to enhance your spiritual journey.",
    type: "website",
    keywords:
      "offline puja, puja services, book puja, personalized rituals, spiritual ceremonies, offline rituals, puja booking, puja path.",
  },
  "/offline_puja/booking/payment/checkout": {
    title: "NeuAstro - Puja Path Services",
    description:
      "Book customized offline puja services through Neuastro. Experience personalized rituals and ceremonies designed to enhance your spiritual journey.",
    type: "website",
    keywords:
      "offline puja, puja services, book puja, personalized rituals, spiritual ceremonies, offline rituals, puja booking, puja path.",
  },
  "/one_card": {
    title: "One Card Tarot - Ask a Question and Find Answers Online",
    description:
      "Ask a question and use Neuastro's online one card tarot to find answers. Get quick and insightful tarot readings to guide your decisions.",
    type: "website",
    keywords:
      "one card tarot, online tarot, ask a question, find answers, tarot reading, quick tarot, insightful readings, tarot guidance.",
  },
  "/one_card_reading": {
    title: "One Card Tarot - Ask a Question and Find Answers Online",
    description:
      "Ask a question and use Neuastro's online one card tarot to find answers. Get quick and insightful tarot readings to guide your decisions.",
    type: "website",
    keywords:
      "one card tarot, online tarot, ask a question, find answers, tarot reading, quick tarot, insightful readings, tarot guidance.",
  },
  "/one_card_result": {
    title: "One Card Tarot - Ask a Question and Find Answers Online",
    description:
      "Ask a question and use Neuastro's online one card tarot to find answers. Get quick and insightful tarot readings to guide your decisions.",
    type: "website",
    keywords:
      "one card tarot, online tarot, ask a question, find answers, tarot reading, quick tarot, insightful readings, tarot guidance.",
  },
  "/numerology": {
    title: "Numerology Insights - Discover Your Numbers And Their Meanings",
    description:
      "Explore Neuastro's numerology section to discover the meanings behind your numbers. Get personalized insights and learn how numerology influences your life.",
    type: "website",
    keywords:
      "numerology, numerology insights, discover numbers, number meanings, personalized numerology, numerology influence, numerology life.",
  },
  "/ruling": {
    title: "Numerology Report - Discover Your Numbers And Their Meanings",
    description:
      "Explore Neuastro's numerology section to discover the meanings behind your numbers. Get personalized insights and learn how numerology influences your life.",
    type: "website",
    keywords:
      "numerology, numerology insights, discover numbers, number meanings, personalized numerology, numerology influence, numerology life, numerology report.",
  },
  "/neu_shop": {
    title: "Neuastro Shop - Explore Astrology Products and Services",
    description:
      "Browse the Neuastro shop for a wide range of astrology products and services. Find everything from personal consultations to spiritual tools and resources.",
    type: "website",
    keywords:
      "Neuastro shop, Genuine astrology products, offline puja, astrology services, healing items, astrology resources, personal consultations",
  },
  "/neu_shop/cart": {
    title: "Neuastro Shop - Explore Astrology Products and Services",
    description:
      "Browse the Neuastro shop for a wide range of astrology products and services. Find everything from personal consultations to spiritual tools and resources.",
    type: "website",
    keywords:
      "Neuastro shop, Genuine astrology products, offline puja, astrology services, healing items, astrology resources, personal consultations",
  },
  "/neu_shop/checkout": {
    title: "Neuastro Shop - Explore Astrology Products and Services",
    description:
      "Browse the Neuastro shop for a wide range of astrology products and services. Find everything from personal consultations to spiritual tools and resources.",
    type: "website",
    keywords:
      "Neuastro shop, Genuine astrology products, offline puja, astrology services, healing items, astrology resources, personal consultations",
  },
  "/blog": {
    title: "Neuastro Blogs - Vedic Astrology, Solutions, and Insights",
    description:
      "Discover all blogs on Neuastro. Explore a wide range of articles and insights on Vedic astrology, spiritual solutions, and personal growth.",
    type: "website",
    keywords:
      "Neuastro blog, Vedic astrology blogs, astrology solutions, spiritual insights, personal growth, explore blogs, informative astrology.",
  },
  "/muhurat": {
    title: "Mahurat - List Of Auspicious Dates And Times.",
    description:
      "Find all Shubh Mahurats listed on Neuastro for your convenience. Explore auspicious dates and times for important events and rituals.",
    type: "website",
    keywords:
      "Mahurat, Shubh Mahurats, auspicious dates, auspicious times, Shubh timings, important events, rituals, Neuastro Mahurat.",
  },
  "/privacy_policy": {
    title: "Privacy Policy - Your Data Protection at NeuAstro.",
    description:
      "Read Neuastro's privacy policy to understand how we protect your personal information. Learn about our data collection, usage, and security practices.",
    type: "website",
    keywords:
      "privacy policy, data protection, personal information, data security, data usage, privacy practices, Neuastro privacy, data collection.",
  },
  "/term_condition": {
    title:
      "Terms And Conditions - Neuastro | Best Astrology Service With Your Privacy As Our Priority.",
    description:
      "Review Neuastro's terms and conditions to understand our service policies and user agreements. Find important information about our platform's usage and responsibilities.",
    type: "website",
    keywords:
      "terms and conditions, service agreement, user agreement, Neuastro policies, platform usage, service policies, user responsibilities.",
  },
  "/about_us": {
    title: "About Us - Learn More About Neuastro And Our Mission.",
    description:
      "Discover Neuastro's mission, values, and the team behind our astrology platform. Learn how we connect you with expert astrologers for accurate insights and guidance.",
    type: "website",
    keywords:
      "about us, Neuastro, our mission, astrology platform, expert astrologers, company values, team, astrology insights.",
  },
  "/conversation_initial_input": {
    title: "NeuAstro - Enter Details For Astrological Analysis.",
    description:
      "Fill in your date of birth, name, and place of birth for a detailed astrological analysis by our expert astrologers. Ensure accurate insights with your provided details.",
    type: "website",
    keywords:
      "astrology analysis, birth details, provide details, date of birth, name, place of birth, astrological insights, submit information",
  },
  "/chat_history": {
    title: "Chat History - Review Your Conversations with Astrologer.",
    description:
      "View the history of your chat sessions with astrologers. Access previous conversations to revisit insights and advice given during your consultations.",
    type: "website",
    keywords:
      "chat history, astrologer chat history, view conversations, previous chats, astrology insights, user chat history, review consultations.",
  },
  "/video_call": {
    title:
      "Video Call with Astrologer - Face-to-Face Real-Time Guidance At NeuAstro.",
    description:
      "Engage in a video call with a certified astrologer for face-to-face guidance and detailed astrological insights. Experience personalized consultations in real time.",
    type: "website",
    keywords:
      "video call with astrologer, astrology video call, connect with astrologer, face-to-face consultation, astrologer video session, live astrology, video chat with astrologer",
  },
  "/audio_call": {
    title:
      "Audio Call with Astrologer - Connect for Real-Time Guidance At NeuAstro.",
    description:
      "Start an audio call with a certified astrologer for immediate insights and personalized guidance At NeuAstro. Easy access to expert advice through real-time communication.",
    type: "website",
    keywords:
      "audio call, astrology audio call, connect with astrologer, real-time guidance, astrologer consultation, phone astrology, audio session with astrologer",
  },
  "/rating": {
    title: "Rate Your Astrologer - Provide Feedback and Improve Our Service.",
    description:
      "Give your star rating and feedback for the astrologer after your call. Your ratings help us enhance our services and ensure high-quality consultations.",
    type: "website",
    keywords:
      "rate astrologer, star rating, provide feedback, improve service, astrologer review, consultation feedback, service improvement, user feedback.",
  },
  "/profile": {
    title: "Neuastro User Profile - Manage Your Account and Settings",
    description:
      "View and manage your Neuastro profile, update personal details, and access your chat summary, transaction, wallet details history.",
    type: "User profile",
    keywords:
      "user profile, Neuastro profile, chat summary, manage account, astrology settings, profile details",
  },
  "/payment/payment_status": {
    title: "Payment Status - Check Your Transaction Progress.",
    description:
      "View the status of your payments on Neuastro. Track your transaction progress and get updates on pending or completed payments.",
    type: "website",
    keywords:
      "payment status, check payment progress, transaction status, payment updates, Neuastro payments, track payments, payment progress.",
  },
  "/payment/cancel_payment": {
    title: "Cancel Payment - Manage Your Transaction Requests.",
    description:
      "Request to cancel a payment on Neuastro. Manage your transaction requests and ensure your payment issues are resolved promptly.",
    type: "website",
    keywords:
      "cancel payment, manage transactions, payment cancellation, transaction requests, payment issues, Neuastro payment, resolve payment.",
  },
  "/payment": {
    title: "Secure Payment - Complete Your Astrology Service Transaction.",
    description:
      "Complete your astrology service purchase securely with our easy payment options. Ensure a smooth transaction for consultations, calls, and other services.",
    type: "website",
    keywords:
      "secure payment, astrology payment, complete transaction, payment options, astrology service payment, secure checkout, payment processing",
  },
  "/login_new_user_details": {
    title: "NeuAstro - New User Details",
    description:
      "Fill new User details on NeuAstro to access personalized astrological services. Secure and easy registration for expert astrology guidance.",
    type: "website",
    keywords:
      "Best astrologers near me, online marriage matching, Best astrologers in India, My horoscope online, Best online astrology website in India, Vastu astrology, Horoscope Astrology, Chat with astrologer, Talk to Astrologer online, Online Astrology, free astrology chat, astroyogi, kundali matching by name and date of birth, horoscope matching for marriage, kundli matching for marriage, kundali matching by date of birth, love calculator, career horoscope.",
  },
  "/login": {
    title: "NeuAstro - Login",
    description:
      "Talk to India's top astrologers on NeuAstro and get instant solutions to your problems.Get First chat/call free on NeuAstro. Find genuine products at NeuShop.",
    type: "website",
    keywords:
      "Best astrologers near me, online marriage matching, Best astrologers in India, My horoscope online, Best online astrology website in India, Vastu astrology, Horoscope Astrology, Chat with astrologer, Talk to Astrologer online, Online Astrology, free astrology chat, astroyogi, kundali matching by name and date of birth, horoscope matching for marriage, kundli matching for marriage, kundali matching by date of birth, love calculator, career horoscope.",
  },
  "/streaming": {
    title: "Live Streaming - Watch Astrologers in Real Time.",
    description:
      "Watch live streaming sessions with astrologers in real time. Access exclusive insights and guidance from experts during their live broadcasts.",
    type: "website",
    keywords:
      "live streaming, astrologer live stream, real-time astrology, watch astrologers, live astrology session, streaming astrology, astrology broadcast",
  },
  "/disclaimer": {
    title: "Disclaimer - Important Information About Neuastro Services.",
    description:
      "Review Neuastro's disclaimer to understand the limitations and scope of our astrology services. Important information about the accuracy and use of our insights.",
    type: "website",
    keywords:
      "disclaimer, Neuastro disclaimer, astrology services, service limitations, information accuracy, use of insights, service scope.",
  },
  "/astrolive": {
    title: "AstroLive - Join Live Streaming Sessions With Astrologers.",
    description:
      "View all live astrologers on Neuastro and join their live streaming sessions. Get real-time insights and guidance from expert astrologers.",
    type: "website",
    keywords:
      "AstroLive, live astrologers, join live streaming, astrologer sessions, real-time astrology, live insights, expert guidance.",
  },
  "/404": {
    title: "404 - Page Not Found",
    description:
      "Page not found on Neuastro. Discover our astrology and tarot services, explore our neushop or return to our homepage or use the menu to find what you need.",
    type: "website",
    keywords:
      "Best astrologers near me, online marriage matching, Best astrologers in India, My horoscope online, Best online astrology website in India, Vastu astrology, Horoscope Astrology, Chat with astrologer, Talk to Astrologer online, Online Astrology, free astrology chat, astroyogi, kundali matching by name and date of birth, horoscope matching for marriage, kundli matching for marriage, kundali matching by date of birth, love calculator, career horoscope.",
  },
  "/": {
    title: "NeuAstro - Online Astrological Guidance by Expert Astrologer.",
    description:
      "Talk to India's top astrologers on NeuAstro and get instant solutions to your problems.Get First chat/call free on NeuAstro. Find genuine products at NeuShop.",
    type: "website",
    keywords:
      "Best astrologers near me, online marriage matching, Best astrologers in India, My horoscope online, Best online astrology website in India, Vastu astrology, Horoscope Astrology, Chat with astrologer, Talk to Astrologer online, Online Astrology, free astrology chat, astroyogi, kundali matching by name and date of birth, horoscope matching for marriage, kundli matching for marriage, kundali matching by date of birth, love calculator, career horoscope.",
  },
});

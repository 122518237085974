import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { convertUTCToLocalDate } from "../../utils/utcToLocalTimeConvertor";
import FeaturePanel from "../../components/FeaturePanel";
import Breadcrumb from "../../components/Breadcrumb";
import { scrollToTop } from "../../utils/scrollToTop";
import BlogService from "../../api/services/blogService";

function BlogPage() {
  const [blogsList, setBlogsList] = useState([]);
  const routes = [{ text: "Blogs" }];
  const { getBlogsList } = BlogService();

  useEffect(() => {
    scrollToTop();
    fetchBlogsList();
  }, [0]);

  const fetchBlogsList = async () => {
    try {
      const { result, data, msg } = await getBlogsList();
      if (result) {
        setBlogsList(data);
      }
    } catch (error) {}
  };

  return (
    <>
      <section className="tw-mb-0 tw-mt-0 tw-pt-7">
        <div className="container-fluid-lg tw-mb-0">
          <Breadcrumb routes={routes} />
        </div>
      </section>
      <section className="blog-section section-b-space">
        <div className="container-fluid-lg">
          <div className="row g-sm-4 g-3">
            {blogsList?.map((list, index) => {
              return (
                <Link
                  to={`/blog/${list._id}`}
                  key={index}
                  className="tw-no-underline tw-text-black col-12 col-sm-6 col-md-4 col-xl-3 tw-cursor-pointer tw-mb-4"
                >
                  <div>
                    <div className="blog-box wow fadeInUp">
                      <div className="blog-image">
                        <img
                          style={{
                            width: "100%",
                            height: "206px",
                            objectFit: "",
                          }}
                          src={list.image}
                          className="bg-img blur-up lazyload img-fluid"
                          alt={`blog_${list?.title}`}
                          loading="lazy"
                        />
                      </div>

                      <div className="blog-contain tw-pl-1.5 tw-py-1.5">
                        <div className="blog-label tw-flex tw-flex-col tw-items-start tw-gap-y-2.5">
                          <h3 className="slider-card-heading tw-my-0 overflow-ellipsis">
                            {list.title}
                          </h3>
                          <div className="tw-text-para-color tw-flex tw-gap-x-1">
                            <span>{list?.author}</span>
                            <span>|</span>
                            <span>
                              {convertUTCToLocalDate(list?.updatedAt)}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </section>
      <FeaturePanel />
      <br />
      <br />
    </>
  );
}

export default BlogPage;

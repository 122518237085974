import React from "react";
import convertUTCToLocalTime from "../../../utils/utcToLocalTimeConvertor";

const UpcomingLiveAstrologerCard = ({ astrologerDetail }) => {
  return (
    <div className="product-box-4 tw-flex tw-flex-col tw-gap-y-3 tw-relative tw-overflow-hidden tw-py-3">
      <div className="tw-w-full">
        <div className="tw-border-[3px] tw-mx-auto tw-w-[90px] tw-h-[90px] tw-border-solid tw-border-light-green tw-rounded-full tw-relative">
          <div className="tw-w-[85px] tw-h-[85px] tw-rounded-full tw-overflow-hidden">
            <img
              src={astrologerDetail.astrologerDetails?.profilePic}
              className="img-fluid tw-w-full tw-h-full tw-rounded-full lazyload"
              alt="profile_pic"
              loading="lazy"
            />
          </div>

          <div className="tw-absolute -tw-translate-x-1/2 tw-bottom-0 tw-left-1/2">
            <button className="tw-bg-light-green tw-text-[11px] tw-tracking-wider tw-rounded-md tw-border-none tw-text-white tw-h-[25px] tw-cursor-default">
              SCHEDULED
            </button>
          </div>
        </div>
        <h3 className="tw-text-base sm:tw-text-lg overflow-ellipsis tw-mt-2">
          {astrologerDetail.astrologerDetails?.name}
        </h3>
      </div>
      <div className="tw-w-full flex-item-center">
        <h4 className="tw-text-para-color tw-text-sm">
          {convertUTCToLocalTime(astrologerDetail.startDateTime)}
        </h4>
      </div>
    </div>
  );
};

export default UpcomingLiveAstrologerCard;

import React, { useContext, useEffect, useRef, useState } from "react";
import firebase from "../Firebase";
import "./chat.css";
import { useLocation, useNavigate } from "react-router-dom";
import ConversationService from "../../api/services/conversationService";
import AuthContext from "../../context/AuthProvider";
import { ChatMessageType } from "./enums/chatMessageType";
import { ChatMessageSenderType } from "./enums/chatMessageSenderType";
import { EndChatReasonType } from "./enums/endChatReasonType";
import { formatTimeWithHour } from "../../utils/formatDate";
import InitialInputMessage from "./components/initialInputMessage";
import AstrologerMessage from "./components/astrologerMessage";
import CustomerMessage from "./components/customerMessage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoMdSend } from "react-icons/io";
import { scrollToTop } from "../../utils/scrollToTop";
import { compareTwoValues } from "../../constants/compareTwoValues";
import SEO from "../../components/SEO";

const Chat = () => {
  const location = useLocation();
  const { auth } = useContext(AuthContext);
  const userId = auth?.userId;
  const {
    conversationId,
    astrologerId,
    conversationInput,
    maxConversationTimeInMinute,
    astroName,
    astroImage,
  } = location.state;
  const navigate = useNavigate();
  const { endConversation } = ConversationService();
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");
  const chatEndedRef = useRef(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const chatEndIntervalTimerRef = useRef(null);
  const OneMinuteTimerRef = useRef(null);
  const [chatEndTime, setChatEndTime] = useState(null);

  useEffect(() => {
    scrollToTop();
    OneMinuteTimerRef.current = setTimeout(() => {
      setChatEndTime(120);
      chatEndIntervalTimerRef.current = setInterval(() => {
        setChatEndTime((prevChatEndTime) => {
          if (prevChatEndTime <= 0) {
            disconnectChat(EndChatReasonType.endByCustomer);
            clearTimeout(OneMinuteTimerRef.current);
            clearInterval(chatEndIntervalTimerRef.current);
            return prevChatEndTime;
          }
          return Number(prevChatEndTime) - 1;
        });
      }, 1000);
    }, 60 * 1000);

    return () => {
      clearTimeout(OneMinuteTimerRef.current);
      chatEndIntervalTimerRef && clearInterval(chatEndIntervalTimerRef.current);
    };
  }, []);

  useEffect(() => {
    let timerInterval;
    const startTimer = () => {
      const startTime = new Date().getTime();
      const maxConversationTimeInMilliseconds =
        maxConversationTimeInMinute * 60 * 1000;

      const updateTimer = () => {
        const currentTime = new Date().getTime();
        const elapsedTime = currentTime - startTime;
        if (elapsedTime >= maxConversationTimeInMilliseconds) {
          disconnectChat(EndChatReasonType.endByExpiredTimer);
          return;
        }
        setElapsedTime(elapsedTime);
      };

      timerInterval = setInterval(updateTimer, 1000);
    };

    const chatRef = firebase
      .database()
      .ref("chat")
      .child(userId)
      .child(astrologerId)
      .child(conversationId);

    chatRef.on("child_added", (snapshot) => {
      const chatData = snapshot.val();
      if (chatData) {
        if (
          chatData.type == ChatMessageType.endChat &&
          chatData.reason == EndChatReasonType.endByAstrologer
        ) {
          disconnectChat(EndChatReasonType.endByAstrologer);
          return;
        } else if (chatData.type == ChatMessageType.startChat) {
          startTimer();
          clearTimeout(OneMinuteTimerRef.current);
          chatEndIntervalTimerRef &&
            clearInterval(chatEndIntervalTimerRef.current);
          return;
        }
        setMessages((previousValue) => [chatData, ...previousValue]);
      }
    });

    const timeStamp = Date.now().toString();
    chatRef.child(timeStamp).set({
      sender: ChatMessageSenderType.customer,
      name: conversationInput.name,
      gender: conversationInput.gender,
      dob: conversationInput.dateOfBirth,
      tob: conversationInput.timeOfBirth,
      pob: conversationInput.placeOfBirth,
      message: conversationInput.message ?? "",
      type: ChatMessageType.initialDetails,
    });

    return () => clearInterval(timerInterval);
  }, []);

  useEffect(() => {
    return () => {
      if (!chatEndedRef.current) {
        disconnectChat(EndChatReasonType.endByCustomer);
      }
    };
  }, []);

  const sendMessage = (event) => {
    event.preventDefault();
    const timeStamp = Date.now().toString();
    if (currentMessage.trim() !== "") {
      const chatRef = firebase.database().ref("chat");
      const newMessageRef = chatRef
        .child(userId)
        .child(astrologerId)
        .child(conversationId)
        .child(timeStamp);
      newMessageRef.set({
        message: currentMessage,
        sender: ChatMessageSenderType.customer,
        type: ChatMessageType.message,
      });
      setCurrentMessage("");
    }
  };

  const disconnectChat = async (reason) => {
    if (compareTwoValues(chatEndedRef.current, true)) return;
    try {
      chatEndedRef.current = true;
      const { result, msg } = await endConversation(conversationId);
      if (!result) {
        toast.error(msg || "Facing some issue!");
        chatEndedRef.current = false;
        return;
      }
    } catch (error) {}

    if (reason != EndChatReasonType.endByAstrologer) {
      const timeStamp = Date.now().toString();
      const chatRef = firebase.database().ref("chat");
      const newMessageRef = chatRef
        .child(userId)
        .child(astrologerId)
        .child(conversationId)
        .child(timeStamp);
      newMessageRef.set({
        reason: reason,
        sender: ChatMessageSenderType.customer,
        type: ChatMessageType.endChat,
      });
    }
    toast.success("Chat ended successfully!");
    navigate("/rating", { state: { astrologerId, conversationId } });
  };

  return (
    <>
      <SEO
        title={`Chat with Astrologer - ${astroName}`}
        description="Connect with certified astrologers for a free chat and personalized insights. Explore expert guidance on astrology and spirituality tailored just for you."
        type="Service Page"
        link="/chat"
        keywords="chat with astrologers, free astrology chat, certified astrologers, astrology guidance, spiritual insights"
      />
      <ToastContainer />
      <section className="flex-item-center">
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-lg-6">
              <div className="card tw-shadow-dark-pink">
                <div className="card-header flex-item-between p-2 tw-border-r-0 tw-border-l-0 tw-border-solid tw-border-t-[4px] tw-border-t-dark-pink tw-bg-white">
                  <div className="tw-flex tw-gap-x-2 tw-items-center">
                    <div>
                      <img
                        src={astroImage}
                        alt="astrologer_pic"
                        loading="lazy"
                        className="tw-w-10 tw-h-10 tw-rounded-full lazyload"
                      />
                    </div>
                    <div className="flex-column tw-gap-y-1">
                      <h5 className="tw-mb-0 tw-text-xs tw-font-semibold sm:tw-text-sm">
                        {astroName}
                      </h5>
                      <p className="tw-m-0 tw-p-0 tw-text-[11px] sm:tw-text-xs tw-text-para-color">
                        Online
                      </p>
                    </div>
                  </div>
                  <div className="tw-flex tw-items-center tw-gap-x-2 sm:tw-gap-x-3">
                    <h4 className="tw-text-xs sm:tw-text-sm tw-rounded-md tw-bg-light-pink tw-border-dark-pink tw-text-dark-pink tw-border-solid tw-border tw-px-2 tw-py-1">
                      {formatTimeWithHour(elapsedTime)}
                    </h4>
                    <h4
                      className="tw-text-theme-color flex-item-center tw-border-solid tw-border tw-rounded hover:tw-bg-theme-color hover:tw-text-white tw-transition-all tw-duration-200 tw-ease-in-out tw-border-theme-color tw-bg-light-yellow tw-py-1 tw-px-2 tw-cursor-pointer tw-font-extrabold tw-tracking-wide tw-text-[13px] sm:tw-text-[15px]"
                      onClick={() => {
                        disconnectChat(EndChatReasonType.endByCustomer);
                      }}
                    >
                      End
                    </h4>
                  </div>
                </div>
                <div
                  className="card-body tw-bg-no-repeat tw-bg-cover tw-bg-center d-flex flex-column-reverse tw-h-[300px] tw-relative tw-overflow-y-auto"
                  data-mdb-perfect-scrollbar="true"
                  style={{
                    backgroundImage: "url('/assets/images/chat/chatbg.jpeg')",
                  }}
                >
                  {messages?.map((item) => {
                    return (
                      <>
                        {item.sender == ChatMessageSenderType.astrologer ? (
                          <AstrologerMessage message={item.message} />
                        ) : item.type == ChatMessageType.initialDetails ? (
                          <>
                            <InitialInputMessage
                              name={item.name}
                              gender={item.gender}
                              dob={item.dob}
                              tob={item.tob}
                              pob={item.pob}
                              message={item.message}
                              astroName={astroName}
                              elapsedTime={elapsedTime}
                              chatEndTime={chatEndTime}
                            />
                          </>
                        ) : (
                          <CustomerMessage message={item.message} />
                        )}
                      </>
                    );
                  })}
                </div>
                <div className="card-footer text-muted d-flex justify-content-start align-items-center p-2 p-sm-3 tw-bg-white">
                  <form className="tw-w-full" onSubmit={sendMessage}>
                    <div className="input-group mb-0">
                      <input
                        type="text"
                        value={currentMessage}
                        onChange={(e) => setCurrentMessage(e.target.value)}
                        className="form-control"
                        placeholder="Type message"
                        aria-label="Recipient's username"
                        aria-describedby="button-addon2"
                      />
                      <button
                        className="btn tw-bg-theme-color tw-py-0 tw-px-2.5 sm:tw-px-4 hover:tw-bg-opacity-80 tw-transition-all tw-duration-150 tw-ease-in-out"
                        type="submit"
                        id="button-addon2"
                      >
                        <IoMdSend className="tw-text-white tw-text-xl" />
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Chat;

import React, { useEffect, useState } from "react";
import { filterType } from "../../../constants/categoryFilters";
import { compareTwoValues } from "../../../constants/compareTwoValues";
import AstrologerFilterService from "../../../api/services/astrologerFilterService";
import { FaFilter } from "react-icons/fa";
import { TbFilterCheck } from "react-icons/tb";
import SkillsFilter from "./SkillsFilter";
import LanguagesFilter from "./LanguagesFilter";
import ExperienceFilter from "./ExperienceFilter";
import PriceFilter from "./PriceFilter";

const Filters = ({
  setAstrologersList,
  setShowList,
  setIsFilterActive,
  postData,
}) => {
  const [skillsFilterData, setSkillsFilterData] = useState([]);
  const [languagesFilterData, setLanguagesFilterData] = useState([]);
  const [skillOptions, setSkillOptions] = useState({});
  const [languageOptions, setLanguageOptions] = useState({});
  const [selectedPriceFilter, setSelectedPriceFilter] = useState(null);
  const [activeFilterIcon, setActiveFilterIcon] = useState(false);
  const [selectedExperienceFilter, setSelectedExperienceFilter] =
    useState(null);
  const [filterDropdown, setFilterDropdown] = useState(false);
  const [selectedFilterType, setSelectedFilterType] = useState(
    filterType.skills
  );
  const { filterAstrologersList, getSkillsFilterData, getLanguagesFilterData } =
    AstrologerFilterService();

  const fetchSkillLangData = async () => {
    try {
      const skillsApiResponse = await getSkillsFilterData();
      if (skillsApiResponse.result) {
        setSkillsFilterData(skillsApiResponse.data);
      }
      const languageApiResponse = await getLanguagesFilterData();
      if (languageApiResponse.result) {
        setLanguagesFilterData(languageApiResponse.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchSkillLangData();
  }, []);

  const showSelectedFilterType = () => {
    switch (selectedFilterType) {
      case filterType.skills:
        return (
          <SkillsFilter
            skillOptions={skillOptions}
            setSkillOptions={setSkillOptions}
            skillsFilterData={skillsFilterData}
          />
        );
      case filterType.languages:
        return (
          <LanguagesFilter
            languageOptions={languageOptions}
            setLanguageOptions={setLanguageOptions}
            languagesFilterData={languagesFilterData}
          />
        );
      case filterType.experience:
        return (
          <ExperienceFilter
            selectedExperienceFilter={selectedExperienceFilter}
            setSelectedExperienceFilter={setSelectedExperienceFilter}
          />
        );
      case filterType.price:
        return (
          <PriceFilter
            selectedPriceFilter={selectedPriceFilter}
            setSelectedPriceFilter={setSelectedPriceFilter}
          />
        );
    }
  };

  const applyAstrologersFilter = async (reqData) => {
    try {
      setFilterDropdown(false);
      setShowList(false);
      const { result, data } = await filterAstrologersList(reqData);
      if (result) {
        setAstrologersList(data);
      }
    } catch (error) {}
    setShowList(true);
  };

  const handleApplyFilters = async (event) => {
    event.stopPropagation();
    const selectedSkills = Object.values(skillOptions).reduce(
      (acc, currentData) => {
        if (currentData) {
          acc.push(currentData);
        }
        return acc;
      },
      []
    );

    const selectedLanguages = Object.values(languageOptions).reduce(
      (acc, currentData) => {
        if (currentData) {
          acc.push(currentData);
        }
        return acc;
      },
      []
    );

    if (
      selectedSkills.length ||
      selectedLanguages.length ||
      selectedExperienceFilter ||
      selectedPriceFilter
    ) {
      setActiveFilterIcon(true);
    } else {
      setActiveFilterIcon(false);
    }

    const reqData = {
      skills: selectedSkills,
      languages: selectedLanguages,
    };
    if (selectedExperienceFilter) {
      reqData.experience = selectedExperienceFilter;
    }
    if (selectedPriceFilter) {
      reqData.price = selectedPriceFilter;
    }

    await applyAstrologersFilter(reqData);
    setIsFilterActive(true);
  };

  const handleOnFilterType = (event) => {
    event.stopPropagation();
    const currentFilter = event.target.name;
    setSelectedFilterType(currentFilter);
  };

  const handleResetFilters = async (event) => {
    event.stopPropagation();
    setSelectedExperienceFilter(null);
    setSelectedPriceFilter(null);
    setSkillOptions({});
    setLanguageOptions({});
    setActiveFilterIcon(false);
    setFilterDropdown(false);
    await postData();
    setIsFilterActive(false);
  };

  return (
    <div className="category-dropdown tw-justify-end tw-m-0">
      <div
        className="tw-relative dropdown tw-h-10 tw-px-0 tw-flex tw-items-center"
        onClick={() => setFilterDropdown(!filterDropdown)}
        onMouseEnter={() => setFilterDropdown(true)}
        onMouseLeave={() => setFilterDropdown(false)}
      >
        <button className="dropdown-toggle tw-flex tw-flex-row tw-p-1.5 tw-w-[130px] bg-light-pink tw-border tw-border-solid tw-border-gray-200">
          <span className="tw-inline-block tw-text-[#6c087d]">Filter</span>{" "}
          {activeFilterIcon ? (
            <TbFilterCheck className="tw-text-[#6c087d]" />
          ) : (
            <FaFilter className="tw-text-[#6c087d]" />
          )}
        </button>
        <div
          className={`dropdown-options tw-shadow-neu-shadow
                         ${filterDropdown ? "tw-flex" : "tw-hidden"}
                        `}
        >
          <h3 className="tw-py-1.5 tw-px-3 tw-w-full">Filters</h3>
          <div className="filter-container tw-w-full">
            <div className="left-filter-options-box">
              <a
                className={`left-filter-options ${
                  compareTwoValues(selectedFilterType, filterType.skills)
                    ? "active-option"
                    : "inactive-option"
                }`}
                name={filterType.skills}
                onClick={handleOnFilterType}
              >
                Skills
              </a>
              <a
                className={`left-filter-options ${
                  compareTwoValues(selectedFilterType, filterType.languages)
                    ? "active-option"
                    : "inactive-option"
                }`}
                name={filterType.languages}
                onClick={handleOnFilterType}
              >
                Languages
              </a>
              <a
                className={`left-filter-options ${
                  compareTwoValues(selectedFilterType, filterType.experience)
                    ? "active-option"
                    : "inactive-option"
                }`}
                name={filterType.experience}
                onClick={handleOnFilterType}
              >
                Experience
              </a>
              <a
                className={`left-filter-options ${
                  compareTwoValues(selectedFilterType, filterType.price)
                    ? "active-option"
                    : "inactive-option"
                }`}
                name={filterType.price}
                onClick={handleOnFilterType}
              >
                Price
              </a>
            </div>
            <div className="tw-w-full">{showSelectedFilterType()}</div>
          </div>
          <div className="apply-btn-box tw-w-full">
            <button className="theme-btn" onClick={handleResetFilters}>
              Reset
            </button>
            <button className="theme-btn" onClick={handleApplyFilters}>
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filters;

import { useLocation } from "react-router-dom";
import TagManager from "react-gtm-module";
import { googleTagManagerKey } from "../pages/config";
import { useEffect } from "react";

const GTMSetup = () => {
  const location = useLocation();
  TagManager.initialize({ gtmId: googleTagManagerKey });

  useEffect(() => {
    const historyListener = () => {
      TagManager.dataLayer({
        dataLayer: {
          event: "pageview",
          pagePath: window.location.origin + location.pathname,
          pageTitle: "View" + window.location.origin + location.pathname,
        },
      });
    };

    historyListener();
  }, [location.pathname]);

  return null;
};

export default GTMSetup;

import React from "react";

const ProductShimmerUI = () => {
  return (
    <div className="home-items tw-grid tw-grid-cols-1 min-[520px]:tw-grid-cols-2  md:tw-grid-cols-3 lg:tw-grid-cols-4 xl:tw-grid-cols-4 tw-px-4 sm:tw-px-8 tw-gap-x-5 tw-gap-y-8 tw-pb-12">
      <div className="tw-w-full tw-h-full text-decoration-none">
        <div className="item-car tw-shadow-box-light tw-w-full tw-h-full tw-cursor-pointer tw-box-border tw-overflow-hidden tw-rounded-md tw-bg-gray-100">
          <div className="item-image tw-object-fill tw-bg-gray-200 tw-aspect-square tw-text-black tw-border-b-gray-100 tw-border-solid tw-border-0 tw-border-b tw-inline-block tw-ease-out tw-duration-300 tw-w-full tw-h-[200px]"></div>
          <div className="tw-pb-4 tw-px-1.5 flex-column tw-gap-y-1.5 sm:tw-gap-y-2.5">
            <div className="tw-bg-gray-200 tw-h-6 tw-w-full tw-rounded"></div>
            <div className="flex-item-between tw-gap-x-2">
              <div className="tw-bg-gray-200 tw-h-6 tw-w-full tw-rounded"></div>
              <div className="tw-bg-gray-200 tw-h-8 tw-w-full tw-rounded"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="tw-w-full tw-h-full text-decoration-none">
        <div className="item-car tw-shadow-box-light tw-w-full tw-h-full tw-cursor-pointer tw-box-border tw-overflow-hidden tw-rounded-md tw-bg-gray-100">
          <div className="item-image tw-object-fill tw-bg-gray-200 tw-aspect-square tw-text-black tw-border-b-gray-100 tw-border-solid tw-border-0 tw-border-b tw-inline-block tw-ease-out tw-duration-300 tw-w-full tw-h-[200px]"></div>
          <div className="tw-pb-4 tw-px-1.5 flex-column tw-gap-y-1.5 sm:tw-gap-y-2.5">
            <div className="tw-bg-gray-200 tw-h-6 tw-w-full tw-rounded"></div>
            <div className="flex-item-between tw-gap-x-2">
              <div className="tw-bg-gray-200 tw-h-6 tw-w-full tw-rounded"></div>
              <div className="tw-bg-gray-200 tw-h-8 tw-w-full tw-rounded"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="tw-w-full tw-h-full text-decoration-none">
        <div className="item-car tw-shadow-box-light tw-w-full tw-h-full tw-cursor-pointer tw-box-border tw-overflow-hidden tw-rounded-md tw-bg-gray-100">
          <div className="item-image tw-object-fill tw-bg-gray-200 tw-aspect-square tw-text-black tw-border-b-gray-100 tw-border-solid tw-border-0 tw-border-b tw-inline-block tw-ease-out tw-duration-300 tw-w-full tw-h-[200px]"></div>
          <div className="tw-pb-4 tw-px-1.5 flex-column tw-gap-y-1.5 sm:tw-gap-y-2.5">
            <div className="tw-bg-gray-200 tw-h-6 tw-w-full tw-rounded"></div>
            <div className="flex-item-between tw-gap-x-2">
              <div className="tw-bg-gray-200 tw-h-6 tw-w-full tw-rounded"></div>
              <div className="tw-bg-gray-200 tw-h-8 tw-w-full tw-rounded"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="tw-w-full tw-h-full text-decoration-none">
        <div className="item-car tw-shadow-box-light tw-w-full tw-h-full tw-cursor-pointer tw-box-border tw-overflow-hidden tw-rounded-md tw-bg-gray-100">
          <div className="item-image tw-object-fill tw-bg-gray-200 tw-aspect-square tw-text-black tw-border-b-gray-100 tw-border-solid tw-border-0 tw-border-b tw-inline-block tw-ease-out tw-duration-300 tw-w-full tw-h-[200px]"></div>
          <div className="tw-pb-4 tw-px-1.5 flex-column tw-gap-y-1.5 sm:tw-gap-y-2.5">
            <div className="tw-bg-gray-200 tw-h-6 tw-w-full tw-rounded"></div>
            <div className="flex-item-between tw-gap-x-2">
              <div className="tw-bg-gray-200 tw-h-6 tw-w-full tw-rounded"></div>
              <div className="tw-bg-gray-200 tw-h-8 tw-w-full tw-rounded"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductShimmerUI;

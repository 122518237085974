import React, { useContext, useEffect } from "react";
import AuthContext from "../context/AuthProvider";
import useRefreshToken from "../hooks/useRefreshToken";
import { Outlet } from "react-router-dom";

const PersistLogin = () => {
  const { auth } = useContext(AuthContext);
  const refresh = useRefreshToken();

  useEffect(() => {
    const verifyRefreshToken = async () => {
      try {
        await refresh();
      } catch (error) {}
    };

    !auth?.authToken && verifyRefreshToken();
  }, []);

  return <Outlet />;
};

export default PersistLogin;

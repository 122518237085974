/* eslint-disable react/prop-types */
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import { useEffect, useState } from "react";
import ZodiacService from "../api/services/zodiacService";

const ZodiacSlider = ({
  zodiacSelectHandler,
  setZodiacOnLoad,
  initialSlideNumber,
}) => {
  const { getZodiacs } = ZodiacService();
  const [zodiacDetails, setZodiacDetails] = useState([]);
  const [sliderRef, setSliderRef] = useState();
  const [selectedZodiacIndex, setSelectedZodiacIndex] = useState(() => {
    if (setZodiacOnLoad) {
      return 0;
    }
    if (initialSlideNumber != null) {
      return initialSlideNumber;
    }
    return null;
  });

  useEffect(() => {
    const response = fetchZodiacDetails();
    response.then((result) => {
      setZodiacDetails(result);
      if (setZodiacOnLoad) {
        zodiacSelectHandler(result[0]);
      }
    });
  }, []);

  useEffect(() => {
    if (initialSlideNumber != null && sliderRef != null) {
      sliderRef.slickGoTo(initialSlideNumber);
    }
  }, [sliderRef]);

  const selectZodiacHandler = (element, index) => {
    setSelectedZodiacIndex(index);
    zodiacSelectHandler(element, index);
  };

  const fetchZodiacDetails = async () => {
    try {
      const { result, data, msg } = await getZodiacs();
      if (result) {
        return data;
      }
    } catch (error) {}
    return [];
  };

  const createSlider = (details) => {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 6,
      slidesToScroll: 1,
      flexDirection: "row",
      responsive: [
        {
          breakpoint: 768,
          settings: {
            dots: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
            flexDirection: "row",
          },
        },
        {
          breakpoint: 440,
          settings: {
            dots: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            flexDirection: "row",
          },
        },
      ],
    };
    return (
      <Slider
        ref={(slider) => {
          setSliderRef(slider);
        }}
        {...settings}
      >
        {details?.map((element, index) => (
          <div
            onClick={() => selectZodiacHandler(element, index)}
            className="shop-category-box border-0 wow fadeIn"
            key={index}
          >
            <div className="circle-1">
              <img
                src={`/assets/images/zodiacSign/${element.name.toLowerCase()}.png`}
                className="img-fluid blur-up lazyload"
                alt={element.name}
                loading="lazy"
              />
            </div>
            <div
              className={`category-name ${
                selectedZodiacIndex == index ? "selected-category-name" : ""
              }`}
            >
              <h6>{element.name}</h6>
              <h6 style={{ fontSize: "10px" }}>{element.datePeriod}</h6>
            </div>
          </div>
        ))}
      </Slider>
    );
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="category-slider arrow-slider">
          {createSlider(zodiacDetails)}
        </div>
      </div>
    </div>
  );
};

export default ZodiacSlider;

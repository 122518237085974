import React, { useEffect, useState } from "react";
import formatDigitOfTime from "../../utils/formatTimeDateDigit";

const OtpTimer = ({ isOtpTimeExpired, setIsOtpTimeExpired }) => {
  const [elapsedTime, setElapsedTime] = useState(60);

  const formatTime = (currentTimeInSeconds) => {
    if (currentTimeInSeconds === 60) {
      return "01:00";
    } else {
      return `00:${formatDigitOfTime(currentTimeInSeconds)}`;
    }
  };

  useEffect(() => {
    let timerInterval;
    if (!isOtpTimeExpired) {
      let countTime = 0;
      const updateTimer = () => {
        countTime = countTime + 1;
        setElapsedTime(elapsedTime - countTime);
        if (countTime === 60) {
          clearInterval(timerInterval);
          setIsOtpTimeExpired(true);
          setElapsedTime(60);
        }
      };
      timerInterval = setInterval(updateTimer, 1000);
    }

    return () => clearInterval(timerInterval);
  }, [isOtpTimeExpired]);

  return (
    <div className="log-in-title">
      <h5 className="text-content">
        A code has been sent to <span>Your Mobile Number</span>
      </h5>
      {!isOtpTimeExpired ? (
        <h5>
          This otp will expired in{" "}
          <span className="text-primary">{formatTime(elapsedTime)}</span>
        </h5>
      ) : (
        <h5>The OTP has expired. Please generate a new otp.</h5>
      )}
    </div>
  );
};

export default OtpTimer;

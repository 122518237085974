import apiUrl from "../../pages/config";
import ApiRequestType from "../apiRequestType";
import ApiRequestor from "../apiRequestor";
import axios from "../axios";

const AstrologerFilterService = () => {
  const requestor = new ApiRequestor(axios);

  const getSkillsFilterData = async () => {
    const url = `${apiUrl}/astrologer/skills/primary`;
    const apiResponse = await requestor.makeRequest(ApiRequestType.GET, url);
    return apiResponse;
  };

  const getLanguagesFilterData = async () => {
    const url = `${apiUrl}/languages`;
    const apiResponse = await requestor.makeRequest(ApiRequestType.GET, url);
    return apiResponse;
  };

  const filterAstrologersList = async (reqData) => {
    const url = `${apiUrl}/astrologer/profile/filter`;
    const apiResponse = await requestor.makeRequest(
      ApiRequestType.POST,
      url,
      reqData
    );
    return apiResponse;
  };

  return {
    getSkillsFilterData,
    getLanguagesFilterData,
    filterAstrologersList,
  };
};

export default AstrologerFilterService;

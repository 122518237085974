import axios from "axios";
import { googleGeoCodingApiKey, googleGeoCodingApiUrl } from "../pages/config";

let getAddress = async (lat, long) => {
  const political_key = "political";
  const sublocality_key = "sublocality";
  let url = googleGeoCodingApiUrl;
  let queryParams = {
    latlng: [lat, long].toString(),
    lat: lat,
    long: long,
    key: googleGeoCodingApiKey,
  };
  let response = null;
  try {
    response = (await axios.get(url, { params: queryParams })).data;
  } catch (error) {
    /* empty */
  }

  if (!response || !response.results || !response.results.length > 0)
    return null;
  for (let i = 0; i < response.results.length; i++) {
    let data = response.results[i];
    if (
      data?.types.includes(political_key) ||
      data?.types.includes(sublocality_key)
    ) {
      return data.formatted_address;
    }
  }
  return null;
};

export default getAddress;

import { useLocation, useNavigate } from "react-router-dom";
import NeushopService from "../../../api/services/neushopService";
import toast from "react-hot-toast";

const CartUtilityFunctions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { addProduct, removeProduct, updateCartProduct, getCartProducts } =
    NeushopService();

  function handleError(error) {
    if (error?.response?.status === 403) {
      toast.error("You are not logged in!");
      setTimeout(() => {
        navigate("/login", {
          state: { pathname: location.pathname, ...location.state },
          replace: true,
        });
      }, 300);
      return;
    }
    const msg = error?.response?.data?.msg;
    toast.error(msg || "Facing some issue!");
  }

  const addProductToCart = async (productId, quantity) => {
    try {
      const { result, msg, data } = await addProduct({
        productId: productId,
        quantity: quantity,
      });
      if (!result) {
        toast.error(msg || "Facing some issue while adding to cart!");
        return;
      }
      return data;
    } catch (error) {
      handleError(error);
    }
  };

  const updateProductFromCart = async (productId, quantity) => {
    try {
      const { result, msg, data } = await updateCartProduct({
        productId: productId,
        quantity: quantity,
      });
      if (!result) {
        toast.error(msg || "Facing some issue while adding to cart!");
        return null;
      }
      return data;
    } catch (error) {
      handleError(error);
    }
  };

  const removeProductFromCart = async (productId) => {
    try {
      const { result, msg, data } = await removeProduct({
        productId: productId,
      });
      if (!result) {
        toast.error(msg || "Facing some issue!");
        return;
      }
      return data;
    } catch (error) {
      handleError(error);
    }
  };

  const fetchCartProducts = async () => {
    try {
      const { result, data } = await getCartProducts();
      if (!result) return null;
      return data;
    } catch (error) {}
  };

  return {
    addProductToCart,
    updateProductFromCart,
    removeProductFromCart,
    fetchCartProducts,
  };
};

export default CartUtilityFunctions;

import apiUrl from "../../pages/config";
import ApiRequestType from "../apiRequestType";
import ApiRequestor from "../apiRequestor";
import axios from "../axios";

const FestivalService = () => {
    const requestor = new ApiRequestor(axios);
    const getFestivalDetails = async (festivalId) => {
        const url = `${apiUrl}/festival/${festivalId}`
        const apiResponse = await requestor.makeRequest(ApiRequestType.GET, url)
        return apiResponse
    }

    const getFestivals = async () => {
        const url = `${apiUrl}/festival/`
        const apiResponse = await requestor.makeRequest(ApiRequestType.GET, url)
        return apiResponse
    }
    return { getFestivalDetails, getFestivals };
}

export default FestivalService
import React from "react";
import { useNavigate } from "react-router-dom";
import { PiPhoneCallFill } from "react-icons/pi";
import { AiFillMessage } from "react-icons/ai";
import { compareTwoValues } from "../../../constants/compareTwoValues";

const OnlineAstrologerCard = ({
  astrologerDetails,
  hasFreeCallChatProvision,
}) => {
  const navigate = useNavigate();
  const onlineStatus = Object.freeze({
    available: "1",
  });

  const handleOnChange = () => {
    navigate(`/astrologer_detail/${astrologerDetails._id}`, {
      state: { astrologerDetails },
    });
  };

  return (
    <div
      className="seller-grid-box tw-box-border tw-cursor-pointer seller-grid-box-1"
      onClick={handleOnChange}
    >
      <div className="grid-image mb-1">
        <div className="tw-flex tw-flex-col tw-items-center">
          <div>
            <img
              src={astrologerDetails?.profilePic}
              alt="astro-img"
              loading="lazy"
              className="rounded-circle tw-aspect-square tw-w-[65px] tw-h-[65px] tw-object-fill tw-object-center lazyload"
            />
          </div>
          <button className="nav-item tw-bg-transparent tw-text-xs tw-justify-center tw-items-center tw-flex tw-gap-1 tw-mt-1 tw-py-[2px] tw-w-14 tw-rounded-2xl tw-border tw-border-solid tw-border-[#f5b60a]">
            {astrologerDetails.averageRating ? (
              astrologerDetails.averageRating
            ) : (
              <span className="tw-text-gold-color tw-font-bold">NEW!</span>
            )}
            {astrologerDetails.averageRating > 0 && (
              <li className="flex-item-center">
                <i
                  style={{ fontWeight: "bolder" }}
                  className="fa fa-star-o tw-text-xs tw-text-[#f5b60a]"
                  aria-hidden="true"
                ></i>
              </li>
            )}
          </button>
        </div>
        <div className="contain-name">
          <h4 className="mb-1 overflow-ellipsis tw-text-sm sm:tw-text-[15px]">
            {astrologerDetails.name}
          </h4>
          <p>
            {Array.isArray(astrologerDetails.primarySkills)
              ? astrologerDetails.primarySkills.join(", ")
              : astrologerDetails.primarySkills}
          </p>
          <p>
            {Array.isArray(astrologerDetails.languages)
              ? astrologerDetails.languages.join(", ")
              : astrologerDetails.languages}
          </p>
          <p>
            {astrologerDetails.totalExperience > 1
              ? `${astrologerDetails.totalExperience} years`
              : `1 year`}{" "}
            exp
          </p>
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <div className="saller-contact d-flex align-items-center">
          <div className="seller-icon tw-mr-2 min-[528px]:tw-mr-1.5">
            <i className="fa-solid fa-phone"></i>
          </div>
          <div>
            {compareTwoValues(astrologerDetails?.isCallChatFree, true) &&
            compareTwoValues(
              hasFreeCallChatProvision,
              astrologerDetails.isCallChatFree
            ) ? (
              <>
                <span className="tw-inline-block tw-rounded-bl-lg tw-rounded-tr-lg tw-py-1 tw-px-2 tw-bg-theme-color tw-font-bold tw-shadow-card-shadow tw-text-black">
                  FREE
                </span>
                <span className="ml-1 tw-inline-block tw-text-xs text-decoration-line-through">
                  ₹{" "}
                  {astrologerDetails.discountedAudioCallRate
                    ? astrologerDetails.discountedAudioCallRate
                    : astrologerDetails.audioCallRate}
                  /Min
                </span>
              </>
            ) : (
              <div>
                <span>₹ </span>
                {astrologerDetails.discountedAudioCallRate ? (
                  <>
                    {" "}
                    <span className="text-decoration-line-through">
                      {astrologerDetails.audioCallRate}
                      /Min{" "}
                    </span>{" "}
                    {astrologerDetails.discountedAudioCallRate}
                  </>
                ) : astrologerDetails.audioCallRate ? (
                  astrologerDetails.audioCallRate
                ) : (
                  "0"
                )}
                <span>/Min</span>
              </div>
            )}
          </div>
        </div>
        <div className="d-flex align-items-center gap-3">
          <div className="d-flex flex-column align-items-center gap-1">
            <PiPhoneCallFill
              className={`astrologer-available-icon ${
                compareTwoValues(
                  astrologerDetails.availableForConversation,
                  false
                )
                  ? "tw-bg-red-500"
                  : compareTwoValues(
                      astrologerDetails.callStatus,
                      onlineStatus.available
                    )
                  ? "tw-bg-[#83c12a]"
                  : "tw-bg-[#dcdcdc]"
              }`}
            />
            <div className="contact-detail tw-text-xs">
              {compareTwoValues(
                astrologerDetails.availableForConversation,
                false
              )
                ? "Busy"
                : compareTwoValues(
                    astrologerDetails.callStatus,
                    onlineStatus.available
                  )
                ? "Call"
                : "Offline"}
            </div>
          </div>
          <div className="d-flex flex-column align-items-center gap-1">
            <AiFillMessage
              className={`astrologer-available-icon ${
                compareTwoValues(
                  astrologerDetails.availableForConversation,
                  false
                )
                  ? "tw-bg-red-500"
                  : compareTwoValues(
                      astrologerDetails.chatStatus,
                      onlineStatus.available
                    )
                  ? "tw-bg-[#83c12a]"
                  : "tw-bg-[#dcdcdc]"
              }`}
            />
            <div className="contact-detail tw-text-xs">
              {compareTwoValues(
                astrologerDetails.availableForConversation,
                false
              )
                ? "Busy"
                : compareTwoValues(
                    astrologerDetails.chatStatus,
                    onlineStatus.available
                  )
                ? "Chat"
                : "Offline"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnlineAstrologerCard;

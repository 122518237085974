import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import FeaturePanel from "../../components/FeaturePanel";
import Breadcrumb from "../../components/Breadcrumb";
import { scrollToTop } from "../../utils/scrollToTop";

function OneCard() {
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
  });
  const routes = [{ text: "Tarot" }];

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.name) {
      setError("Please Enter a Name!");
      return;
    }
    navigate("/one_card_reading", { state: { userName: formData.name } });
    scrollToTop();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <>
      <section className="section-b-space shop-section tw-p-0 tw-mb-2">
        <div className="container-fluid-lg">
          <Breadcrumb routes={routes} />
        </div>
      </section>
      <section className="blog-section tw-mt-2">
        <div className="container-fluid-lg">
          <div className="row">
            <div
              className="col-xxl-12 col-xl-12 col-lg-12 order-lg-2 max-[440px]:tw-h-[120px] tw-h-[160px] sm:tw-h-[200px]  md:tw-h-[240px] lg:tw-min-h-[300px]"
              style={{
                backgroundImage:
                  'url("../assets/images/inner-page/the-sun.webp")',
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            ></div>
            <div className="col-xxl-12 col-xl-12 col-lg-12 order-lg-2 tw-mt-5 sm:tw-mt-10">
              <div className="row">
                <div>
                  <h2 className="main-heading">One Card Tarot Reading</h2>
                </div>
                <div className="title">
                  <p className="max-[640px]:tw-p-0">
                    A one-card tarot reading involves drawing a single card from
                    a tarot deck to provide insight, guidance, or perspective on
                    a specific question or situation. The card drawn represents
                    the energies, themes, or messages relevant to the question
                    or area of concern. The interpretation of the card can offer
                    clarity, reflection, or guidance for the person. It&apos;s a
                    simple yet powerful way to gain insight into a particular
                    issue or aspect of life. Pls enter your name below to start
                    with your reading.
                  </p>
                </div>

                <div className="col-xxl-4 col-sm-12 tw-py-4 sm:tw-px-4 tw-border tw-border-solid tw-border-gold-color tw-rounded-md sm:tw-w-3/4 lg:tw-w-1/2 tw-mx-auto">
                  <font>
                    {error && <p className="tw-text-red-600">{error}</p>}
                  </font>
                  <form onSubmit={handleSubmit}>
                    <div className="tw-flex tw-items-center tw-w-full">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="Enter Your Name"
                        value={formData.name}
                        onChange={handleInputChange}
                        required
                      />
                      <button
                        type="submit"
                        className="btn tw-bg-theme-color btn-md fw-bold tw-rounded-lg tw-text-white tw-shadow-lg hover:tw-shadow-sm tw-h-[35px] tw-ml-2 sm:tw-ml-5 hover:tw-bg-opacity-80 flex-item-center tw-w-[100px] sm:tw-w-[120px]"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container-fluid-lg">
          <div className="title tw-p-[5px]">
            <p>
              Tarot is a deck of cards that has been used for centuries as a
              tool for divination, self-reflection, and guidance. A standard
              Tarot deck consists of 78 cards, divided into two main categories:
            </p>
            <ul className="shifted-list">
              <li>
                <b>Major Arcana:</b> This set of 22 cards represents significant
                life events and archetypal themes. Major Arcana cards often
                depict powerful figures, symbolic images, and universal
                concepts.
              </li>
              <li>
                <b>Minor Arcana:</b> Comprising 56 cards, the Minor Arcana is
                further divided into four suits—Cups, Wands, Swords, and
                Pentacles. Each suit corresponds to different aspects of life,
                such as emotions, creativity, intellect, and material concerns.
              </li>
            </ul>
            <p>How Tarot Readings Work:</p>
            <p>
              During a Tarot reading, a reader (or the person seeking guidance)
              shuffles the cards while focusing on their question or concern.
              Cards are then drawn and laid out in specific patterns known as
              spreads. The interpretation of the cards depends on their position
              in the spread, the symbolism in each card, and the reader&apos;s
              intuition.
            </p>
            <p>Common Tarot Spreads:</p>
            <ul className="shifted-list">
              <li>
                <b>Celtic Cross:</b> A widely used spread offering insights into
                past, present, and future aspects of a situation.
              </li>
              <li>
                <b>Three-Card Spread:</b> Provides quick answers to specific
                questions or insights into the past, present, and future.
              </li>
              <li>
                <b>Past-Present-Future:</b> Simple three-card spread focusing on
                the timeline of events.
              </li>
            </ul>
            <p>
              It&apos;s important to note that Tarot readings are subjective and
              open to interpretation. Many people use Tarot as a tool for
              self-reflection, gaining insight into their current situations,
              and exploring potential future paths. While some see Tarot as a
              form of spiritual guidance, others view it more as a psychological
              tool for reflection and introspection.
            </p>
            <p>
              The Major Arcana is a set of 22 cards in a Tarot deck, each
              carrying significant symbolism and representing archetypal themes.
              Here&apos;s a brief description of each Major Arcana card:
            </p>

            <ul className="shifted-list">
              <li>
                <b>The Fool:</b> Symbolizes new beginnings, spontaneity, and
                taking a leap of faith.
              </li>
              <li>
                <b>The Magician:</b> Represents manifestation, power, and the
                ability to transform ideas into reality.
              </li>
              <li>
                <b>The High Priestess:</b> Signifies intuition, mystery, and
                accessing the subconscious.
              </li>
              <li>
                <b>The Empress:</b> Symbolizes fertility, nurturing, and
                abundance.
              </li>
              <li>
                <b>The Emperor:</b> Represents authority, structure, and the
                father figure.
              </li>
              <li>
                <b>The Hierophant:</b> Signifies tradition, spiritual guidance,
                and education.
              </li>
              <li>
                <b>The Lovers:</b> Symbolizes partnerships, relationships, and
                choices.
              </li>
              <li>
                <b>The Chariot:</b> Represents determination, control, and
                overcoming obstacles.
              </li>
              <li>
                <b>Strength:</b> Signifies inner strength, courage, and taming
                one&apos;s impulses.
              </li>
              <li>
                <b>The Hermit:</b> Symbolizes introspection, solitude, and inner
                wisdom.
              </li>
              <li>
                <b>Wheel of Fortune:</b> Represents cycles, destiny, and the
                inevitability of change.
              </li>
              <li>
                <b>Justice:</b> Signifies fairness, balance, and legal matters.
              </li>
              <li>
                <b>The Hanged Man:</b> Symbolizes surrender, letting go, and
                gaining a new perspective.
              </li>
              <li>
                <b>Death:</b> Represents transformation, endings, and new
                beginnings.
              </li>
              <li>
                <b>Temperance:</b> Signifies balance, moderation, and harmony.
              </li>
              <li>
                <b>The Devil:</b> Symbolizes materialism, temptation, and
                bondage.
              </li>
              <li>
                <b>The Tower:</b> Represents sudden upheaval, revelation, and a
                breakthrough.
              </li>
              <li>
                <b>The Star:</b> Signifies hope, inspiration, and spiritual
                insight.
              </li>
              <li>
                <b>The Moon:</b> Symbolizes intuition, emotions, and the
                subconscious mind.
              </li>
              <li>
                <b>The Sun:</b> Represents joy, success, and vitality.
              </li>
              <li>
                <b>Judgement:</b> Signifies renewal, awakening, and a call to
                self-reflection.
              </li>
              <li>
                <b>The World:</b> Symbolizes completion, fulfillment, and a
                sense of accomplishment.
              </li>
            </ul>
            <p>
              Each Major Arcana card carries layers of meaning and can be
              interpreted in various ways, depending on the context of the
              reading and the surrounding cards. These cards often represent
              significant life events and can offer insights into the deeper
              aspects of personal and spiritual development.
            </p>
          </div>
        </div>
      </section>
      <br />
      <FeaturePanel />
      <br />
    </>
  );
}

export default OneCard;

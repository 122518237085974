import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import HomeBannerSecond from "../HomeBannerSecond";
import ZodiacSlider from "../../components/ZodiacSlider";
import ZODIAC_PERIOD from "../../constants/zodiacPeriods";
import HoroscopeLinksComponent from "./component/HoroscopeLinksComponent";
import FeaturePanel from "../../components/FeaturePanel";
import DailyZodiacHoroscopeDetails from "./component/DailyZodiacHoroscopeDetails";
import { scrollToTop } from "../../utils/scrollToTop";
import AllHoroscopeBottomList from "./component/AllHoroscopeBottomList";

const HoroscopeDaily = () => {
  const location = useLocation();
  const initialSlideNumber = location.state?.initialSlideNumber;

  const [zodiacDetail, setZodiacDetail] = useState(() => {
    return location.state?.initialSelectedZodiacDetails;
  });

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleZodiacClick = (detail, _) => {
    setZodiacDetail(detail);
  };

  return (
    <>
      <section className="home-section tw-mt-6 md:tw-mt-10">
        <div className="container-fluid-lg">
          <img
            align="right"
            src="../assets/images/logo/daily-horoscope.webp"
            width="100%"
            alt="daily-horoscope"
            loading="lazy"
          />
          <div className="row g-4"></div>
        </div>
      </section>
      {/* Top detailed Paragraph starts */}
      <section>
        <div className="container-fluid-lg">
          <div className="title">
            <h2 style={{ textAlign: "center" }}>Daily Horoscope</h2>
            <p>
              Daily horoscopes are brief astrological forecasts that offer
              insights into what the day might hold for individuals based on
              their zodiac signs. They typically cover various aspects of life
              such as love, career, health, and finances, providing a general
              overview of the energies influencing each sign. These forecasts
              are based on the positions of celestial bodies at the time of
              one&apos;s birth and the principles of astrology. People often
              read their daily horoscope to gain a sense of guidance or
              understanding about the opportunities and challenges they may
              encounter throughout the day.
            </p>
          </div>
        </div>
      </section>
      {/* Top detailed Paragraph ends */}

      <section className="category-section-2">
        <div className="container-fluid-lg">
          <HoroscopeLinksComponent selectedComponent="daily" />
          <ZodiacSlider
            zodiacSelectHandler={handleZodiacClick}
            setZodiacOnLoad={zodiacDetail == null}
            initialSlideNumber={initialSlideNumber}
          />
        </div>
      </section>

      <DailyZodiacHoroscopeDetails
        zodiacDetail={zodiacDetail}
        period={ZODIAC_PERIOD.DAILY}
      />

      <HomeBannerSecond />
      <section>
        <div className="container-fluid-lg">
          <div className="title" style={{ padding: " 5px" }}>
            <h2>Benefits of Daily Horoscope</h2>
            <span className="title-leaf">
              <svg className="icon-width">
                <Link to="https://themes.pixelstrap.com/fastkart/assets/svg/leaf.svg#leaf"></Link>
              </svg>
            </span>
            <p>
              A horoscope is a forecast of a person&apos;s future based on the
              positions of celestial bodies (such as planets and stars) at a
              specific time, usually the time of their birth. It is commonly
              associated with astrology and is used to provide insights into
              various aspects of life, including personality traits,
              relationships, and potential future events. Horoscopes are often
              divided into twelve astrological signs, each representing
              different periods of the year. People often read horoscopes to
              gain a better understanding of themselves or to get predictions
              about their future.
            </p>
            <p>
              Reading a daily horoscope can offer several potential benefits:
            </p>
            <ul className="shifted-list">
              <li>
                <b>Guidance: </b>Horoscopes can provide guidance and insight
                into the potential opportunities and challenges that may arise
                in your day. They might offer suggestions on how to navigate
                certain situations or make decisions.
              </li>
              <li>
                <b>Self-reflection: </b>Daily horoscopes can encourage
                self-reflection by prompting you to consider how the
                astrological predictions relate to your own life experiences and
                feelings.
              </li>
              <li>
                <b>Awareness: </b>Horoscopes can help you become more aware of
                astrological influences and how they may impact your mood,
                behavior, or interactions with others.
              </li>
              <li>
                <b>Entertainment: </b>For many people, reading their daily
                horoscope is simply a form of entertainment or a fun ritual to
                start their day.
              </li>
              <li>
                <b>Validation: </b>Sometimes, reading your horoscope can provide
                validation for what you&apos;re currently experiencing or
                feeling. It can reassure you that others might be going through
                similar situations.
              </li>
            </ul>
          </div>
        </div>
      </section>
      <AllHoroscopeBottomList />
      <FeaturePanel />
      <br />
    </>
  );
};

export default HoroscopeDaily;

import useAxiosPrivateHook from "../../hooks/useAxiosPrivate";
import apiUrl from "../../pages/config";
import ApiRequestType from "../apiRequestType";
import ApiRequestor from "../apiRequestor";

const ConversationService = () => {
  const axiosPrivate = useAxiosPrivateHook();
  const requestor = new ApiRequestor(axiosPrivate);

  const createConversation = async (
    astrologerId,
    astrologerName,
    astrologerImage,
    conversationType,
  ) => {
    const url = `${apiUrl}/conversation/create`;
    const reqData = {
      astrologerId,
      astrologerName,
      astrologerImage,
      conversationType,
    };
    const apiResponse = await requestor.makeRequest(
      ApiRequestType.POST,
      url,
      reqData
    );
    return apiResponse;
  };

  const startConversation = async (
    conversationId,
    astrologerId,
    astrologerName,
    astrologerImage,
    conversationType,
    conversationInput
  ) => {
    const url = `${apiUrl}/conversation/start`;
    const reqData = {
      conversationId,
      astrologerId,
      astrologerName,
      astrologerImage,
      conversationType,
      conversationInput,
    };
    const apiResponse = await requestor.makeRequest(
      ApiRequestType.POST,
      url,
      reqData
    );
    return apiResponse;
  };

  const endConversation = async (conversationId) => {
    const url = `${apiUrl}/conversation/end`;
    const reqData = {
      conversationId: conversationId,
    };
    const apiResponse = await requestor.makeRequest(
      ApiRequestType.POST,
      url,
      reqData
    );
    return apiResponse;
  };

  const getChatList = async () => {
    const url = `${apiUrl}/conversation/customer/chat`;
    const apiResponse = await requestor.makeRequest(ApiRequestType.GET, url);
    return apiResponse;
  };

  return { createConversation, startConversation, endConversation, getChatList };
};

export default ConversationService;

import ApiRequestType from "./apiRequestType";

class ApiGetRequestor {
  constructor(client) {
    this.client = client;
  }
  makeRequest = async (url) => {
    let response = await this.client.get(url);
    return response.data;
  };
}

class ApiGetFileRequestor {
  constructor(client) {
    this.client = client;
  }

  _extractFileName(apiResponse) {
    const contentDisposition = apiResponse.headers["content-disposition"];
    if (contentDisposition && contentDisposition.includes("filename=")) {
      return contentDisposition.split("filename=")[1];
    }
    return "";
  }

  makeRequest = async (url) => {
    let response = await this.client.get(url, {
      responseType: "blob",
    });
    const filename = this._extractFileName(response);
    return { data: response.data, filename };
  };
}

class ApiPostRequestor {
  constructor(client) {
    this.client = client;
  }
  makeRequest = async (url, reqData) => {
    let response = await this.client.post(url, reqData);
    return response.data;
  };
}

class ApiPutRequestor {
  constructor(client) {
    this.client = client;
  }
  makeRequest = async (url, reqData) => {
    let response = await this.client.put(url, reqData);
    return response.data;
  };
}

class ApiPatchRequestor {
  constructor(client) {
    this.client = client;
  }
  makeRequest = async (url, reqData) => {
    let response = await this.client.patch(url, reqData);
    return response.data;
  };
}

class ApiPutFileRequestor {
  constructor(client) {
    this.client = client;
  }
  makeRequest = async (url, reqData) => {
    let response = await this.client.put(url, reqData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  };
}

class ApiRequestor {
  constructor(client) {
    this.getRequestor = new ApiGetRequestor(client);
    this.getFileRequestor = new ApiGetFileRequestor(client);
    this.postRequestor = new ApiPostRequestor(client);
    this.patchRequestor = new ApiPatchRequestor(client);
    this.putRequestor = new ApiPutRequestor(client);
    this.putFileRequestor = new ApiPutFileRequestor(client);
  }
  makeRequest = async (type, url, reqData) => {
    switch (type) {
      case ApiRequestType.GET:
        return await this.getRequestor.makeRequest(url);
      case ApiRequestType.GET_FILE:
        return await this.getFileRequestor.makeRequest(url);
      case ApiRequestType.POST:
        return await this.postRequestor.makeRequest(url, reqData);
      case ApiRequestType.PATCH:
        return await this.patchRequestor.makeRequest(url, reqData);
      case ApiRequestType.PUT:
        return await this.putRequestor.makeRequest(url, reqData);
      case ApiRequestType.PUT_FILE:
        return await this.putFileRequestor.makeRequest(url, reqData);
      default:
        throw Error("Invalid ApiRequestType");
    }
  };
}

export default ApiRequestor;

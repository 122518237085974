import React, { useState } from "react";
import { changeTimeFormat } from "../../../utils/formatDate";
import PanchangService from "../../../api/services/panchangService";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";

const HoraDetail = ({
  getFormData,
  isHoraDropdownOpen,
  setIsHoraDropdownOpen,
  updateFlag,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [horaDetails, setHoraDetails] = useState(null);
  const { getHoraMuhurta } = PanchangService();

  const fetchHoraDetails = async () => {
    setIsLoading(true);
    try {
      const reqData = getFormData();
      const { result, data, msg } = await getHoraMuhurta(reqData);
      if (!result) {
        toast.error(msg || "Facing some issue while fetching details!");
        return;
      }
      setHoraDetails(data.hora);
      updateFlag.horaFlag = false;
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnClick = () => {
    updateFlag.horaFlag && fetchHoraDetails();
    setIsHoraDropdownOpen(!isHoraDropdownOpen);
  };

  return (
    <div className="mt-3 seller-grid-box tw-h-fit">
      <div
        className="tw-bg-theme-color tw-w-full tw-text-base tw-text-white tw-p-2 text-center hover:tw-bg-opacity-80 tw-transition-all hover:tw-text-gray-200 tw-cursor-pointer flex-item-between tw-shadow"
        onClick={handleOnClick}
      >
        <h3 className="tw-text-base">Hora Muhurat</h3>
        {isLoading ? (
          <Loader />
        ) : (
          <span
            className={`common-carot ${
              isHoraDropdownOpen ? "tw-rotate-45" : "-tw-rotate-45"
            } tw-p-[3px]`}
          ></span>
        )}
      </div>
      {isHoraDropdownOpen && horaDetails && (
        <table className="table-container w-100 table-bordered">
          <tr className="text-center p-0 bg-light-gray">
            <td colSpan={2} className="py-1">
              <b className="p-0">Day</b>
            </td>
          </tr>
          {horaDetails?.day?.map((item, index) => (
            <tr key={index}>
              <td className="col-6">
                <b>{item?.hora}</b>
              </td>
              <td className="col-6">{changeTimeFormat(item?.time, " : ")}</td>
            </tr>
          ))}
          <tr className="text-center p-0 bg-light-gray">
            <td colSpan={2} className="py-1">
              <b className="p-0">Night</b>
            </td>
          </tr>
          {horaDetails?.night?.map((item, index) => (
            <tr key={index}>
              <td className="col-6">
                <b>{item?.hora}</b>
              </td>
              <td className="col-6">{changeTimeFormat(item?.time, " : ")}</td>
            </tr>
          ))}
        </table>
      )}
    </div>
  );
};

export default HoraDetail;

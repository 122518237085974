const convertUTCToLocalTime = (dateString) => {
  if (!dateString) {
    return dateString;
  }
  let localTime = new Date(dateString);
  return localTime.toLocaleString("en-US", {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const convertUTCToLocalDate = (dateString) => {
  if (!dateString) {
    return dateString;
  }
  let localTime = new Date(dateString);
  return localTime.toLocaleString("en-US", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
};

export const convertToLocalDate = (year, month, day) => {
  return new Date(year, month - 1, day).toLocaleDateString("en-US", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
};

export const convertLocalDateToUTCFormat = (dateString, timeString) => {
  return new Date(`${dateString}T${timeString}`).toISOString();
};

export default convertUTCToLocalTime;

import { configureStore } from "@reduxjs/toolkit";
import CartSlice from "./slices/CartSlice";
import AstrologerSlice from "./slices/AstrologerSlice";

export const store = configureStore({
  reducer: {
    cart: CartSlice,
    astrologer : AstrologerSlice,
  },
});

/* eslint-disable react/prop-types */
import React from "react";
import { useRef, useState } from "react";
import {
  dayOptionsGenerator,
  hourOptionsGenerator,
  minuteOptionsGenerator,
  monthOptionsGenerator,
  yearOptionsGenerator,
} from "../utils/dobBirthTimeGenerator";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

export default function BirthDetailsForm({
  propogateInputChanges,
  formIdentifier,
}) {
  const [address, setAddress] = useState("");

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLngDetails = await getLatLng(results[0]);
    propogateInputChanges("address", value, formIdentifier);
    propogateInputChanges("lat", latLngDetails.lat, formIdentifier);
    propogateInputChanges("lng", latLngDetails.lng, formIdentifier);
    setAddress(value);
  };

  const birthDetails = useRef({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    birthDetails.current[name] = value;
    propogateInputChanges(name, value, formIdentifier);
  };

  return (
    // <div className="col-lg-6 col-md-6">
    <div className="seller-grid-box">
      <div className="grid-contain">
        <div className="col-lg-12">
          <div className="right-sidebar-box">
            <div className="row">
              <div className="col-xxl-6 col-lg-6 col-sm-6">
                <div className="mb-md-4 mb-3 custom-form">
                  <label htmlFor="name" className="form-label">
                    Name:<span className="tw-text-red-600">*</span>
                  </label>
                  <div className="custom-input">
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      placeholder="Enter Your Name"
                      value={birthDetails.current.name}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>

              <div className="col-xxl-6 col-lg-6 col-sm-6">
                {/* Male Gender */}
                <div className="mb-md-4 mb-3 custom-form">
                  <label htmlFor="gender" className="form-label">
                    Gender:<span className="tw-text-red-600">*</span>
                  </label>
                  <div className="custom-input">
                    <select
                      style={{ padding: "0px 0px 0px 10px" }}
                      type="text"
                      className="form-control"
                      id="gender"
                      name="gender"
                      placeholder="Select Gender"
                      value={birthDetails.current.gender}
                      onChange={handleInputChange}
                    >
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xxl-6 col-lg-6 col-sm-6">
                {/* Male Date of Birth */}
                <div className="mb-md-4 mb-3 custom-form">
                  <label htmlFor="dob" className="form-label">
                    Date of Birth:<span className="tw-text-red-600">*</span>
                  </label>
                  <div className="custom-input">
                    <div className="tw-w-full tw-flex tw-gap-x-1">
                      <select
                        style={{
                          width: "32%",
                          height: "45px",
                        }}
                        name="day"
                        value={birthDetails.current.day}
                        onChange={handleInputChange}
                        className="form-control"
                      >
                        <option>Day</option>
                        {dayOptionsGenerator()}
                      </select>
                      <select
                        style={{
                          width: "32%",
                          height: "45px",
                        }}
                        name="month"
                        value={birthDetails.current.month}
                        onChange={handleInputChange}
                        className="form-control"
                      >
                        <option>Month</option>
                        {monthOptionsGenerator()}
                      </select>
                      <select
                        style={{
                          width: "32%",
                          height: "45px",
                        }}
                        name="year"
                        value={birthDetails.current.year}
                        onChange={handleInputChange}
                        className="form-control"
                      >
                        <option>Year</option>
                        {yearOptionsGenerator()}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xxl-6 col-lg-6 col-sm-6">
                {/* Male Birth Time */}
                <div className="mb-md-4 mb-3 custom-form">
                  <label htmlFor="birthTime" className="form-label">
                    Birth Time:<span className="tw-text-red-600">*</span>
                  </label>
                  <div className="custom-input">
                    <div className="tw-w-full tw-flex tw-gap-x-1">
                      <select
                        style={{
                          width: "32%",
                          height: "45px",
                        }}
                        name="hour"
                        value={birthDetails.current.hour}
                        onChange={handleInputChange}
                        className="form-control"
                      >
                        <option>Hour</option>
                        {hourOptionsGenerator()}
                      </select>
                      <select
                        style={{
                          width: "32%",
                          height: "45px",
                        }}
                        name="min"
                        value={birthDetails.current.min}
                        onChange={handleInputChange}
                        className="form-control"
                      >
                        <option>Minute</option>
                        {minuteOptionsGenerator()}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xxl-12 col-lg-12 col-sm-12">
                <div className="mb-md-6 mb-3 custom-form">
                  <label htmlFor="name" className="form-label">
                    Birth Place:<span className="tw-text-red-600">*</span>
                  </label>
                  <div className="custom-input">
                    <PlacesAutocomplete
                      value={address}
                      onChange={(newValue) => setAddress(newValue)}
                      onSelect={handleSelect}
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <input
                            type="text"
                            className="form-control"
                            id="place"
                            name="birthplace"
                            placeholder="Enter Your Birth-place"
                            value={birthDetails.current.birthplace}
                            onChange={handleInputChange}
                            {...getInputProps({
                              placeholder: "Type address",
                            })}
                          />
                          <div>
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion, index) => {
                              const style = {
                                backgroundColor: suggestion.active
                                  ? "#41b6e6"
                                  : "#fff",
                              };
                              return (
                                <div
                                  key={index}
                                  {...getSuggestionItemProps(suggestion, {
                                    style,
                                  })}
                                >
                                  {suggestion.description}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
}

import React from "react";
import { Link } from "react-router-dom";

const AllHoroscopeBottomList = () => {
  return (
    <section className="product-list-section section-b-space">
      <div className="container-fluid-lg">
        <div className="title">
          <h2>Also Check</h2>
          <span className="title-leaf">
            <svg className="icon-width">
              <Link to="https://themes.pixelstrap.com/fastkart/assets/svg/leaf.svg#leaf"></Link>
            </svg>
          </span>
        </div>
        <div className="row g-4">
          <div className="col-xxl-3 col-md-3">
            <Link to="/horoscope_daily" className="tw-text-[#222]">
              <div className="product-box-4 wow fadeInUp">
                <div className="product-header">
                  <div className="product-image">
                    <img
                      src="../assets/images/inner-page/wheelCircle.png"
                      className="img-fluid blur-up lazyload"
                      alt="daily_horoscope_icon"
                      loading="lazy"
                    />
                  </div>
                </div>

                <div className="product-footer">
                  <div className="product-detail">
                    <h5 className="name">Daily Horoscope</h5>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-xxl-3 col-md-3">
            <Link to="/horoscope_page" className="tw-text-[#222]">
              <div className="product-box-4 wow fadeInUp">
                <div className="product-header">
                  <div className="product-image">
                    <img
                      src="../assets/images/horoscopes/weeklyHoroscopeCircle.png"
                      className="img-fluid blur-up lazyload"
                      alt="weekly_horoscope_icon"
                      loading="lazy"
                    />
                  </div>
                </div>

                <div className="product-footer">
                  <div className="product-detail">
                    <h5 className="name">Weekly Horoscope</h5>
                  </div>
                </div>
              </div>
            </Link>
          </div>

          <div className="col-xxl-3 col-md-3">
            <Link to="/horoscope_monthly" className="tw-text-[#222]">
              <div className="product-box-4 wow fadeInUp">
                <div className="product-header">
                  <div className="product-image">
                    <img
                      src="../assets/images/inner-page/monthlyWheelCircle.png"
                      className="img-fluid blur-up lazyload"
                      alt="monthly_horoscope_icon"
                      loading="lazy"
                    />
                  </div>
                </div>

                <div className="product-footer">
                  <div className="product-detail">
                    <h5 className="name">Monthly Horoscope</h5>
                  </div>
                </div>
              </div>
            </Link>
          </div>

          <div className="col-xxl-3 col-md-3">
            <Link to="/horoscope_yearly" className="tw-text-[#222]">
              <div className="product-box-4 wow fadeInUp">
                <div className="product-header">
                  <div className="product-image">
                    <img
                      src="../assets/images/inner-page/yearlyWheelCircle.png"
                      className="img-fluid blur-up lazyload"
                      alt="yearly_horoscope_icon"
                      loading="lazy"
                    />
                  </div>
                </div>

                <div className="product-footer">
                  <div className="product-detail">
                    <h5 className="name">Yearly Horoscope</h5>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AllHoroscopeBottomList;

import React, { useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import BirthDetailsForm from "../../../components/BirthDetailsForm";
import { scrollToTop } from "../../../utils/scrollToTop";
import Breadcrumb from "../../../components/Breadcrumb";
import { convertToLocalDate } from "../../../utils/utcToLocalTimeConvertor";
import LatLngTimezoneService from "../../../api/services/latLngTimezoneService";
import KundliService from "../../../api/services/kundliService";
import Loader from "../../../components/Loader";

const KundaliForm = () => {
  const navigate = useNavigate();
  const routes = [{ text: "Kundli" }];
  const kundaliFormData = useRef({});
  const [error, setError] = useState("");
  const { getTimezone } = LatLngTimezoneService();
  const { getKundliResponse } = KundliService();
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (name, value) => {
    kundaliFormData.current[name] = value;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!kundaliFormData.current.day) {
      setError("Please Select a day.");
      return;
    }

    if (!kundaliFormData.current.month) {
      setError("Please select a month.");
      return;
    }

    if (!kundaliFormData.current.year) {
      setError("Please select a year.");
      return;
    }
    if (!kundaliFormData.current.hour) {
      setError("Please select an hour.");
      return;
    }

    if (!kundaliFormData.current.min) {
      setError("Please select a minute.");
      return;
    }

    if (!kundaliFormData.current.name) {
      setError("Please enter a name.");
      return;
    }
    if (!kundaliFormData.current.gender) {
      setError("Please select a gender.");
      return;
    }

    if (!kundaliFormData.current.address) {
      setError("Please Enter birthplace.");
      return;
    }

    const api = "birth_details";
    let timezone = "";
    try {
      setIsLoading(true);
      const dob = convertToLocalDate(
        kundaliFormData.current.year,
        kundaliFormData.current.month,
        kundaliFormData.current.day
      );
      const timezoneResponse = await getTimezone(
        kundaliFormData.current.lat,
        kundaliFormData.current.lng,
        dob
      );
      timezone = timezoneResponse.timezone.toString();
      if (!timezone) {
        return;
      }
    } catch (error) {
      setIsLoading(false);
      setError("Faced an issue while fetching timezone!");
    }

    const data = {
      day: Number(kundaliFormData.current.day),
      month: Number(kundaliFormData.current.month),
      year: Number(kundaliFormData.current.year),
      hour: Number(kundaliFormData.current.hour),
      min: Number(kundaliFormData.current.min),
      lat: parseFloat(kundaliFormData.current.lat),
      lon: parseFloat(kundaliFormData.current.lng),
      tzone: timezone,
      name: kundaliFormData.current.name,
      birthplace: kundaliFormData.current.address,
      gender: kundaliFormData.current.gender,
    };

    try {
      const apiResponse = await getKundliResponse(api, data);
      if (!apiResponse) {
        return;
      }
      scrollToTop();
      navigate("/self_kundali", {
        state: {
          selectedLocation: kundaliFormData.current.address,
          responseData: apiResponse,
          timezone: timezone,
        },
      });
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <section className="section-b-space shop-section tw-p-0">
        <div className="container-fluid-lg">
          <Breadcrumb routes={routes} />
        </div>
      </section>

      <section className="fresh-vegetable-section section-lg-space tw-m-0">
        <div className="container-fluid-lg">
          <div className="title tw-p-[5px]">
            <div className="tw-flex tw-flex-col tw-gap-y-6 min-[850px]:tw-flex-row min-[850px]:tw-gap-x-5">
              <div className="min-[850px]:tw-w-3/12">
                <img
                  className="img2 lazyload"
                  src="../assets/images/logo/kundli.jpeg"
                  alt="kundli_pic"
                  loading="lazy"
                  width="170"
                  height="170"
                />
              </div>
              <div className="min-[850px]:tw-w-9/12">
                <h2>Kundli</h2>
                <p>
                  Janam Kundli, also known as Janam Patri or Birth Chart, is a
                  map of the celestial bodies&apos; positions at the exact time
                  of an individual&apos;s birth. It is based on Vedic astrology
                  and is used to understand various aspects of a person&apos;s
                  life, including their personality, career, relationships,
                  health, and more. Janam Kundli is created using the date,
                  time, and place of birth of an individual and is divided into
                  twelve houses, each representing different aspects of life.
                  Astrologers analyze the positions of planets, their aspects,
                  and other astrological factors in the Janam Kundli to make
                  predictions and provide insights into the individual&apos;s
                  life journey.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="client-section section-lg-space" id="kundali-form">
        <div className="container-fluid-lg">
          <div className="row">
            <div className="col-12">
              <div className="about-us-title text-center">
                <h2 className="center">Get Your Kundli</h2>
              </div>
            </div>

            <div className="seller-grid-box">
              <div className="grid-contain">
                <div className="col-lg-12">
                  <div>
                    <font className="tw-text-[#ff4f4f]">
                      {error && <p className="tw-text-[#ff4f4f]">{error}</p>}
                    </font>
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-xxl-12 col-lg-12 col-sm-12">
                          <BirthDetailsForm
                            propogateInputChanges={handleInputChange}
                            formIdentifier={"firstForm"}
                          />
                        </div>
                      </div>
                      <div className="row tw-pb-2 tw-mt-[15px]">
                        <div className="mx-auto tw-w-fit">
                          <button
                            type="submit"
                            className="btn tw-bg-theme-color tw-h-[40px] tw-w-[140px] btn-md fw-bold tw-rounded-lg tw-text-white tw-shadow-lg hover:tw-shadow-sm tw-mt-3 hover:tw-bg-opacity-80 "
                          >
                            {isLoading ? <Loader /> : "Get Your Kundli"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default KundaliForm;

import { useEffect, useRef, useState } from "react";
import { viewportRootMargin } from "../pages/config";

const options = {
  root: null,
  rootMargin: viewportRootMargin,
  threshold: 0,
};

export const useVisible = (targetRef) => {
  const [isVisible, setIsVisible] = useState(false);
  const observerRef = useRef();

  const setIsVisibleCallback = ([entry]) => {
    if (entry.isIntersecting) setIsVisible(entry.isIntersecting);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(setIsVisibleCallback, options);

    if (targetRef.current) {
      observer.observe(targetRef.current);
      observerRef.current = observer;
    }

    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, []);

  return { isVisible, observerRef };
};

import React, { useContext, useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthProvider";
import LoginService from "../../api/services/loginService";
import OtpTimer from "./OtpTimer";

const VerifyOtp = ({ inputValue, otpId }) => {
  const { setAuth } = useContext(AuthContext);
  const location = useLocation();
  const from = location.state?.pathname || "/";
  const [inputOtp, setInputOtp] = useState("");
  const navigate = useNavigate();
  const [verifyOtp, setVerifyOtp] = useState({
    _id: otpId.current,
    otp: "",
  });
  const { verifyOtpNumber, resendOtpNumber } = LoginService();
  const [isOtpTimeExpired, setIsOtpTimeExpired] = useState(false);

  useEffect(() => {
    let otp = inputOtp;
    setVerifyOtp({ ...verifyOtp, otp });
  }, [inputOtp]);

  const resendOtp = async () => {
    setIsOtpTimeExpired(false);
    try {
      const otpResponse = await resendOtpNumber(
        "resend_otp",
        inputValue.current
      );
      if (otpResponse) {
        toast.success(otpResponse.msg);
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  const submitOtpHandle = async (event) => {
    event.preventDefault();
    try {
      const { result, data, msg } = await verifyOtpNumber(
        "verify_otp",
        verifyOtp
      );

      if (!result) {
        toast.error("Invalid Otp");
        return;
      }

      toast.success(msg);
      setTimeout(() => {
        if (!data.isRegistered) {
          navigate("/login_new_user_details", {
            state: {
              customerDetails: data,
            },
          });
          return;
        }
        setAuth({
          userId: data?.userId,
          authToken: data?.authToken,
          customerDetails: {
            hasFreeCallChatProvision: data?.hasFreeCallChatProvision,
          },
        });
        navigate(from, {
          replace: true,
          state: location.state,
        });
      }, 2000);
    } catch (error) {
      if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.msg);
      }
    }
  };

  const inputStyle = {
    width: "100%",
    height: "50px",
  };

  const otpInputStyle = {
    width: "100px",
    height: "100px",
  };

  const inputProps = {
    type: "number",
  };

  return (
    <div>
      <OtpTimer
        isOtpTimeExpired={isOtpTimeExpired}
        setIsOtpTimeExpired={setIsOtpTimeExpired}
      />

      <form action="" method="post">
        <div id="otp" className="inputs d-flex flex-row justify-content-center">
          <OtpInput
            value={inputOtp}
            onChange={setInputOtp}
            numInputs={4}
            inputStyle={inputStyle}
            style={otpInputStyle}
            renderInput={(props) => <input {...props} />}
            inputProps={inputProps}
          />
        </div>

        <div className="send-box pt-4 " onClick={resendOtp}>
          {isOtpTimeExpired && (
            <h5>
              Didn&apos;t get the code?{" "}
              <a href="javascript:void(0)" className="theme-color fw-bold">
                Resend It
              </a>
            </h5>
          )}
        </div>
        <a>
          <button
            className="btn mt-3 hover-btn tw-shadow-lg tw-bg-theme-color tw-text-white tw-font-semibold"
            type="submit"
            onClick={submitOtpHandle}
          >
            Verify Otp
          </button>
        </a>
      </form>
    </div>
  );
};

export default VerifyOtp;

export const CurrencyType = Object.freeze({
  INDIAN: "INR",
});

export const getCurrencySymbol = (currencyType) => {
  switch (currencyType) {
    case CurrencyType.INDIAN:
      return "₹";
  }
};

import firebase from "firebase/compat/app";
import "firebase/compat/database";

const firebaseConfig = {
  apiKey: "AIzaSyBBkc4Skng5HPeSZcXMGSMQbXtll7BFbrY",
  authDomain: "neuastroapplication.firebaseapp.com",
  databaseURL:
    "https://neuastroapplication-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "neuastroapplication",
  storageBucket: "neuastroapplication.appspot.com",
  messagingSenderId: "470188157068",
  appId: "1:470188157068:web:87db470b926ba4d26633a9",
  measurementId: "G-XZJLLN2823",
};

// Initialize Firebase
// const app = initializeApp(firebaseConfig);

firebase.initializeApp(firebaseConfig);

export default firebase;

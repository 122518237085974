import React from "react";
import { useLocation } from "react-router-dom";
import { HeadMetaData } from "../constants/headMetaData";
import SEO from "./SEO";

const SEOLayout = () => {
  const location = useLocation();
  return HeadMetaData[location.pathname] ? (
    <SEO
      title={HeadMetaData[location.pathname].title}
      description={HeadMetaData[location.pathname].description}
      type={HeadMetaData[location.pathname].type}
      link={location.pathname}
      keywords={HeadMetaData[location.pathname].keywords}
    />
  ) : (
    <SEO
      title={HeadMetaData.DEFAULT.title}
      description={HeadMetaData.DEFAULT.description}
      type={HeadMetaData.DEFAULT.type}
      link={location.pathname}
      keywords={HeadMetaData.DEFAULT.keywords}
    />
  );
};

export default SEOLayout;

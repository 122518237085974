import React from "react";

const SkillsFilter = ({ skillOptions, setSkillOptions, skillsFilterData }) => {
  const handleSelectAllSkills = (event) => {
    event.stopPropagation();
    const allSkillsDictionary = skillsFilterData.reduce((acc, currentData) => {
      acc[currentData.tag] = currentData.tag;
      return acc;
    }, {});
    setSkillOptions({ ...allSkillsDictionary });
  };

  const handleClearSkills = (event) => {
    event.stopPropagation();
    setSkillOptions({});
  };

  const handleOnSkillClick = (event) => {
    const currentOption = event.target.name;
    const currentValue = event.target.value;
    setSkillOptions((previousValue) => ({
      ...previousValue,
      [currentOption]: skillOptions[currentOption] ? false : currentValue,
    }));
  };

  return (
    <div className="tw-px-0 tw-w-full">
      <div className="category-filter-box custom-scroll tw-w-full">
        <div className="category-action-box tw-w-full">
          <span className="category-action-tab" onClick={handleSelectAllSkills}>
            Select all
          </span>
          <span className="category-action-tab" onClick={handleClearSkills}>
            Clear
          </span>
        </div>
        {skillsFilterData?.map((skillData) => (
          <label
            key={skillData._id}
            htmlFor={skillData.tag}
            className="flex-center category-option-lable tw-w-full"
          >
            <div className="checkbox-container tw-mb-[18px]">
              <input
                type="checkbox"
                className="checkbox-input"
                id={skillData.tag}
                name={skillData.tag}
                value={skillData.tag}
                onChange={handleOnSkillClick}
              />
              <span
                className={`checkmark ${
                  skillOptions[skillData.tag]
                    ? "checkmark-checked"
                    : "tw-bg-gray-200"
                } `}
              ></span>
            </div>
            <p className="category-option-para">{skillData.tag}</p>
          </label>
        ))}
      </div>
    </div>
  );
};

export default SkillsFilter;

import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Home from "./pages/home/Home";
import LoginPage from "./pages/loginSignUp/LoginPage";
import Kundali from "./pages/kundli/Kundali";
import KundaliMilan from "./pages/kundliMilan/KundaliMilan";
import BlogPage from "./pages/blog/BlogPage";
import HoroscopeWeekly from "./pages/horoscope/HoroscopeWeekly";
import HoroscopeYearly from "./pages/horoscope/HoroscopeYearly";
import HoroscopeMonthly from "./pages/horoscope/HoroscopeMonthly";
import Chat from "./pages/chat/Chat";
import OneCard from "./pages/tarot/OneCard";
import Numerology from "./pages/Numerology";
import HeaderFooterLayout from "./components/HeaderFooterLayout";
import RequireAuth from "./components/RequireAuth";
import PanchangPage from "./pages/panchang/PanchangPage";
import SuspenceLayout from "./components/SuspenceLayout";
import {
  LazyAboutus,
  LazyAstrolive,
  LazyAstrologerDetail,
  LazyAudioCall,
  LazyBlogDetail,
  LazyBookOfflinePuja,
  LazyCancelPayment,
  LazyChatHistorySummary,
  LazyConversationInitialInput,
  LazyDisclaimer,
  LazyFestivalDetail,
  LazyKundalireport,
  LazyLoginUserDetialsPage,
  LazyMahurt,
  LazyNeuShopCart,
  LazyNeuShopCheckout,
  LazyNeuShopItemDetails,
  LazyOfflinePujaCheckout,
  LazyOfflinePujaPayment,
  LazyOneCardReading,
  LazyOneCardResult,
  LazyPayment,
  LazyPaymentStatus,
  LazyPrivacyPolicy,
  LazyProfile,
  LazyRating,
  LazyRuling,
  LazySelfKundli,
  LazyStreaming,
  LazyTermcondition,
  LazyVideoCall,
} from "./lazyLoadRoutes";
import AstrologersList from "./pages/astrologerListScreen/AstrologersList";
import OfflinePuja from "./pages/offlinePuja/OfflinePuja";
import PujaDescription from "./pages/offlinePuja/PujaDescription";
import HoroscopeDaily from "./pages/horoscope/HoroscopeDaily";
import NeuShopHome from "./pages/neushop/home/NeuShopHome";
import PersistLogin from "./components/PersistLogin";
import NotFound from "./components/NotFound";

function App() {
  return (
    <Routes>
      <Route element={<PersistLogin />}>
        <Route path="/" element={<HeaderFooterLayout />}>
          {/* Authenticated routes */}
          <Route element={<RequireAuth />}>
            <Route path="chat" element={<Chat />} />
            <Route element={<SuspenceLayout />}>
              <Route path="profile" element={<LazyProfile />} />
              <Route
                path="astrologer_detail/:astrologerId"
                element={<LazyAstrologerDetail />}
              />
              {/* <Route path="puja_list_detail" element={<LazyPujaListDetail />} /> */}
              <Route path="audio_call" element={<LazyAudioCall />} />
              <Route path="video_call" element={<LazyVideoCall />} />
              <Route
                path="conversation_initial_input"
                element={<LazyConversationInitialInput />}
              />
              <Route path="payment" element={<LazyPayment />} />
              <Route path="streaming" element={<LazyStreaming />} />
              <Route path="neu_shop/cart" element={<LazyNeuShopCart />} />
              <Route
                path="neu_shop/checkout"
                element={<LazyNeuShopCheckout />}
              ></Route>
              <Route
                path="offline_puja/booking/payment"
                element={<LazyOfflinePujaPayment />}
              />
              <Route
                path="offline_puja/booking/payment/checkout"
                element={<LazyOfflinePujaCheckout />}
              />
              <Route
                path="offline_puja/booking"
                element={<LazyBookOfflinePuja />}
              />
              <Route
                path="chat_history"
                element={<LazyChatHistorySummary />}
              ></Route>
            </Route>
          </Route>
          {/* Un-Authenticated routes */}
          <Route path="login" element={<LoginPage />} />
          <Route path="/" element={<Home />} />
          <Route path="kundali" element={<Kundali />} />
          <Route path="kundali_milan" element={<KundaliMilan />} />
          <Route path="blog" element={<BlogPage />} />
          {/* <Route path="online_puja" element={<OnlinePuja />} />
          <Route
            path="online_puja/:onlinePujaId"
            element={<OnlinePujaDetails />}
          /> */}
          <Route path="offline_puja" element={<OfflinePuja />} />
          <Route path="offline_puja/:pujaId" element={<PujaDescription />} />
          <Route path="horoscope_page" element={<HoroscopeWeekly />}></Route>
          <Route path="astrologers_list" element={<AstrologersList />}></Route>
          <Route path="horoscope_daily" element={<HoroscopeDaily />}></Route>
          <Route path="horoscope_yearly" element={<HoroscopeYearly />}></Route>
          <Route
            path="horoscope_monthly"
            element={<HoroscopeMonthly />}
          ></Route>
          <Route path="panchang_page" element={<PanchangPage />}></Route>
          <Route path="one_card" element={<OneCard />}></Route>
          <Route path="numerology" element={<Numerology />}></Route>
          <Route path="neu_shop" element={<NeuShopHome />}></Route>
          <Route element={<SuspenceLayout />}>
            <Route
              path="login_new_user_details"
              element={<LazyLoginUserDetialsPage />}
            />
            <Route path="astrolive" element={<LazyAstrolive />} />
            <Route path="privacy_policy" element={<LazyPrivacyPolicy />} />
            <Route path="about_us" element={<LazyAboutus />} />
            <Route path="kundali_report" element={<LazyKundalireport />} />
            <Route path="self_kundali" element={<LazySelfKundli />} />
            <Route path="ruling" element={<LazyRuling />} />
            <Route path="rating" element={<LazyRating />} />
            <Route path="term_condition" element={<LazyTermcondition />} />
            <Route path="disclaimer" element={<LazyDisclaimer />} />
            <Route
              path="neu_shop/item/:itemId"
              element={<LazyNeuShopItemDetails />}
            ></Route>
            <Route
              path="one_card_reading"
              element={<LazyOneCardReading />}
            ></Route>
            <Route
              path="one_card_result"
              element={<LazyOneCardResult />}
            ></Route>
            {/* <Route
              path="celebrity_list"
              element={<LazyCelebrityList />}
            ></Route> */}
            {/* <Route path="new_details" element={<LazyNewDetails />}></Route> */}
            {/* <Route path="/nmart" element={<LazyNmart />}></Route>
            <Route
              path="eclipses_blog_detail"
              element={<LazyEclipsesBlogDetail />}
            />
            <Route path="vedic_blog" element={<LazyVedicBlog />}></Route>
            <Route
              path="navagrahas_blog"
              element={<LazyNavagrahasBlog />}
            ></Route> */}
            <Route
              path="festival/:festivalId"
              element={<LazyFestivalDetail />}
            />
            {/* <Route path="vastu" element={<LazyVastu />} />
            <Route path="love" element={<LazyLove />} />
            <Route path="zodiac" element={<LazyZodiac />} />
            <Route path="neu_astro_mart" element={<LazyNeuAstroMartPage />} />
            <Route path="spritual" element={<LazySpritual />} /> */}
            <Route path="muhurat" element={<LazyMahurt />} />
            <Route
              path="payment/cancel_payment"
              element={<LazyCancelPayment />}
            />
            <Route
              path="payment/payment_status"
              element={<LazyPaymentStatus />}
            />
            <Route path="blog/:blogId" element={<LazyBlogDetail />} />
          </Route>
          <Route path="/404" element={<NotFound />} />
          <Route path="*" element={<Navigate to="/404" />}></Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;

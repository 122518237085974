import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { validateNumber } from "../../utils/validateMobileNumber";
import { toast } from "react-toastify";
import LoginService from "../../api/services/loginService";
import CountrySelect from "./CountrySelect";

const LoginForm = ({ inputValue, setIsSentOtp, otpId }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [mobileError, setMobileError] = useState(false);
  const { loginSignupUser } = LoginService();
  const [country, setCountry] = useState("IN");
  const countryCode = useRef();

  useEffect(() => {
    countryCode.current = "91";
  }, []);

  const handleOnChange = (event) => {
    if (mobileError) {
      setMobileError(false);
    }
    setPhoneNumber(event.target.value);
  };

  const formHandle = async (event) => {
    event.preventDefault();
    if (!validateNumber(phoneNumber, country)) {
      setMobileError(true);
      return;
    }

    inputValue.current.mobileNo = phoneNumber;
    inputValue.current.countryCode = countryCode.current;
    inputValue.current.countryFlag = country;

    try {
      const reqResponse = await loginSignupUser(inputValue.current);
      if (reqResponse) {
        setIsSentOtp(true);
        toast.success("Otp Sent Successfully");
        otpId.current = reqResponse._id;
      } else {
        toast.error("Something went wrong!");
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  return (
    <div>
      <p>Please enter your mobile number to Login/Sign Up on NeuAstro</p>

      <div className="col-12 p-sm-0 p-0">
        <form method="post" onSubmit={formHandle}>
          <div className="phone-input-container">
            <div className="phone-input-box ">
              <CountrySelect
                value={country}
                setCountry={setCountry}
                setPhoneNumber={setPhoneNumber}
                countryCode={countryCode}
              />
              <input
                type="number"
                maxLength="15"
                className="phone-input"
                value={phoneNumber}
                onChange={handleOnChange}
                placeholder="Enter Mobile no..."
              />
            </div>
          </div>
          {mobileError && (
            <p style={{ color: "red" }} className="pl-2">
              Please enter your valid mobile number!
            </p>
          )}
          <br />
          <div className="policy-text ng-star-inserted">
            By Signing up, You agree to Our{" "}
            <Link to={"/term_condition"}>Terms of Use</Link> and{" "}
            <Link to={"/privacy_policy"}>Privacy Policy</Link>
          </div>
          <br />

          <a>
            <button
              className="btn mx-auto hover-btn tw-shadow-lg tw-bg-theme-color tw-text-white tw-font-semibold"
              type="submit"
            >
              Generate Otp
            </button>
          </a>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;

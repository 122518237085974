import apiUrl from "../../pages/config";
import ApiRequestType from "../apiRequestType";
import ApiRequestor from "../apiRequestor";
import axios from "../axios";

const CustomerSupportService = () => {
  const requestor = new ApiRequestor(axios);

  const getCustomerSupportNumber = async () => {
    const url = `${apiUrl}/customer_support/contact_number`;
    const apiResponse = await requestor.makeRequest(ApiRequestType.GET, url);
    return apiResponse;
  };

  return {
    getCustomerSupportNumber,
  };
};

export default CustomerSupportService;

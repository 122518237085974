import React, { useEffect, useState } from "react";
import PujaCard from "./components/PujaCard";
import OfflinePujaService from "../../api/services/offlinePujaService";
import FeaturePanel from "../../components/FeaturePanel";
import Breadcrumb from "../../components/Breadcrumb";
import { scrollToTop } from "../../utils/scrollToTop";

const OfflinePuja = () => {
  const { getAllPujaList } = OfflinePujaService();
  const [pujaList, setPujaList] = useState([]);
  const routes = [{ text: "Offline Puja" }];

  useEffect(() => {
    scrollToTop();
    fetchAllPujaList();
  }, []);

  const fetchAllPujaList = async () => {
    try {
      const { result, data } = await getAllPujaList();
      if (!result) return;
      if (data) {
        setPujaList(data);
      }
    } catch (error) {}
  };

  return (
    <>
      <section className="section-b-space shop-section tw-p-0">
        <div className="container-fluid-lg">
          <Breadcrumb routes={routes} />
        </div>
      </section>
      <section className="blog-section">
        <div className="container-fluid-lg">
          <div className="tw-grid tw-place-content-center min-[480px]:tw-grid-cols-2 md:tw-grid-cols-3 2xl:tw-grid-cols-4 tw-gap-y-8 tw-gap-x-5 lg:tw-gap-x-7">
            {pujaList.map((pujaDetails) => (
              <PujaCard
                key={pujaDetails.pujaId}
                pujaDetails={pujaDetails}
              ></PujaCard>
            ))}
          </div>
        </div>
      </section>
      <br />
      <FeaturePanel />
      <br />
      <br />
    </>
  );
};

export default OfflinePuja;

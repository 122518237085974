import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OfflinePujaService from "../../../api/services/offlinePujaService";
import { getCurrencySymbol } from "../../../utils/getCurrencySymbol";
import { useVisible } from "../../../hooks/useVisible";

const OfflinePuja = () => {
  const navigate = useNavigate();
  const { getAllPujaList } = OfflinePujaService();
  const [pujaList, setPujaList] = useState([]);
  const offlinePujaRef = useRef();
  const { isVisible, observerRef } = useVisible(offlinePujaRef);

  const fetchAllPujaList = async () => {
    try {
      const { result, data } = await getAllPujaList();
      if (!result) return;
      if (data) {
        setPujaList(data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (!isVisible) return;
    fetchAllPujaList();
    observerRef.current.unobserve(offlinePujaRef.current);
  }, [isVisible]);

  const handleOnClick = (pujaId) => {
    navigate(`/offline_puja/${pujaId}`);
  };

  const settings = {
    autoplay: true,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <div className="tw-hidden"></div>,
    prevArrow: <div className="tw-hidden"></div>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 440,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <section className="blog-section section-b-space" ref={offlinePujaRef}>
        {pujaList.length > 0 && (
          <div className="container-fluid-lg">
            <div className="title title-flex">
              <div>
                <h2 className="main-heading">Puja Path @your place</h2>
                <h4 className="sub-main-heading">
                  Best Pandit Ji at your place
                </h4>
              </div>
              <div className="timing-box">
                <Link to="/offline_puja">
                  <img
                    src="../assets/images/veg-3/category/live_right_arrow.png"
                    className="img-fluid slide-arrow-img lazyload"
                    alt=""
                    loading="lazy"
                  />
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 order-lg-3 blog-slider">
                <Slider {...settings}>
                  {pujaList.map((pujaDetail) => {
                    return (
                      <div
                        key={pujaDetail.pujaId}
                        onClick={() => handleOnClick(pujaDetail.pujaId)}
                        className="tw-cursor-pointer"
                      >
                        <div className="blog-box wow fadeInUp tw-relative overflow-hidden">
                          <div className="blog-image tw-relative tw-shadow-card-shadow">
                            <img
                              src={pujaDetail.image}
                              className="bg-img blur-up lazyload img-fluid tw-w-full tw-h-[206px]"
                              alt="puja_pic"
                              loading="lazy"
                            />
                            <div className="tw-absolute tw-bottom-0 tw-text-xs tw-right-0 tw-bg-theme-color tw-text-white tw-py-[2px] tw-px-2 tw-rounded-tl-lg">
                              <span>
                                Starting from{" "}
                                {getCurrencySymbol(
                                  pujaDetail?.startingPrice?.currency
                                )}
                                {pujaDetail?.startingPrice?.amount}
                              </span>
                            </div>
                          </div>
                          <div className="tw-py-2.5 tw-px-2 tw-h-[85px] flex-column tw-gap-y-2">
                            <h3>{pujaDetail.title}</h3>
                            <p className="tw-text-[14px] tw-font-medium tw-text-para-color overflow-ellipsis">
                              {pujaDetail.description}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default OfflinePuja;

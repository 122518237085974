import React from "react";
import KundaliForm from "./components/KundaliForm";
import OnlineKundali from "./components/OnlineKundali";
// import AskQuestion from "../AskQuestion";
import useHashFragment from "../../hooks/useHashFragment";
import FeaturePanel from "../../components/FeaturePanel";

function Kundali() {
  useHashFragment(100);
  return (
    <>
      <KundaliForm />
      {/* <KTeam /> */}
      <OnlineKundali />
      {/* <AskQuestion /> */}
      <FeaturePanel />
      <br />
      <br />
    </>
  );
}

export default Kundali;

import apiUrl from "../../pages/config";
import ApiRequestType from "../apiRequestType";
import ApiRequestor from "../apiRequestor";
import axios from "../axios";

const BlogService = () => {
  const requestor = new ApiRequestor(axios);

  const getBlogsList = async () => {
    const url = `${apiUrl}/blogs`;
    const apiResponse = await requestor.makeRequest(ApiRequestType.GET, url);
    return apiResponse;
  };

  const getBlogDetails = async (blogId) => {
    const url = `${apiUrl}/blogs/${blogId}`;
    const apiResponse = await requestor.makeRequest(ApiRequestType.GET, url);
    return apiResponse;
  };

  return {
    getBlogDetails,
    getBlogsList,
  };
};

export default BlogService;

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import React, { useCallback, useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import PanchangService from "../../api/services/panchangService";
import {
  dayOptionsGenerator,
  monthOptionsGenerator,
  yearOptionsGenerator,
} from "../../utils/dobBirthTimeGenerator";
import {
  defaultPanchangAddress,
  defaultPanchangLat,
  defaultPanchangLng,
} from "../config";
import PanchangBasicDetails from "./components/PanchangBasicDetails";
import Choghadiya from "./components/Choghadiya";
import LagnaDetails from "./components/LagnaDetails";
import HoraDetail from "./components/HoraDetail";
import FeaturePanel from "../../components/FeaturePanel";
import Breadcrumb from "../../components/Breadcrumb";
import { convertToLocalDate } from "../../utils/utcToLocalTimeConvertor";
import LatLngTimezoneService from "../../api/services/latLngTimezoneService";
import BottomDetailSection from "./components/BottomDetailSection";
import { ToastContainer } from "react-toastify";
import Loader from "../../components/Loader";
import { scrollToTop } from "../../utils/scrollToTop";

function PanchangPage() {
  const [responseData, setResponseData] = useState(null);
  const [address, setAddress] = useState(defaultPanchangAddress);
  const [timezone, setTimezone] = useState(5.5);
  const latLngRef = useRef(null);
  const formDataUpdateRef = useRef({
    sunriseFlag: false,
    choghadiyaFlag: true,
    lagnaFlag: true,
    horaFlag: true,
  });
  const [formData, setFormData] = useState(() => {
    const currentDate = new Date();
    let data = {
      day: currentDate.getDate(),
      month: currentDate.getMonth() + 1,
      year: currentDate.getFullYear(),
    };
    return data;
  });
  const [error, setError] = useState("");
  const [isChogadiyaDropdownOpen, setIsChogadiyaDropdownOpen] = useState(false);
  const [isHoraDropdownOpen, setIsHoraDropdownOpen] = useState(false);
  const [isLagnaDropdownOpen, setIsLagnaDropdownOpen] = useState(false);
  const { getTimezone } = LatLngTimezoneService();
  const { getSunrise } = PanchangService();
  const routes = [{ text: "Panchang" }];
  const OptionsType = Object.freeze({
    DAY: "1",
    MONTH: "2",
    YEAR: "3",
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    scrollToTop();
    fetchTimezone();
  }, [latLngRef.current]);

  useEffect(() => {
    getSunriseData();
  }, []);

  useEffect(() => {
    formDataUpdateRef.current = {
      ...formDataUpdateRef.current,
      sunriseFlag: true,
    };
  }, [formData, latLngRef.current]);

  const fetchTimezone = useCallback(async () => {
    let timezoneString = "";
    const lat = latLngRef.current?.lat || defaultPanchangLat;
    const lng = latLngRef.current?.lng || defaultPanchangLng;
    const date = convertToLocalDate(
      formData.year,
      formData.month,
      formData.day
    );
    try {
      const timezoneResponse = await getTimezone(lat, lng, date);
      timezoneString = timezoneResponse.timezone.toString() || 5.5;
      setTimezone(timezoneString);
    } catch (error) {}
  }, [latLngRef.current]);

  const generateOptions = useCallback((type) => {
    switch (type) {
      case OptionsType.DAY:
        return dayOptionsGenerator();
      case OptionsType.MONTH:
        return monthOptionsGenerator();
      case OptionsType.YEAR:
        return yearOptionsGenerator(2050);
    }
  }, []);

  const getFormData = () => {
    return {
      day: formData.day,
      month: formData.month,
      year: formData.year,
      hour: 7,
      min: 25,
      lat: latLngRef.current?.lat || defaultPanchangLat,
      lon: latLngRef.current?.lng || defaultPanchangLng,
      tzone: timezone,
    };
  };

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    latLngRef.current = latLng;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const getSunriseData = async () => {
    setIsLoading(true);
    try {
      const reqData = getFormData();
      const response = await getSunrise(reqData);
      if (response.result) {
        setResponseData({ ...response.data, address: address });
        formDataUpdateRef.current.sunriseFlag = false;
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.day) {
      setError("Please Select a day.");
      return;
    }

    if (!formData.month) {
      setError("Please a Month .");
      return;
    }

    if (!formData.year) {
      setError("Please select a year.");
      return;
    }
    if (!address) {
      setError("Please select a address.");
      return;
    }

    if (formDataUpdateRef.current.sunriseFlag) {
      formDataUpdateRef.current = {
        ...formDataUpdateRef.current,
        choghadiyaFlag: true,
        lagnaFlag: true,
        horaFlag: true,
      };
      getSunriseData();
    }
    isChogadiyaDropdownOpen && setIsChogadiyaDropdownOpen(false);
    isHoraDropdownOpen && setIsHoraDropdownOpen(false);
    isLagnaDropdownOpen && setIsLagnaDropdownOpen(false);
  };

  return (
    <>
      <ToastContainer />
      {/* <!-- Breadcrumb Section Start --> */}
      <section className="section-b-space shop-section">
        <div className="container-fluid-lg">
          <Breadcrumb routes={routes} />
        </div>
      </section>
      {/* <!-- Breadcrumb Section End --> */}

      <section className="fresh-vegetable-section section-lg-space mb-3 py-0">
        <div className="container-fluid-lg">
          <div className="title" style={{ padding: "5px" }}>
            <div className="row">
              <div className="col-lg-3">
                <img
                  className="img2 img-fluid lazyload"
                  src="../assets/images/logo/panchangLogo.png"
                  alt="aries"
                  loading="lazy"
                  height="200px"
                />
              </div>
              <div className="col-lg-9">
                <h2 className="mt-3">Today Panchang</h2>

                <p>
                  A Panchang (Sanskrit: पञ्चाङ्गम्) is a Hindu calendar and
                  almanac, which is used in Astrology. It follows traditional
                  units of Hindu timekeeping, and presents important dates and
                  their calculations in a tabulated form.{" "}
                </p>
                <p>
                  The study of Panchang involves understanding Rasi phala, the
                  impact of the signs of the zodiac on the individual.
                  Astrologers consult the Panchang to understand present with
                  movement of planets & its effect on individual, set auspicious
                  date for occasions like engagement, weddings and other
                  activities.
                </p>
              </div>
            </div>

            <br />
            <br />
            <div className="right-sidebar-box">
              <font style={{ color: "#ff4f4f" }}>
                {error && <p style={{ color: "#ff4f4f" }}>{error}</p>}
              </font>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-xxl-12 col-lg-12 col-sm-12">
                    <div className="mb-md-4 mb-3 custom-form">
                      <div className="custom-input">
                        <div
                          className="form-container"
                          style={{
                            width: "100%",
                          }}
                        >
                          <select
                            style={{
                              height: "45px",
                            }}
                            name="day"
                            value={formData.day}
                            onChange={handleInputChange}
                          >
                            {generateOptions(OptionsType.DAY)}
                          </select>
                          <select
                            style={{
                              height: "45px",
                            }}
                            name="month"
                            value={formData.month}
                            onChange={handleInputChange}
                          >
                            {generateOptions(OptionsType.MONTH)}
                          </select>
                          <select
                            style={{
                              height: "45px",
                            }}
                            name="year"
                            value={formData.year}
                            onChange={handleInputChange}
                          >
                            {generateOptions(OptionsType.YEAR)}
                          </select>
                          <PlacesAutocomplete
                            value={address}
                            onChange={(newValue) => setAddress(newValue)}
                            onSelect={handleSelect}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div>
                                <input
                                  style={{
                                    width: "100%",
                                    height: "45px",
                                    border: "1px solid rgb(118, 118, 118)",
                                  }}
                                  className="form-control"
                                  {...getInputProps({
                                    placeholder:
                                      defaultPanchangAddress || "Location",
                                  })}
                                />
                                {(loading || suggestions?.length > 0) && (
                                  <div
                                    style={{
                                      width: "100%",
                                      padding: "4px",
                                      backgroundColor: "#f1f1f1",
                                    }}
                                  >
                                    {loading ? (
                                      <div>Loading...</div>
                                    ) : (
                                      suggestions.map((suggestion) => {
                                        const style = {
                                          backgroundColor: suggestion.active
                                            ? "#41b6e6"
                                            : "#fff",
                                        };
                                        return (
                                          <div
                                            key={suggestion.description}
                                            {...getSuggestionItemProps(
                                              suggestion,
                                              {
                                                style,
                                              }
                                            )}
                                            style={{
                                              padding: "3px 5px",
                                            }}
                                          >
                                            {suggestion.description}
                                          </div>
                                        );
                                      })
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </PlacesAutocomplete>
                          <button
                            type="submit"
                            className="btn tw-bg-theme-color btn-md fw-bold tw-rounded-lg tw-text-white tw-shadow-lg hover:tw-shadow-sm tw-h-[45px] min-[529px]:tw-ml-10 hover:tw-bg-opacity-80 flex-item-center tw-w-[120px]"
                          >
                            {isLoading ? <Loader /> : "Get Panchang"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Client Section Start --> */}
      <section className=" bg-white section-lg-space p-0 m-0">
        <div className="container-fluid-lg container-fluid py-0 my-0">
          {responseData && (
            <PanchangBasicDetails
              responseData={responseData}
              formData={formData}
            />
          )}
          <div className="line"></div>
          <div className="grid-3-tables mt-4">
            <Choghadiya
              getFormData={getFormData}
              isChogadiyaDropdownOpen={isChogadiyaDropdownOpen}
              setIsChogadiyaDropdownOpen={setIsChogadiyaDropdownOpen}
              updateFlag={formDataUpdateRef.current}
            />
            <LagnaDetails
              getFormData={getFormData}
              isLagnaDropdownOpen={isLagnaDropdownOpen}
              setIsLagnaDropdownOpen={setIsLagnaDropdownOpen}
              updateFlag={formDataUpdateRef.current}
            />
            <HoraDetail
              getFormData={getFormData}
              isHoraDropdownOpen={isHoraDropdownOpen}
              setIsHoraDropdownOpen={setIsHoraDropdownOpen}
              updateFlag={formDataUpdateRef.current}
            />
          </div>
        </div>
      </section>
      <br />
      <BottomDetailSection />
      <br />
      <FeaturePanel />
      <br />
    </>
  );
}

export default PanchangPage;

import React from "react";

const CustomerMessage = ({ message }) => {
  return (
    <div className="direct-chat-msg">
      <div className="direct-chat-text tw-float-right tw-w-max tw-max-w-[80%] tw-bg-theme-color">
        <p className="tw-m-0 tw-py-1">{message}</p>
      </div>
    </div>
  );
};

export default CustomerMessage;

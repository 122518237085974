export const generateRandomAstrologerGroupNumber = (totalGroupCount) => {
   return Math.floor(Math.random() * totalGroupCount) + 1;
}

export const generateAstrologerGroupSet = (totalGroupCount) => {
   const groupSet = new Set();
   for(let i = 1; i <= totalGroupCount; i++){
      groupSet.add(i);
   }
   return groupSet;
}
import apiUrl from "../../pages/config";
import ApiRequestType from "../apiRequestType";
import ApiRequestor from "../apiRequestor";
import axios from "../axios";

const PanchangService = () => {
  const requestor = new ApiRequestor(axios);

  const getHoraMuhurta = async (data) => {
    const url = `${apiUrl}/ext/astrology/hora_muhurta`;
    const apiResponse = await requestor.makeRequest(
      ApiRequestType.POST,
      url,
      data
    );
    return apiResponse;
  };

  const getChaughadiyaMuhurta = async (data) => {
    const url = `${apiUrl}/ext/astrology/chaughadiya_muhurta`;
    const apiResponse = await requestor.makeRequest(
      ApiRequestType.POST,
      url,
      data
    );
    return apiResponse;
  };

  const getLagnaDetail = async (data) => {
    const url = `${apiUrl}/ext/astrology/panchang_lagna_table`;
    const apiResponse = await requestor.makeRequest(
      ApiRequestType.POST,
      url,
      data
    );
    return apiResponse;
  };

  const getSunrise = async (data) => {
    const url = `${apiUrl}/ext/astrology/advanced_panchang/sunrise`;
    const apiResponse = await requestor.makeRequest(
      ApiRequestType.POST,
      url,
      data
    );
    return apiResponse;
  };

  return { getChaughadiyaMuhurta, getHoraMuhurta, getLagnaDetail, getSunrise };
};

export default PanchangService;

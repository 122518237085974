import { Link } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import ZodiacService from "../../../api/services/zodiacService";
import FestivalService from "../../../api/services/festivalService";
import { useVisible } from "../../../hooks/useVisible";

const Ywkukun = () => {
  const { getZodiacs } = ZodiacService();
  const { getFestivals } = FestivalService();
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const [zodiacsDetails, setZodiacsDetails] = useState([]);
  const [festivals, setFestivals] = useState([]);
  const ywkukunRef = useRef();
  const { isVisible, observerRef } = useVisible(ywkukunRef);

  const fetchZodiacDetails = async () => {
    try {
      const { result, data, msg } = await getZodiacs();
      if (result) {
        return data;
      }
    } catch (error) {}
    return [];
  };

  const _fetchFestivals = async () => {
    try {
      const { result, msg, data } = await getFestivals();
      if (result) {
        return data;
      }
    } catch (error) {}
    return [];
  };

  useEffect(() => {
    if (!isVisible) return;
    const response = fetchZodiacDetails();
    response.then((result) => {
      setZodiacsDetails(result);
    });
    _fetchFestivals().then((result) => {
      setFestivals(result);
    });
    observerRef.current.unobserve(ywkukunRef.current);
  }, [isVisible]);

  return (
    <div ref={ywkukunRef}>
      <section className="blog-section section-b-space">
        <div className="container-fluid-lg">
          <div className="row g-4">
            <div className="col-xxl-12 col-xl-12 col-lg-12 order-lg-2">
              <div className="horoscope-card-container ratio_65">
                <div>
                  <Link to="/horoscope_yearly">
                    <div
                      className="blog-box wow fadeInUp tw-visible"
                      style={{
                        animationName: "fadeInUp",
                      }}
                    >
                      <div className="blog-image">
                        <Link
                          to="/horoscope_yearly"
                          className="bg-size blur-up lazyloaded image-bg-prop"
                          style={{
                            backgroundImage:
                              'url("../assets/images/news/horoscope.webp")',
                          }}
                        ></Link>
                      </div>
                      <div className="blog-contain custom-scroll horoscope-card-details horoscope-card-box-model">
                        <div className="tw-mb-2.5">
                          <h4 className="horoscope-card-heading tw-text-black">
                            Yearly Horoscope
                          </h4>
                        </div>
                        <div>
                          <ul>
                            {zodiacsDetails?.map((details, index) => {
                              return (
                                <>
                                  <li>
                                    <Link
                                      to={"/horoscope_yearly"}
                                      className="horoscope-card-para"
                                      state={{
                                        initialSelectedZodiacDetails: details,
                                        initialSlideNumber: index,
                                      }}
                                    >
                                      {details.name} Horoscope {year}
                                    </Link>
                                  </li>
                                  <hr />
                                </>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div>
                  <Link to="/horoscope_monthly">
                    <div
                      className="blog-box wow fadeInUp tw-visible"
                      data-wow-delay="0.05s"
                      style={{
                        animationDelay: "0.05s",
                        animationName: "fadeInUp",
                      }}
                    >
                      <div className="blog-image">
                        <Link
                          to="/horoscope_monthly"
                          className="bg-size blur-up lazyloaded image-bg-prop"
                          style={{
                            backgroundImage:
                              'url("../assets/images/news/monthlyHoroscope.webp")',
                          }}
                        ></Link>
                      </div>
                      <div className="blog-contain horoscope-card-box-model">
                        <div className="tw-mb-2.5">
                          <h4 className="horoscope-card-heading tw-text-black">
                            Monthly Horoscope
                          </h4>
                        </div>
                        <p className="horoscope-card-para">
                          Are you seeking what your fortune will be like this
                          month? Look no further than NeuAstro&apos;s insightful
                          monthly horoscope predictions. NeuAstro&apos;s monthly
                          horoscope is a predictive guide for each zodiac sign
                          that can help one discover what the month holds...
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
                <div>
                  <Link to="/one_card">
                    <div
                      className="blog-box wow fadeInUp tw-visible"
                      data-wow-delay="0.25s"
                      style={{
                        animationDelay: "0.25s",
                        animationName: "fadeInUp",
                      }}
                    >
                      <div className="blog-image">
                        <Link
                          to="/one_card"
                          className="bg-size blur-up lazyloaded image-bg-prop"
                          style={{
                            backgroundImage:
                              'url("../assets/images/inner-page/home_tarot2.webp")',
                          }}
                        ></Link>
                      </div>
                      <div className="blog-contain horoscope-card-box-model">
                        <div className="tw-mb-2.5">
                          <h4 className="horoscope-card-heading tw-text-black">
                            Tarot
                          </h4>
                        </div>
                        <p className="horoscope-card-para">
                          Do you need quick fixes or answers to your life&apos;s
                          problems? A single card tarot prediction can provide
                          you with insight into the future and assist you in
                          making better decisions about what needs to be done.
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
                <div>
                  <div>
                    <div
                      className="blog-box wow fadeInUp tw-visible"
                      data-wow-delay="0.45s"
                      style={{
                        animationDelay: "0.45s",
                        animationName: "fadeInUp",
                      }}
                    >
                      <div className="blog-image">
                        <a
                          href="javascript:void(0)"
                          className="bg-size blur-up lazyloaded image-bg-prop"
                          style={{
                            backgroundImage:
                              'url("../assets/images/inner-page/festival.webp")',
                          }}
                        ></a>
                      </div>
                      <div className="blog-contain custom-scroll horoscope-card-details horoscope-card-box-model">
                        <div className="tw-mb-2.5">
                          <h4 className="horoscope-card-heading tw-text-black">
                            Festival
                          </h4>
                        </div>
                        <div>
                          <a>
                            <ul>
                              {festivals.map((festivalDeatail) => {
                                return (
                                  <>
                                    <li>
                                      <Link
                                        to={`/festival/${festivalDeatail.festivalId}`}
                                        className="horoscope-card-para"
                                      >
                                        {festivalDeatail.title} {year}
                                      </Link>
                                    </li>
                                    <hr />
                                  </>
                                );
                              })}
                            </ul>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Ywkukun;

import React from "react";
import countriesCodeAndName from "../../constants/mobileNoCountryCodeDetails";

const CountrySelect = ({ value, setCountry, setPhoneNumber, countryCode }) => {
  const url = `/assets/images/flags/${value}.svg`;

  const handleOnChange = (event) => {
    const countryDetails = countriesCodeAndName.find(
      (item) => item.isoCode2 === event.target.value
    );
    countryCode.current = countryDetails.countryCodes[0];
    setCountry(event.target.value || undefined);
    setPhoneNumber("");
  };

  return (
    <div className="select-box">
      <img src={url} alt="flag" loading="lazy" />
      <select className="flag-box" value={value} onChange={handleOnChange}>
        <option value="">Search with country code</option>
        {countriesCodeAndName.map((countryData) => {
          return (
            <option
              key={countryData.country}
              value={countryData.isoCode2}
              className="options"
            >
              {`+${countryData.countryCodes[0]} ${countryData.country}`}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default CountrySelect;

const apiUrl = process.env.REACT_APP_BACKEND_APP_URL;
const agoraAppId = process.env.REACT_APP_AGORA_APP_ID;
const defaultPanchangAddress =
  process.env.REACT_APP_PANCHANG_DEFAULT_ADDRESS_VALUE;
const defaultPanchangLat =
  process.env.REACT_APP_PANCHANG_DEFAULT_LATITUDE_VALUE;
const defaultPanchangLng =
  process.env.REACT_APP_PANCHANG_DEFAULT_LONGITUDE_VALUE;

const googleGeoCodingApiUrl =
  process.env.REACT_APP_GOOGLE_REVERSE_GEOCODING_API_URL;

const googleGeoCodingApiKey =
  process.env.REACT_APP_GOOGLE_REVERSE_GEOCODING_API_KEY;

const googleTagManagerKey =
  process.env.REACT_APP_GOOGLE_TAG_MANAGER_KEY || null;

const viewportRootMargin = process.env.REACT_APP_VIEWPORT_ROOT_MARGIN || "50px";

const minimumRechargeAmount =
  process.env.REACT_APP_MINIMUM_RECHARGE_AMOUNT || 50;

export default apiUrl;
export {
  agoraAppId,
  defaultPanchangAddress,
  defaultPanchangLat,
  defaultPanchangLng,
  googleGeoCodingApiUrl,
  googleGeoCodingApiKey,
  googleTagManagerKey,
  viewportRootMargin,
  minimumRechargeAmount,
};

import React from "react";
import { Link } from "react-router-dom";
import { scrollToTop } from "../../utils/scrollToTop";

const Navbar = () => {
  return (
    <div className="navbar-container lg:tw-inline-block main-nav navbar navbar-expand-xl navbar-light navbar-sticky">
      <ul className="lg:tw-inline-block [&>li>a]:tw-text-black navbar-light">
        <li className="nav-item">
          <Link className="nav-link navbar-link" to="/" onClick={scrollToTop}>
            Home
          </Link>
        </li>

        <li className="nav-item">
          <Link
            className="nav-link navbar-link"
            to="/astrologers_list"
            onClick={scrollToTop}
          >
            Hello Astrologer
          </Link>
        </li>

        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle navbar-link"
            href="javascript:void(0)"
            data-bs-toggle="dropdown"
          >
            Horoscope
          </a>
          <ul className="dropdown-menu">
            <li>
              <Link
                className="dropdown-item tw-text-black"
                to="/horoscope_daily"
                onClick={scrollToTop}
              >
                Daily Horoscope
              </Link>
            </li>
            <li>
              <Link
                className="dropdown-item tw-text-black"
                to="/horoscope_page"
                onClick={scrollToTop}
              >
                Weekly Horoscope
              </Link>
            </li>

            <li>
              <Link
                className="dropdown-item tw-text-black"
                to="/horoscope_monthly"
                onClick={scrollToTop}
              >
                Monthly Horoscope
              </Link>
            </li>

            <li>
              <Link
                className="dropdown-item tw-text-black"
                to="/horoscope_yearly"
                onClick={scrollToTop}
              >
                Yearly Horoscope
              </Link>
            </li>
          </ul>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link navbar-link"
            to="/panchang_page"
            onClick={scrollToTop}
          >
            Panchang
          </Link>
        </li>
        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle navbar-link"
            href="javascript:void(0)"
            data-bs-toggle="dropdown"
          >
            Kundli
          </a>
          <ul className="dropdown-menu">
            <li>
              <Link
                className="dropdown-item tw-text-black"
                to="/kundali#kundali-form"
              >
                Kundli
              </Link>
            </li>
            <li>
              <Link
                className="dropdown-item tw-text-black"
                to="/kundali_milan#kundali-milan-form"
              >
                Kundli Milan
              </Link>
            </li>
          </ul>
        </li>
        {/* <li className="nav-item dropdown">
          <a
            style={{ color: "black" }}
            className="nav-link dropdown-toggle"
            href="javascript:void(0)"
            data-bs-toggle="dropdown"
          >
            Puja Path
          </a>
          <ul className="dropdown-menu">
            <li>
              <Link
                style={{ color: "black" }}
                className="dropdown-item"
                to="/online_puja"
              >
                Online-Puja
              </Link>
            </li>
            <li>
              <Link
                style={{ color: "black" }}
                className="dropdown-item"
                to="/offline_puja"
              >
                Offline-Puja
              </Link>
            </li>
          </ul>
        </li> */}

        <li className="nav-item">
          <Link
            className="nav-link navbar-link"
            to="/offline_puja"
            onClick={scrollToTop}
          >
            Puja Path
          </Link>
        </li>

        <li className="nav-item">
          <Link
            className="nav-link navbar-link"
            to="/one_card"
            onClick={scrollToTop}
          >
            Tarot
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link navbar-link"
            to="/numerology"
            onClick={scrollToTop}
          >
            Numerology
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/neu_shop" className="nav-link navbar-link">
            NeuShop
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="nav-link navbar-link"
            to="/blog"
            onClick={scrollToTop}
          >
            Blog
          </Link>
        </li>
        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle tw-text-black navbar-link"
            href="javascript:void(0)"
            data-bs-toggle="dropdown"
          >
            Others
          </a>
          <ul className="dropdown-menu">
            <li>
              <Link
                className="dropdown-item tw-text-black"
                to="/muhurat"
                onClick={scrollToTop}
              >
                Muhurat
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;

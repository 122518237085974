import useAxiosPrivateHook from "../../hooks/useAxiosPrivate";
import apiUrl from "../../pages/config";
import ApiRequestType from "../apiRequestType";
import ApiRequestor from "../apiRequestor";
import axios from "../axios";

const OfflinePujaService = () => {
  const axiosPrivate = useAxiosPrivateHook();
  const authenticatedRequestor = new ApiRequestor(axiosPrivate);
  const requestor = new ApiRequestor(axios);

  const getAllPujaList = async () => {
    const url = `${apiUrl}/offline_puja`;
    const apiResponse = await requestor.makeRequest(ApiRequestType.GET, url);
    return apiResponse;
  };

  const getPujaDetails = async (pujaId) => {
    const url = `${apiUrl}/offline_puja/${pujaId}`;
    const apiResponse = await requestor.makeRequest(ApiRequestType.GET, url);
    return apiResponse;
  };

  const bookOfflinePuja = async (reqData, pujaId) => {
    const url = `${apiUrl}/offline_puja/booking/${pujaId}`;
    const apiResponse = await authenticatedRequestor.makeRequest(
      ApiRequestType.POST,
      url,
      reqData
    );
    return apiResponse;
  };

  const proceedOfflinePujaPayment = async (reqData) => {
    const url = `${apiUrl}/offline_puja/booking/payment`;
    const apiResponse = await authenticatedRequestor.makeRequest(
      ApiRequestType.POST,
      url,
      reqData
    );
    return apiResponse;
  };

  const payAmount = async (transactionId) => {
    const url = `${apiUrl}/offline_puja/booking/payment/${transactionId}/initialize`;
    const apiResponse = await authenticatedRequestor.makeRequest(
      ApiRequestType.POST,
      url
    );
    return apiResponse;
  };

  const retrieveBookedPujaList = async () => {
    const url = `${apiUrl}/offline_puja/booking/customer`;
    const apiResponse = await authenticatedRequestor.makeRequest(
      ApiRequestType.GET,
      url
    );
    return apiResponse;
  };

  const getPujaInvoicePdf = async (pujaId) => {
    const url = `${apiUrl}/invoice/offline_puja/payment?bookingId=${pujaId}`;
    const apiResponse = await authenticatedRequestor.makeRequest(
      ApiRequestType.GET_FILE,
      url
    );
    return apiResponse;
  };

  return {
    getAllPujaList,
    getPujaDetails,
    bookOfflinePuja,
    proceedOfflinePujaPayment,
    payAmount,
    retrieveBookedPujaList,
    getPujaInvoicePdf,
  };
};

export default OfflinePujaService;

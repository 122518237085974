import React from "react";

const KundaliMilanBanner = () => {
  return (
    <>
      <section className="home-section">
        <div
          className="container-fluid-lg"
          style={{
            background: "linear-gradient(to bottom, #00cc00 0%, #ff99cc 100%)",
          }}
        >
          <img
            align="right"
            src="../assets/images/logo/kundliBanner.webp"
            width="100%"
            alt="kundli-milan"
            loading="lazy"
          />
        </div>
      </section>
      <section>
        <div className="container-fluid-lg">
          <div className="title" style={{ paddingTop: "20px" }}>
            <h2 style={{ textAlign: "center" }}>
              Free Kundli Milan &amp; Gun Milan
            </h2>

            <p>
              {" "}
              The success of a marriage depends on your own values, tolerance,
              and adjustment skills, such traits in a person are easily known by
              reading his or her horoscope. And they can be matched with another
              horoscope through Kundli Milan.
            </p>

            <p>
              Kundli Milan helps in understanding the issues a couple might face
              in their marital life. Kundli Milan work as a guiding force,
              giving you a overview of areas which are strong as a couple and
              where would you both need to work upon.
            </p>

            <p>
              Vedic astrology enthusiasts are usually familiar with the concept
              of Guna Milaan. In fact, Guna Milaan and Kundli matching for
              marriage are often seen as the same. This is not the case. Guna
              Milaan is just one part of it. While matching Janam Kundli of both
              bride and groom, several factors are assessed. The position of
              natal Moon is assessed in both the partner’s chart.{" "}
            </p>

            <p>
              For example, An expert astrologer would also assess both the chart
              for presence of Mangal Dosha. If it exists in both the chart, the
              effect nullifies but if it exists in one of the charts, some
              serious remedies are suggested to mitigate the harmful effects
              based on the severity of the Dosha. If your Moon occupies the 6th
              house and your partner’s Moon is placed 8th then it is considered
              inauspicious for a marriage. The strength of 7th house of partner
              and 7th lord is also taken into account when conducting horoscope
              matching.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default KundaliMilanBanner;

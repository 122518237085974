import React from "react";
import { Link } from "react-router-dom";
import { getCurrencySymbol } from "../../../utils/getCurrencySymbol";

const PujaCard = ({ pujaDetails }) => {
  const { pujaId, title, image, description, startingPrice } = pujaDetails;

  return (
    <Link to={`/offline_puja/${pujaId}`} className="tw-h-max tw-inline-block">
      <div className="tw-max-w-[400px] tw-cursor-pointer tw-rounded-lg hover:tw-shadow-lg tw-ease-out tw-duration-300 tw-overflow-hidden tw-relative tw-shadow-box-light">
        <div className="tw-h-[180px] tw-relative lg:tw-h-[200px] tw-shadow-sm tw-rounded-t-lg">
          <img
            src={image}
            alt="puja_pic"
            loading="lazy"
            className="tw-w-full tw-h-full lazyload"
          />
          <div className="tw-absolute tw-bottom-0 tw-font-normal tw-text-sm tw-right-0 tw-bg-theme-color tw-text-white tw-py-[2px] tw-px-2 tw-rounded-tl-lg tw-shadow">
            <span>
              Starting from {getCurrencySymbol(startingPrice?.currency)}
              {startingPrice?.amount}
            </span>
          </div>
        </div>
        <div className="tw-py-2 lg:tw-py-2.5 tw-px-2.5 tw-h-[100px] flex-column tw-gap-y-[2px]">
          <h3 className="tw-font-semibold tw-text-black sub-main-heading overflow-ellipsis">
            {title}
          </h3>
          <p className="tw-text-[14px] tw-font-medium tw-text-para-color overflow-ellipsis line-clamp-2 ">
            {description}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default PujaCard;

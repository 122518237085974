import React, { useState } from "react";
import { changeTimeFormat } from "../../../utils/formatDate";
import PanchangService from "../../../api/services/panchangService";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";

const LagnaDetails = ({
  getFormData,
  isLagnaDropdownOpen,
  setIsLagnaDropdownOpen,
  updateFlag,
}) => {
  const [lagnaDetails, setLagnaDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { getLagnaDetail } = PanchangService();

  const fetchLagnaDetails = async () => {
    setIsLoading(true);
    try {
      const reqData = getFormData();
      const { result, data, msg } = await getLagnaDetail(reqData);
      if (!result) {
        toast.error(msg || "Facing some issue while fetching details");
        return;
      }
      setLagnaDetails(data);
      updateFlag.lagnaFlag = false;
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnClick = () => {
    updateFlag.lagnaFlag && fetchLagnaDetails();
    setIsLagnaDropdownOpen(!isLagnaDropdownOpen);
  };

  return (
    <div className="mt-3 seller-grid-box tw-h-fit">
      <div
        className="tw-bg-theme-color tw-w-full tw-text-base tw-text-white tw-p-2 text-center hover:tw-bg-opacity-80 tw-transition-all hover:tw-text-gray-200 tw-cursor-pointer flex-item-between tw-shadow"
        onClick={handleOnClick}
      >
        <h3 className="tw-text-base">Lagna Muhurat</h3>
        {isLoading ? (
          <Loader />
        ) : (
          <span
            className={`common-carot ${
              isLagnaDropdownOpen ? "tw-rotate-45" : "-tw-rotate-45"
            } tw-p-[3px]`}
          ></span>
        )}
      </div>
      {isLagnaDropdownOpen && (
        <table className="table-container w-100 table-bordered">
          {lagnaDetails?.map((item, index) => (
            <tr key={index}>
              <td>
                <b>{item?.lagna}</b>
              </td>
              <td>
                {`${changeTimeFormat(
                  item?.start_time,
                  " "
                )} to ${changeTimeFormat(item?.end_time, " ")}`}
              </td>
            </tr>
          ))}
        </table>
      )}
    </div>
  );
};

export default LagnaDetails;

import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import HomeBannerSecond from "../HomeBannerSecond";
import ZodiacHoroscopeDetails from "../../components/ZodiacHoroscopeDetails";
import ZODIAC_PERIOD from "../../constants/zodiacPeriods";
import ZodiacSlider from "../../components/ZodiacSlider";
import HoroscopeLinksComponent from "./component/HoroscopeLinksComponent";
import FeaturePanel from "../../components/FeaturePanel";
import { scrollToTop } from "../../utils/scrollToTop";
import AllHoroscopeBottomList from "./component/AllHoroscopeBottomList";

function HoroscopeYearly() {
  const location = useLocation();
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const initialSlideNumber = location.state?.initialSlideNumber;

  const [zodiacDetail, setZodiacDetail] = useState(() => {
    return location.state?.initialSelectedZodiacDetails;
  });

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleZodiacClick = (detail) => {
    setZodiacDetail(detail);
  };

  return (
    <>
      <section className="home-section tw-mt-6 md:tw-mt-10">
        <div
          className="container-fluid-lg"
          style={{
            background: "lineargradient(to bottom, #00cc00 0%, #ff99cc 100%)",
          }}
        >
          <img
            align="right"
            src="../assets/images/logo/yearly-horoscope.webp"
            width="100%"
            alt="yearly-horoscope"
            loading="lazy"
          />
        </div>
      </section>
      <section>
        <div className="container-fluid-lg">
          <div className="title" style={{ padding: 20 }}>
            <h2 style={{ textAlign: "center" }}>Yearly horoscope {year}</h2>

            <p>
              We are excited about several things as the year {year} finally
              arrives. This year, we have the chance to do things right and get
              back on track. Your Horoscope {year}, compiled by the top
              astrologers in India, will help you along the way. You may use it
              to establish new goals or reflect on previous ones.
            </p>
            <p>
              Regarding life, we all have certain objectives we hope to
              accomplish. These objectives could be as straightforward as
              falling in love or launching your own company in {year}. As much
              as we would like to reach these objectives for ourselves, we are
              constrained by both internal and external factors. It&apos;s
              obvious that things in life don&apos;t always go as planned. Even
              while it&apos;s a positive thing, there are moments when it can be
              annoying. In these situations, we should consider what we are
              doing incorrectly or, more simply put, what is going wrong with
              us. And here&apos;s your yearly horoscope for {year} to help you
              with it.
            </p>
            <p>
              The purpose of the horoscopes is to guide you and set you on the
              correct course in life. In a similar vein, the goal of the {year}{" "}
              horoscope is to share with you all of the opportunities that lie
              ahead and guide you in the proper path. Astrologers claim that
              relationships and our aspirations in life are not always impacted
              by the actions we take. There are occasions when the energies of
              the planets, zodiac signs, etc. influence our decision-making. You
              will undoubtedly make poor selections in life if you are ignorant
              about those choices. There are moments when we put everything into
              trying to accomplish a certain goal but ultimately fall short. It
              can be the consequence of your failure to attempt or the impact of
              planets or zodiac signs on you.{" "}
            </p>
          </div>
        </div>
      </section>
      <section className="category-section-2">
        <div className="container-fluid-lg">
          <HoroscopeLinksComponent selectedComponent="yearly" />
          <ZodiacSlider
            zodiacSelectHandler={handleZodiacClick}
            setZodiacOnLoad={zodiacDetail == null}
            initialSlideNumber={initialSlideNumber}
          />
        </div>
      </section>
      <ZodiacHoroscopeDetails
        zodiacDetail={zodiacDetail}
        period={ZODIAC_PERIOD.YEARLY}
      />
      <HomeBannerSecond />
      <section>
        <div className="container-fluid-lg">
          <div className="title" style={{ padding: " 5px" }}>
            <h2>Benefits of Yearly Horoscope</h2>
            <span className="title-leaf">
              <svg className="icon-width">
                <Link to="https://themes.pixelstrap.com/fastkart/assets/svg/leaf.svg#leaf"></Link>
              </svg>
            </span>
            <p>
              A horoscope is a forecast of a person&apos;s future based on the
              positions of celestial bodies (such as planets and stars) at a
              specific time, usually the time of their birth. It is commonly
              associated with astrology and is used to provide insights into
              various aspects of life, including personality traits,
              relationships, and potential future events. Horoscopes are often
              divided into twelve astrological signs, each representing
              different periods of the year. People often read horoscopes to
              gain a better understanding of themselves or to get predictions
              about their future.
            </p>
            <p>
              Yearly horoscopes provide a broad overview of the astrological
              influences and trends for an entire year. Here&apos;s how they can
              be perceived to help:
            </p>
            <p>
              <ul className="shifted-list">
                <li>
                  <b>Long-Term Planning:</b> Yearly horoscopes offer a glimpse
                  into overarching themes and major trends. This can be useful
                  for making strategic plans and setting goals for the year
                  ahead.
                </li>
                <li>
                  <b>Life Transitions:</b> For those experiencing major life
                  transitions or milestones, a yearly horoscope may provide
                  insights into the potential challenges and opportunities
                  associated with those changes.
                </li>
                <li>
                  <b>Personal Reflection:</b> Yearly horoscopes often encourage
                  introspection and reflection on personal goals, relationships,
                  and life choices over a more extended period. This reflection
                  can guide individuals in aligning their actions with their
                  aspirations.
                </li>
                <li>
                  <b>Timing of Major Decisions:</b> Understanding astrological
                  influences for the year can help in timing major decisions or
                  events, considering auspicious periods and potential
                  challenges.
                </li>
                <li>
                  <b>Career Guidance:</b> Yearly horoscopes may touch upon
                  career prospects and opportunities. This information can be
                  beneficial for those seeking guidance on professional
                  development and work-related decisions.
                </li>
              </ul>
            </p>
          </div>
        </div>
      </section>
      <AllHoroscopeBottomList />
      <FeaturePanel />
      <br />
    </>
  );
}

export default HoroscopeYearly;

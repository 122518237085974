import React from "react";
import {
  convertSecondsInMinSec,
  dateInDDMMYYYY,
} from "../../../utils/formatDate";
import { formatTimeInMinSec } from "../../../utils/formatTimeDateDigit";
import { compareTwoValues } from "../../../constants/compareTwoValues";

const InitialInputMessage = ({
  name,
  gender,
  dob,
  tob,
  pob,
  message,
  astroName,
  elapsedTime,
  chatEndTime,
}) => {
  return (
    <>
      <div className="flex-column tw-w-full tw-justify-center tw-items-center tw-gap-y-2.5 tw-mb-4">
        <div className="tw-bg-white tw-max-w-[80%] tw-rounded tw-shadow-card-shadow">
          <p className="tw-m-0 tw-p-1 tw-w-full tw-text-[10px] sm:tw-text-xs">
            {elapsedTime > 0 ? (
              <>
                <span className="tw-font-semibold">{astroName}</span> has
                accepted chat request.
              </>
            ) : (
              <>
                Wait for <span className="tw-font-semibold">{astroName}</span>{" "}
                to accept chat request.
              </>
            )}
          </p>
        </div>
        <div
          className={`tw-bg-white tw-max-w-[80%] tw-rounded tw-shadow-card-shadow ${
            elapsedTime > 0 || compareTwoValues(chatEndTime, null)
              ? "tw-hidden"
              : ""
          }`}
        >
          <p className="tw-m-0 tw-p-1 tw-w-full tw-text-[10px] sm:tw-text-xs">
            This chat will end in{" "}
            <span className="tw-text-theme-color tw-font-bold">
              {convertSecondsInMinSec(chatEndTime)}
            </span>{" "}
            if <span className="tw-font-medium">{astroName}</span> do not accept
            the chat request.
          </p>
        </div>
      </div>
      <div className="direct-chat-msg">
        <div className="direct-chat-text tw-float-right tw-bg-theme-color">
          <p className="tw-m-0 tw-py-1">
            {`Name: ${name}`}
            <br />
            {`Gender: ${gender}`}
            <br />
            {`DOB: ${dateInDDMMYYYY(dob)}`}
            <br />
            {`Time of Birth: ${formatTimeInMinSec(tob)}`}
            <br />
            {`Place Of Birth: ${pob}`}
            <br />
            {`Message: ${message}`}
          </p>
        </div>
      </div>
    </>
  );
};

export default InitialInputMessage;

import React from "react";
import { useNavigate } from "react-router-dom";

const HomeBanner = () => {
  const Navigate = useNavigate();

  let userlike = () => {
    Navigate("/astrologers_list");
  };

  return (
    <>
      {/* <!-- Home Section Start --> */}
      <section className="home-section">
        <div className="container-fluid-lg">
          <div className="row">
            <div className="col-xl-12 ratio_30">
              <div className="full-wh home-contain tw-relative">
                <div className="tw-h-full">
                  <img
                    src="/assets/images/inner-page/mainPoster-2.webp"
                    className="bg-img blur-up lazyload img-fluid banner-img"
                    alt="hero_page_banner"
                  />
                </div>
                <div className="banner-content">
                  <div>
                    <div className="banner-header">
                      <span>Problems in life ?</span>
                    </div>
                    <h1 className="banner-header tw-m-0">
                      Best Astrologer Available for you
                    </h1>
                    <br />
                    <h2 className="banner-header tw-m-0">
                      First Chat FREE
                      <span className="daily">
                        <button
                          onClick={userlike}
                          className="banner-chat-btn chat-btn"
                        >
                          Chat Now
                        </button>
                      </span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeBanner;

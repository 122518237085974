import apiUrl from "../../pages/config";
import ApiRequestType from "../apiRequestType";
import ApiRequestor from "../apiRequestor";
import axios from "../axios";

const HoroscopeService = () => {
  const requestor = new ApiRequestor(axios);

  const getZodiac = async (zodiacPeriod, zodiacId) => {
    const url = `${apiUrl}/horoscope/${zodiacPeriod}/zodiac/${zodiacId}`;
    const apiResponse = await requestor.makeRequest(ApiRequestType.GET, url);
    return apiResponse;
  };

  const getMonthlyZodiac = async (zodiacId, month) => {
    const url = `${apiUrl}/horoscope/monthly/zodiac/${zodiacId}?month=${month}`;
    const apiResponse = await requestor.makeRequest(ApiRequestType.GET, url);
    return apiResponse;
  };

  return { getZodiac, getMonthlyZodiac };
};

export default HoroscopeService;

import React from "react";
import { PiPhoneCallFill } from "react-icons/pi";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="tw-text-center flex-item-center flex-column tw-p-0 tw-bg-[#f0f0f0] tw-text-[#333] tw-h-[90vh]">
      <h1 className="tw-mb-1.5 md:tw-mb-4 tw-text-3xl sm:tw-text-4xl md:tw-text-6xl tw-text-dark-blue tw-font-bold">
        404 - Page Not Found
      </h1>
      <p className="tw-mb-8 tw-text-lg sm:tw-text-xl md:tw-text-2xl">
        Oops! The page you&apos;re looking for doesn&apos;t exist.
      </p>
      <div className="tw-flex tw-gap-x-6 max-[580px]:tw-flex-col tw-gap-y-3">
        <Link
          to="/"
          className="tw-rounded-md flex-item-center tw-py-1.5 md:tw-py-2 tw-text-base sm:tw-text-lg md:tw-text-xl tw-px-4 tw-bg-theme-color tw-text-dark-blue tw-transition-all tw-duration-300 tw-ease-in-out hover:tw-bg-light-yellow hover:tw-shadow-neu-theme-inner"
        >
          Go to Home
        </Link>
        <Link
          to="/astrologers_list"
          className="tw-text-base flex-item-center tw-gap-x-1 sm:tw-text-lg md:tw-text-xl tw-py-1.5 md:tw-py-2 tw-px-4 tw-rounded-md tw-bg-dark-blue tw-text-white hover:tw-bg-opacity-60 tw-transition-all tw-duration-300 tw-ease-in-out hover:tw-shadow-neu-blue-inner"
        >
          {" "}
          <PiPhoneCallFill
            className={`astrologer-available-icon tw-bg-light-green`}
          />{" "}
          <span>Talk to Astrologers</span>
        </Link>
      </div>
    </div>
  );
};

export default NotFound;

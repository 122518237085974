import apiUrl from "../../pages/config";
import { ApplicationType } from "../../pages/constants";
import ApiRequestType from "../apiRequestType";
import ApiRequestor from "../apiRequestor";
import axios, { axiosPrivate } from "../axios";

const LoginService = () => {
  const requestor = new ApiRequestor(axios);
  const privateRequestor = new ApiRequestor(axiosPrivate);
  const apiSign = `${apiUrl}/user/login_sign_up`;

  const verifyOtpNumber = async (urlKey, data) => {
    const url = `${apiSign}/${urlKey}?requestingApplicationType=${ApplicationType.WEB}`;
    const apiResponse = await privateRequestor.makeRequest(
      ApiRequestType.POST,
      url,
      data
    );
    return apiResponse;
  };

  const resendOtpNumber = async (urlKey, data) => {
    const url = `${apiSign}/${urlKey}`;
    const apiResponse = await requestor.makeRequest(
      ApiRequestType.PATCH,
      url,
      data
    );
    if (apiResponse.result) return apiResponse;
    return null;
  };

  const loginSignupUser = async (data) => {
    const apiResponse = await requestor.makeRequest(
      ApiRequestType.POST,
      apiSign,
      data
    );
    if (apiResponse.result) return apiResponse.data;
    return null;
  };

  return {
    verifyOtpNumber,
    resendOtpNumber,
    loginSignupUser,
  };
};

export default LoginService;

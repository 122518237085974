import { lazy } from "react";

export const LazyAstrolive = lazy(() => {
  return import("./pages/Astrolive");
});

export const LazyAstrologerDetail = lazy(() => {
  return import("./pages/astrologerDetails/AstrologerDetailPage");
});

export const LazyLoginUserDetialsPage = lazy(() => {
  return import("./pages/loginSignUp/LoginUserDetailsPage");
});

export const LazyProfile = lazy(() => {
  return import("./pages/profile/Profile");
});

export const LazyPujaListDetail = lazy(() => {
  return import("./pages/PujaListDetail");
});

export const LazyAudioCall = lazy(() => {
  return import("./pages/audioVideoScreen/AudioCall");
});

export const LazyVideoCall = lazy(() => {
  return import("./pages/audioVideoScreen/VideoCall");
});

export const LazyConversationInitialInput = lazy(() => {
  return import("./pages/conversation/ConversationInitialInputScreen");
});

export const LazyPayment = lazy(() => {
  return import("./pages/payment/Payment");
});

export const LazyStreaming = lazy(() => {
  return import("./pages/liveStreamingScreen/Streaming");
});

export const LazyPrivacyPolicy = lazy(() => {
  return import("./pages/PrivacyPolicy");
});

export const LazyAboutus = lazy(() => {
  return import("./pages/Aboutus");
});

export const LazyKundalireport = lazy(() => {
  return import("./pages/kundliMilan/Kundalireport");
});

export const LazySelfKundli = lazy(() => {
  return import("./pages/kundli/SelfKundli");
});

export const LazyRuling = lazy(() => {
  return import("./pages/Ruling");
});

export const LazyRating = lazy(() => {
  return import("./pages/ReviewRating");
});

export const LazyReviews = lazy(() => {
  return import("./pages/Reviews");
});

export const LazyTermcondition = lazy(() => {
  return import("./pages/Termcondition");
});

export const LazyDisclaimer = lazy(() => {
  return import("./pages/Disclaimer");
});

export const LazyCancelPayment = lazy(() => {
  return import("./pages/payment/components/CancelPayment");
});

export const LazyPaymentStatus = lazy(() => {
  return import("./pages/payment/components/PaymentStatus");
});

export const LazyNeuShopCart = lazy(() => {
  return import("./pages/neushop/cart/Cart");
});

export const LazyNeuShopCheckout = lazy(() => {
  return import("./pages/neushop/checkout/NeuShopCheckout");
});

export const LazyNeuShopItemDetails = lazy(() => {
  return import("./pages/neushop/itemDetails/Details");
});

export const LazyOneCardReading = lazy(() => {
  return import("./pages/tarot/OneCardReading");
});

export const LazyOneCardResult = lazy(() => {
  return import("./pages/tarot/OneCardResult");
});

export const LazyCelebrityList = lazy(() => {
  return import("./pages/Celebritylist");
});

export const LazyNewDetails = lazy(() => {
  return import("./pages/NewDetails");
});

export const LazyNmart = lazy(() => {
  return import("./pages/Nmart");
});

export const LazyEclipsesBlogDetail = lazy(() => {
  return import("./pages/EclipsesBlogDetail");
});

export const LazyNavagrahasBlog = lazy(() => {
  return import("./pages/NavagrahasBlog");
});

export const LazyVedicBlog = lazy(() => {
  return import("./pages/VedicBlog");
});

export const LazyFestivalDetail = lazy(() => {
  return import("./pages/festival/FestivalDetails");
});

export const LazyVastu = lazy(() => {
  return import("./pages/Vastu");
});

export const LazyLove = lazy(() => {
  return import("./pages/Love");
});

export const LazyZodiac = lazy(() => {
  return import("./pages/Zodiac");
});

export const LazyNeuAstroMartPage = lazy(() => {
  return import("./pages/NeuAstroMartPage");
});

export const LazyMahurt = lazy(() => {
  return import("./pages/Mahurt");
});

export const LazySpritual = lazy(() => {
  return import("./pages/Spritual");
});

export const LazyBlogDetail = lazy(() => {
  return import("./pages/blog/BlogDetail");
});

export const LazyOfflinePujaPayment = lazy(() => {
  return import("./pages/offlinePuja/OfflinePujaPayment");
});

export const LazyOfflinePujaCheckout = lazy(() => {
  return import("./pages/offlinePuja/OfflinePujaCheckout");
});

export const LazyBookOfflinePuja = lazy(() => {
  return import("./pages/offlinePuja/BookOfflinePuja");
});

export const LazyChatHistorySummary = lazy(() => {
  return import("./pages/ChatHistorySummary");
});

import React from "react";

const HomeBannerSecond = () => {
  return (
    <section>
      <div className="container-fluid-lg tw-mb-2">
        <div className="row">
          <div className="col-12">
            <div className="banner-contain">
              <div className="banner-contain hover-effect">
                <img
                  src="/assets/images/inner-page/downloadAppBanner1.webp"
                  className="bg-img blur-up lazyload img-fluid tw-object-contain"
                  alt="home_banner"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeBannerSecond;

import useAxiosPrivateHook from "../../hooks/useAxiosPrivate";
import apiUrl from "../../pages/config";
import ApiRequestType from "../apiRequestType";
import ApiRequestor from "../apiRequestor";

const UserLoginLogoutService = () => {
  const axiosPrivate = useAxiosPrivateHook();
  const requestor = new ApiRequestor(axiosPrivate);

  const logout = async () => {
    const url = `${apiUrl}/user/logout`;
    const apiResponse = await requestor.makeRequest(ApiRequestType.POST, url);
    return apiResponse;
  };
  return { logout };
};

export default UserLoginLogoutService;

import React from "react";
import { experienceFilterData } from "../../../constants/categoryFilters";
import { compareTwoValues } from "../../../constants/compareTwoValues";

const ExperienceFilter = ({
  selectedExperienceFilter,
  setSelectedExperienceFilter,
}) => {
  const handleExperienceFilter = (event) => {
    event.stopPropagation();
    const currentOption = event.target.name;
    const selectedOption =
      currentOption !== selectedExperienceFilter ? currentOption : false;
    setSelectedExperienceFilter(selectedOption);
  };

  return (
    <div className="single-option-box custom-scroll">
      {experienceFilterData.map((optionData) => (
        <a
          key={optionData.type}
          className={`dropdown-option ${
            compareTwoValues(selectedExperienceFilter, optionData.type)
              ? "tw-bg-gray-200 "
              : "hover:tw-bg-gray-100"
          }`}
          name={optionData.type}
          onClick={handleExperienceFilter}
        >
          {optionData.text}
        </a>
      ))}
    </div>
  );
};

export default ExperienceFilter;

/* eslint-disable react/prop-types */
import React from "react";
import { Link } from "react-router-dom";

const HoroscopeLinksComponent = ({ selectedComponent }) => {
  return (
    <div className="row tw-flex tw-items-center max-[568px]:tw-justify-between max-[568px]:tw-mb-1 max-[568px]:tw-mr-15 max-[1024px]:tw-mr-20">
      <div className="col-xxl-3 col-md-3 col-3 tw-flex max-[568px]:tw-w-fit max-[568px]:tw-px-0 max-[568px]:tw-justify-start">
        <Link to="/horoscope_daily">
          <div
            className={`title ${
              selectedComponent == "daily" ? "" : "unselected-title"
            }`}
          >
            <h2 className="max-[568px]:tw-text-[15px] max-[1024px]:tw-text-[20px]">
              Daily
            </h2>
          </div>
        </Link>
      </div>
      <div className="col-xxl-3 col-md-3 col-3 tw-flex max-[568px]:tw-px-0 max-[568px]:tw-w-fit max-[568px]:tw-justify-start">
        <Link to="/horoscope_page">
          <div
            className={`title ${
              selectedComponent == "weekly" ? "" : "unselected-title"
            }`}
          >
            <h2 className="max-[568px]:tw-text-[15px] max-[1024px]:tw-text-[20px]">
              Weekly
            </h2>
          </div>
        </Link>
      </div>
      <div className="col-xxl-3 col-md-3 col-3 tw-flex max-[568px]:tw-w-fit max-[568px]:tw-px-0 max-[568px]:tw-justify-start">
        <Link to="/horoscope_monthly">
          <div
            className={`title ${
              selectedComponent == "monthly" ? "" : "unselected-title"
            }`}
          >
            <h2 className="max-[568px]:tw-text-[15px] max-[1024px]:tw-text-[20px]">
              Monthly
            </h2>
          </div>
        </Link>
      </div>
      <div className="col-xxl-3 col-md-3 col-3 tw-flex max-[568px]:tw-w-fit max-[568px]:tw-px-0 max-[568px]:tw-justify-start">
        <Link to="/horoscope_yearly">
          <div
            className={`title ${
              selectedComponent == "yearly" ? "" : "unselected-title"
            }`}
          >
            <h2 className="max-[568px]:tw-text-[15px] max-[1024px]:tw-text-[20px]">
              Yearly
            </h2>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default HoroscopeLinksComponent;

import React from "react";

const KundliMilanBottomText = () => {
  return (
    <>
      <section>
        <div className="container-fluid-lg">
          <div className="title" style={{ padding: "5px" }}>
            <p>
              Kundli Milan, also known as Kundali Matching, is done through a
              process that involves comparing the birth charts (Kundlis) of two
              individuals, typically those considering marriage. Here&apos;s a
              general overview of how Kundli Milan is done:
            </p>
            <ul className="shifted-list">
              <li>
                <b>Gathering Birth Details:</b> The first step is to collect the
                accurate birth details of both individuals, including their
                date, time, and place of birth. These details are essential for
                generating accurate birth charts.
              </li>
              <li>
                <b>Generating Kundlis:</b> Using the birth details, astrologers
                or online software generate the Kundlis or birth charts for both
                individuals. Kundlis depict the positions of celestial bodies at
                the time of birth and are divided into twelve houses
                representing various aspects of life.
              </li>
              <li>
                <b>Assessing Guna Milan:</b> The primary method used in Kundli
                Milan is Guna Milan, which involves assigning points or Gunas
                based on the position of the Moon in the birth charts of both
                individuals. This process evaluates the compatibility of the
                couple based on 36 characteristics or Gunas.
              </li>
              <li>
                <b>Matching Gunas:</b> Each Guna has a specific weightage, and a
                total score out of 36 is calculated by comparing the Gunas of
                both individuals. A higher score indicates better compatibility,
                while a lower score may suggest potential challenges.
              </li>
              <li>
                <b>Checking Manglik Dosha:</b> Another aspect considered in
                Kundli Milan is Manglik Dosha, which occurs when Mars is placed
                in certain houses in the birth chart. Depending on the placement
                of Mars in the Kundlis of both individuals, remedies or
                precautions may be suggested to mitigate its effects.
              </li>
              <li>
                <b>Analyzing Other Factors:</b> In addition to Guna Milan and
                Manglik Dosha, astrologers may also consider other factors such
                as the placement of other planets, the presence of benefic or
                malefic aspects, and the overall harmony between the Kundlis.
              </li>
              <li>
                <b>Interpretation and Recommendation:</b> Based on the analysis
                of various factors, astrologers interpret the compatibility
                between the individuals and provide recommendations or guidance
                regarding the marriage alliance. They may suggest proceeding
                with the marriage, seeking remedies for any doshas, or advise
                against the alliance if significant challenges are indicated.
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default KundliMilanBottomText;

/* eslint-disable react/prop-types */
import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import HoroscopeService from "../api/services/horoscopeService";
import ZODIAC_PERIOD from "../constants/zodiacPeriods";

function ZodiacHoroscopeDetails({ zodiacDetail, period }) {
  const { getZodiac, getMonthlyZodiac } = HoroscopeService();
  const [horoscopeDetails, setHoroscopeDetails] = useState(null);

  useEffect(() => {
    if (period === ZODIAC_PERIOD.MONTHLY) {
      fetchMonthlyZodiacDetails(zodiacDetail);
      return;
    }
    fetchZodiacHoroscopeDetails(zodiacDetail);
  }, [zodiacDetail]);

  const fetchMonthlyZodiacDetails = async (zodiacDetail) => {
    if (!zodiacDetail) {
      return;
    }
    const month = new Date().getMonth() + 1;
    try {
      const { result, data } = await getMonthlyZodiac(zodiacDetail.id, month);
      if (!result) {
        setHoroscopeDetails("Coming soon!");
        return;
      }
      setHoroscopeDetails(data);
    } catch (error) {
      setHoroscopeDetails("Coming soon!");
    }
  };

  const fetchZodiacHoroscopeDetails = async (zodiacDetail) => {
    if (!zodiacDetail) {
      return;
    }
    try {
      const { result, data } = await getZodiac(period, zodiacDetail.id);
      if (!result) {
        setHoroscopeDetails("Coming soon!");
        return;
      }
      setHoroscopeDetails(data);
    } catch (error) {
      setHoroscopeDetails("Coming soon!");
    }
  };

  return (
    <section className="seller-grid-section">
      <div className="container-fluid-lg">
        <div className="row g-4">
          <div className="col-xxl-12 col-md-12">
            <Link to="#" className="seller-grid-box">
              <div className="grid-contain">
                <div
                  className="title !tw-flex !tw-gap-x-2"
                  style={{ padding: "5px" }}
                >
                  <img
                    className="img2 lazyload"
                    src={`../assets/images/astro/${zodiacDetail?.name.toLowerCase()}.png`}
                    alt="zodiac"
                    loading="lazy"
                    width="130"
                    height="130"
                    style={{ marginRight: "20px" }}
                  />
                  <p style={{ whiteSpace: "pre-wrap" }}>{horoscopeDetails}</p>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <nav className="custome-pagination"></nav>
      </div>
    </section>
  );
}

export default ZodiacHoroscopeDetails;

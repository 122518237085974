/* eslint-disable react/prop-types */
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Navigate } from "react-router-dom";
import AuthContext from "../../context/AuthProvider";
import UserLoginLogoutService from "../../api/services/userLoginLogoutService";
import { scrollToTop } from "../../utils/scrollToTop";
import HeaderModal from "./HeaderModal";
import Navbar from "./Navbar";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { IoCartOutline } from "react-icons/io5";
import { useSelector } from "react-redux";

const Header = ({ _onLanguageChange }) => {
  const navigate = useNavigate();
  const { auth, setAuth } = useContext(AuthContext);
  const { logout } = UserLoginLogoutService();
  const [showHamBurger, setShowHamburger] = useState(false);
  const location = useLocation();
  const totalItems = useSelector((store) => store.cart.totalItems);

  const handleHamburgerClick = () => {
    if (!showHamBurger) {
      document.body.style.width = "100%";
      document.body.style.height = "100%";
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.width = "100%";
      document.body.style.height = "100%";
      document.body.style.overflowY = "scroll";
    }
    setShowHamburger(!showHamBurger);
  };

  const logoutHandler = () => {
    if (showHamBurger) {
      handleHamburgerClick();
    }

    setAuth(null);
    logout();
    return <Navigate to="/login" />;
  };

  return (
    <>
      <header className={`header-position ${"header-sticky"}`}>
        <HeaderModal
          handleHamburgerClick={handleHamburgerClick}
          logoutHandler={logoutHandler}
          showHamBurger={showHamBurger}
        />
        <div className="top-nav top-header">
          <div className="container-fluid-lg main-header-container">
            <div className="w-full">
              <div className="w-full">
                <div className="navbar-top">
                  <div className="top-header-box">
                    <div
                      className="flex-item-center flex-column hamburger lg:tw-hidden"
                      onClick={handleHamburgerClick}
                    >
                      <span className="hamburger-line"></span>
                      <span className="hamburger-line"></span>
                      <span className="hamburger-line"></span>
                    </div>
                    <Link
                      to="/"
                      className="web-logo nav-logo"
                      onClick={scrollToTop}
                    >
                      <img
                        src="/assets/images/logo/website_logo_2.png"
                        className="img-fluid blur-up lazyload header-logo"
                        alt="neuastro-logo"
                        loading="lazy"
                      />
                    </Link>
                  </div>

                  <div className="rightside-box">
                    <div className="right-side-menu">
                      {location.pathname?.includes("/neu_shop") && (
                        <div className="tw-mr-3">
                          <div>
                            <NavLink to="neu_shop/cart">
                              <div className="tw-relative ">
                                <div className=" tw-absolute -tw-mt-1 tw-ml-4 tw-bg-green-600 tw-rounded-full tw-text-white tw-w-4 tw-h-4 sm:tw-h-5 sm:tw-w-5 tw-flex tw-p-1 tw-text-xs tw-justify-center tw-items-center tw-animate-bounce">
                                  {<span>{totalItems}</span>}
                                </div>
                                <IoCartOutline size="30" />
                              </div>
                            </NavLink>
                          </div>
                        </div>
                      )}
                      <div className="about-list right-nav-about top-right-header">
                        <div className="right-nav-list">
                          <div className="dropdown theme-form-select">
                            <Link
                              to="/expert/"
                              target="_blank"
                              className="light-text header-link-hover tw-text-[9px] min-[480px]:tw-text-xs tw-gap-x-1 sm:tw-text-sm tw-flex tw-flex-col min-[480px]:tw-flex-row tw-font-semibold tw-transition-all tw-duration-500 tw-ease-linear tw-text-dark-blue min-[480px]:tw-py-1.5 tw-py-0.5 tw-rounded tw-px-2 tw-bg-theme-color hover:tw-bg-light-yellow hover:tw-shadow-neu-theme-inner"
                            >
                              <span className="tw-inline-block max-[480px]:tw-tracking-wider">
                                Register as
                              </span>
                              <span className="tw-inline-block max-[480px]:tw-tracking-wider">
                                Astrologer
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="right-side tw-inline-block"></div>

                      {auth?.userId ? (
                        <div>
                          <div className="tw-flex lg:tw-gap-2">
                            <Link to="/profile">
                              <div className="delivery-login-box profile-icon-box">
                                <div className="delivery-icon">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-user tw-text-theme-color"
                                  >
                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                    <circle cx="12" cy="7" r="4"></circle>
                                  </svg>
                                </div>
                              </div>
                            </Link>

                            <Link to="/" className="logout-box">
                              <button
                                onClick={logoutHandler}
                                className="btn public-font-family tw-px-2 lg:tw-px-4 tw-py-1.5 cart-button login-btn btn-hover tw-text-xs sm:tw-text-sm hover:tw-bg-opacity-50 hover:tw-shadow-neu-blue-inner hover:tw-text-dark-blue tw-transition-all tw-duration-500 tw-ease-linear"
                              >
                                LogOut
                              </button>
                            </Link>
                          </div>
                        </div>
                      ) : (
                        <div className="login-box">
                          <button
                            onClick={() => {
                              navigate("/login");
                              scrollToTop();
                            }}
                            className="tw-text-[9px] tw-border-0 min-[480px]:tw-items-center tw-justify-between tw-items-start tw-rounded min-[480px]:tw-text-xs tw-gap-x-1 sm:tw-text-sm tw-flex tw-flex-col min-[480px]:tw-flex-row tw-px-2 lg:tw-px-4 public-font-family tw-py-1.5 cart-button login-btn btn-hover hover:tw-bg-opacity-50 hover:tw-shadow-neu-blue-inner tw-transition-all tw-duration-500 tw-ease-linear"
                          >
                            <span className="tw-inline-block max-[480px]:tw-tracking-wider">
                              Login /
                            </span>
                            <span className="tw-inline-block max-[480px]:tw-tracking-wider">
                              SignUp
                            </span>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <Navbar />
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;

import React from "react";
import { priceFilterData } from "../../../constants/categoryFilters";
import { compareTwoValues } from "../../../constants/compareTwoValues";

const PriceFilter = ({ selectedPriceFilter, setSelectedPriceFilter }) => {
  const handlePriceFilter = (event) => {
    event.stopPropagation();
    const currentOption = event.target.name;
    const selectedOption =
      currentOption !== selectedPriceFilter ? currentOption : false;
    setSelectedPriceFilter(selectedOption);
  };

  return (
    <div className="single-option-box custom-scroll">
      {priceFilterData.map((priceOption) => (
        <a
          key={priceOption.type}
          className={`dropdown-option ${
            compareTwoValues(selectedPriceFilter, priceOption.type)
              ? "tw-bg-gray-200 "
              : "hover:tw-bg-gray-100"
          }`}
          name={priceOption.type}
          onClick={handlePriceFilter}
        >
          {priceOption.text}
        </a>
      ))}
    </div>
  );
};

export default PriceFilter;

import React from "react";

const Astrology = () => {
  return (
    <>
      <section>
        <div className="container-fluid-lg">
          <div className="title" style={{ padding: "5px" }}>
            <div style={{ textAlign: "center" }}>
              <h2>Astrology</h2>
            </div>
            <p>
              Astrology is an ancient science which helps us improve our karma
              by telling us what our destiny is going to be. It is not that we
              are consigned to accept bad situations in life; Astrology is a
              kind of tool to help us correct and resurrect our Life through
              Good deeds. Astrology is the science of gaining proficiency over
              deed &amp; time management which helps us manage our life in a
              better way.
            </p>
            <p>
              By analysing the horoscope and understanding the dharma, artha,
              kama and moksha, one can gains insight into the individual’s basic
              instincts. He would also be able to suggest a better lifestyle and
              spiritual sadhana. The three gunas—sattva, rajas and tamas coexist
              in a varying degree of influence that is also understood from the
              chart. The gunas suggest the nature of all ones thoughts, feelings
              and actions, so the sadhana is suggested accordingly. For example,
              Sativ, Rajasic, Tamasic persons are suggested yoga and meditation,
              devotion, Selfless service respectively.
            </p>
            <p>
              It is just the knowledge of karma which helps the individual take
              ownership for the circumstances in which he finds himself and let
              go of resentment at the misfortunes indicated in the chart which
              is a mere messenger of one’s karma or in other word an opportunity
              to correct one’s life.
            </p>
            <p>
              Vedic astrology is based on mathematical divisions of the zodiac
              and defined relationships between planetary locations. The zodiac
              is a fine band across the sky through which the sun, moon and
              planets travel, expressing various influences, both physical and
              subtle. The main zodiac division used is made of twelve signs, or
              rashis, of 30 degrees each.
            </p>
            <p>Key components of astrology include:</p>

            <ul className="shifted-list">
              <li>
                <b>Zodiac Signs:</b> The zodiac is divided into twelve signs,
                each associated with specific personality traits and
                characteristics. The position of the sun at the time of a
                person&apos;s birth determines their zodiac sign.
              </li>
              <li>
                <b>Planets:</b> Astrology involves the analysis of the positions
                and movements of celestial bodies, including the Sun, Moon,
                Mercury, Venus, Mars, Jupiter, Saturn, Uranus, Neptune, and
                Pluto. Each planet is believed to influence different aspects of
                life.
              </li>
              <li>
                <b>Houses:</b> The astrological chart is divided into twelve
                houses, each representing different areas of life, such as
                relationships, career, and home.
              </li>
              <li>
                <b>Aspects:</b> Astrologers study the angles (aspects) between
                planets, considering how their positions relative to each other
                might influence an individual&apos;s traits or life events.
              </li>
              <li>
                <b>Transits:</b> The study of how current positions of celestial
                bodies affect an individual&apos;s natal chart and,
                consequently, their life.
              </li>
              <li>
                <b>Nakshatra:</b> Nakshatras are lunar mansions or star clusters
                used in Hindu astrology. They play a significant role in Vedic
                astrology and are part of the broader system of dividing the
                zodiac. There are 27 Nakshatras, each associated with specific
                qualities and characteristics.
              </li>
            </ul>
            <br />
            <p>Astrology is often used for various purposes, including:</p>
            <ul className="shifted-list">
              <li>
                <b>Natal Astrology:</b> Analyzing an individual&apos;s birth
                chart to gain insights into their personality, strengths,
                challenges, and potential life path.
              </li>
              <li>
                <b>Horoscope Readings:</b> Providing forecasts and insights
                based on the positions of celestial bodies at a specific time,
                often daily, weekly, monthly, or yearly.
              </li>
              <li>
                <b>Compatibility Assessments:</b> Evaluating the compatibility
                between individuals based on their astrological profiles.
              </li>
            </ul>
            <br />
            <p>
              <b>Rashi (Zodiac Signs)</b>
            </p>
            <p>
              Here are the twelve zodiac signs (based on western astrology)
              along with brief definitions:
            </p>
            <ul className="shifted-list">
              <li>
                <b>Aries:</b> A fire sign associated with traits like
                enthusiasm, leadership, and assertiveness.
              </li>
              <li>
                <b>Aries:</b> A fire sign associated with traits like
                enthusiasm, leadership, and assertiveness.
              </li>
              <li>
                <b>Taurus:</b> An earth sign known for its practicality,
                stability, and determination.
              </li>
              <li>
                <b>Gemini:</b> An air sign characterized by adaptability,
                curiosity, and effective communication skills.
              </li>
              <li>
                <b>Cancer:</b> A water sign associated with emotional depth,
                intuition, and a strong sense of loyalty.
              </li>
              <li>
                <b>Leo:</b> A fire sign known for qualities such as confidence,
                generosity, and a love for the spotlight.
              </li>
              <li>
                <b>Virgo:</b> An earth sign recognized for attention to detail,
                practicality, and analytical abilities.
              </li>
              <li>
                <b>Libra:</b> An air sign associated with traits like diplomacy,
                fairness, and a love for harmony.
              </li>
              <li>
                <b>Scorpio:</b> A water sign characterized by intensity,
                passion, and a mysterious aura.
              </li>
              <li>
                <b>Sagittarius:</b> A fire sign known for optimism, a love for
                adventure, and a philosophical outlook.
              </li>
              <li>
                <b>Capricorn:</b> An earth sign associated with ambition,
                discipline, and a practical approach to life.
              </li>
              <li>
                <b>Aquarius:</b> An air sign recognized for independence,
                originality, and humanitarian values.
              </li>
              <li>
                <b>Pisces:</b> A water sign characterized by empathy,
                creativity, and a dreamy nature.
              </li>
            </ul>
            <br />
            <p>
              <b>Lagna</b>
            </p>
            <p>
              The Earth rotates at about one sign every two hours, causing the
              signs and planets in them to rise in the east and set in the west.
              The point of the sign rising in the east forms the cusp of the
              first house (bhava). This is the ascendant, rising sign or lagna,
              which determines the orientation of the chart as a whole.{" "}
            </p>
            <br />
            <p>
              <b>Nakshatra</b>
            </p>
            <p>
              Nakshatras are lunar mansions or star clusters used in Hindu
              astrology. They play a significant role in Vedic astrology and are
              part of the broader system of dividing the zodiac. There are 27
              Nakshatras, each associated with specific qualities and
              characteristics.
            </p>
            <p>
              In Vedic astrology, the position of the Moon at the time of a
              person&apos;s birth is crucial, and Nakshatras provide more
              detailed information about the Moon&apos;s placement. Each
              Nakshatra is associated with a ruling planet, and they are further
              divided into four quarters known as Padas.
            </p>
            <p>
              The Nakshatras hold cultural and religious significance in Hindu
              traditions, often influencing various aspects of life, including
              naming ceremonies, marriage compatibility assessments, and other
              auspicious events. However, it&apos;s essential to understand that
              Nakshatras and Vedic astrology, in general, are rooted in cultural
              beliefs rather than scientific validation.
            </p>
            <p>
              here are 27 Nakshatras in Hindu astrology. These Nakshatras are
              segments or divisions of the ecliptic, and each Nakshatra is
              associated with specific qualities and characteristics. The
              Moon&apos;s position at the time of an individual&apos;s birth is
              often analyzed with respect to these Nakshatras to provide more
              detailed insights in Vedic astrology.
            </p>
            <p>
              1. Ashwini: Ruled by Ketu, associated with dynamic and energetic
              qualities.
            </p>
            <p>
              2. Bharani: Ruled by Venus, linked to creativity and nurturing
              aspects.
            </p>
            <p>
              3. Krittika: Ruled by the Sun, known for determination and fiery
              energy.
            </p>
            <p>
              4. Rohini: Ruled by Moon, associated with fertility and growth.
            </p>
            <p>
              5. Mrigashira: Ruled by Mars, linked to curiosity and exploration.
            </p>
            <p>
              6. Ardra: Ruled by Rahu, connected to transformation and change.
            </p>
            <p>
              7. Punarvasu: Ruled by Jupiter, associated with renewal and
              expansion.
            </p>
            <p>
              8. Pushya: Ruled by Saturn, known for stability and nourishment.
            </p>
            <p>
              9. Ashlesha: Ruled by Mercury, associated with intuitive and
              secretive traits.
            </p>
            <p>10. Magha: Ruled by Ketu, linked to authority and power.</p>
            <p>
              11. Purva Phalguni: Ruled by Venus, connected to love and
              creativity.
            </p>
            <p>
              12. Uttara Phalguni: Ruled by the Sun, associated with virtue and
              duty.
            </p>
            <p>13. Hasta: Ruled by Moon, linked to precision and skill.</p>
            <p>
              14. Chitra: Ruled by Mars, known for artistic and aesthetic
              qualities.
            </p>
            <p>
              15. Swati: Ruled by Rahu, associated with independence and
              freedom.
            </p>
            <p>
              16. Vishakha: Ruled by Jupiter, linked to determination and
              leadership.
            </p>
            <p>
              17. Anuradha: Ruled by Saturn, known for discipline and focus.
            </p>
            <p>
              18. Jyeshtha: Ruled by Mercury, associated with wisdom and
              protection.
            </p>
            <p>
              19. Mula: Ruled by Ketu, connected to destruction and
              transformation.
            </p>
            <p>
              20. Purva Ashadha: Ruled by Venus, linked to perseverance and
              achievement.
            </p>
            <p>
              21. Uttara Ashadha: Ruled by the Sun, known for success and
              strength.
            </p>
            <p>
              22. Shravana: Ruled by Moon, associated with learning and
              listening.
            </p>
            <p>23. Dhanishta: Ruled by Mars, linked to wealth and abundance.</p>
            <p>
              24. Shatabhisha: Ruled by Rahu, known for healing and mystical
              qualities.
            </p>
            <p>
              25. Purva Bhadrapada: Ruled by Jupiter, associated with spiritual
              growth.
            </p>
            <p>
              26. Uttara Bhadrapada: Ruled by Saturn, connected to compassion
              and empathy.
            </p>
            <p>
              27. Revati: Ruled by Mercury, known for creativity and gentleness.
            </p>
            <p>
              These descriptions are brief and provide only a glimpse into the
              characteristics associated with each Nakshatra. For a more
              in-depth analysis, Pls consult Vedic astrology experts on
              NeuAstro.
            </p>
            <p>
              <b>Navagraha</b>
            </p>
            <p>
              Navagraha are nine heavenly bodies (as well as deities) that
              affects human life as per vedic astrology. The term is derived
              from nava (&quot;nine&quot;) and Graha (planet).
            </p>
            <p>The Navaraghas are:-</p>
            <ul className="shifted-list">
              <li>Surya: the Sun</li>
              <li>Chandra: the Moon</li>
              <li>Mangala: Mars</li>
              <li>Budha: Mercury</li>
              <li>Bṛhaspati: Jupiter</li>
              <li>Shukra: Venus</li>
              <li>Shani: Saturn</li>
              <li>Rahu: Shadow body</li>
              <li>Ketu: Shadow body</li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default Astrology;

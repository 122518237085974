import React from "react";

const LanguagesFilter = ({
  languageOptions,
  setLanguageOptions,
  languagesFilterData,
}) => {
  const handleOnLanguageClick = (event) => {
    const currentOption = event.target.name;
    const currentValue = event.target.value;
    setLanguageOptions((previousValue) => ({
      ...previousValue,
      [currentOption]: languageOptions[currentOption] ? false : currentValue,
    }));
  };

  const handleSelectAllLanguages = (event) => {
    event.stopPropagation();
    const allLanguagesDictionary = languagesFilterData.reduce(
      (acc, currentData) => {
        acc[currentData.lang] = currentData.lang;
        return acc;
      },
      {}
    );
    setLanguageOptions({ ...allLanguagesDictionary });
  };

  const handleClearLanguages = (event) => {
    event.stopPropagation();
    setLanguageOptions({});
  };

  return (
    <div className="tw-px-0">
      <div className="category-filter-box custom-scroll">
        <div className="category-action-box">
          <span
            className="category-action-tab"
            onClick={handleSelectAllLanguages}
          >
            Select all
          </span>
          <span className="category-action-tab" onClick={handleClearLanguages}>
            Clear
          </span>
        </div>
        {languagesFilterData?.map((languageData) => (
          <label
            key={languageData._id}
            htmlFor={languageData.lang}
            className="flex-center category-option-lable"
          >
            <div className="checkbox-container tw-mb-[18px]">
              <input
                type="checkbox"
                className="checkbox-input"
                id={languageData.lang}
                name={languageData.lang}
                value={languageData.lang}
                onChange={handleOnLanguageClick}
              />
              <span
                className={`checkmark ${
                  languageOptions[languageData.lang]
                    ? "checkmark-checked"
                    : "tw-bg-gray-200"
                } `}
              ></span>
            </div>
            <p className="category-option-para">{languageData.lang}</p>
          </label>
        ))}
      </div>
    </div>
  );
};

export default LanguagesFilter;

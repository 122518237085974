import React from "react";
import { Link, useNavigate } from "react-router-dom";

const Breadcrumb = ({ routes }) => {
  const navigate = useNavigate();
  const navigateToBack = () => {
    navigate(-1);
  };

  return (
    <div className="tw-w-full tw-text-sm sm:tw-text-base tw-font-semibold sm:tw-font-bold tw-flex tw-gap-1">
      <Link to="/" className="hover:tw-text-gray-400">
        Home
      </Link>
      <span>/</span>
      {routes.map((route) => {
        return (
          <React.Fragment key={route.text}>
            {route.path ? (
              <>
                <Link to={`/${route.path}`} className="hover:tw-text-gray-400">
                  {route.text}
                </Link>
                <span>/</span>
              </>
            ) : route.navigate ? (
              <>
                <span
                  to="#"
                  onClick={navigateToBack}
                  className="tw-text-gold-color tw-cursor-pointer hover:tw-text-gray-400"
                >
                  {route.text}
                </span>
                <span>/</span>
              </>
            ) : (
              <span key={route.text}>{route.text}</span>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Breadcrumb;

import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../context/AuthProvider";

const RequireAuth = () => {
  const { auth } = useContext(AuthContext);
  const location = useLocation();

  return auth?.userId ? (
    <Outlet />
  ) : (
    <Navigate
      to="/login"
      state={{ pathname: location.pathname, ...location.state }}
      replace
    />
  );
};

export default RequireAuth;

import React, { useState } from "react";
import SearchBar from "./SearchBar";
import Filter from "./Filter";

const NeuShopHeader = ({
  setProductsList,
  setFilterValue,
  setPageFlag,
  pageRef,
  productFilterFlag,
  resetFilter,
  pageFlag,
}) => {
  const [searchQuery, setSearchQuery] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");

  const handleOnResetFilter = async () => {
    await resetFilter();
    setSearchQuery("");
    setSelectedCategory("");
    if (!pageFlag) setPageFlag(true);
  };

  return (
    <div className="tw-w-full tw-z-10 tw-bg-white tw-border-b-gray-200 tw-border-solid tw-border-0 tw-border-b">
      <div className="tw-flex tw-flex-col sm:tw-flex-row sm:tw-items-center sm:tw-justify-between tw-gap-y-2.5 md:tw-px-8 tw-px-4 tw-mb-4 tw-bg-white tw-pt-6">
        <div className="tw-flex-1 tw-flex tw-gap-x-2 sm:tw-gap-x-4">
          <SearchBar
            setProductsList={setProductsList}
            setFilterValue={setFilterValue}
            setPageFlag={setPageFlag}
            pageRef={pageRef}
            productFilterFlag={productFilterFlag}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            pageFlag={pageFlag}
          />
          <div>
            <button
              className="tw-border-none tw-shadow-lg hover:tw-shadow tw-transition-all hover:tw-opacity-70 tw-bg-theme-color tw-py-1 tw-rounded tw-text-[15px] tw-w-16 flex-item-center"
              onClick={handleOnResetFilter}
            >
              Reset
            </button>
          </div>
        </div>
        <Filter
          setProductsList={setProductsList}
          setFilterValue={setFilterValue}
          setPageFlag={setPageFlag}
          pageRef={pageRef}
          productFilterFlag={productFilterFlag}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
          pageFlag={pageFlag}
        />
      </div>
    </div>
  );
};

export default NeuShopHeader;

import { useEffect } from "react";
import React from "react";
import HomeBanner from "./components/HomeBanner";
import HomeBannerSecond from "../HomeBannerSecond";
import WeeklyHoroscopeZodiac from "./components/WeeklyHoroscopeZodiac";
import Panchang from "./components/Panchang";
// import OnlinePuja from "./components/OnlinePuja";
import LatestBlog from "./components/LastestBlog";
// import Reviews from "../Reviews";
import Astrology from "./components/Astrology";
// import News from "./components/News";
import Ywkukun from "./components/Ywkukun";
import { scrollToTop } from "../../utils/scrollToTop";
import ActiveAstrologerWrapper from "./components/ActiveAstrologerWrapper";
import FeaturePanel from "../../components/FeaturePanel";
import OfflinePuja from "./components/OfflinePuja";
import NeushopSlider from "./components/NeushopSlider";

function Home() {
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <HomeBanner />
      <FeaturePanel />
      <div className="lg:tw-mt-16 tw-mt-8" />
      <HomeBannerSecond />
      <ActiveAstrologerWrapper />
      <WeeklyHoroscopeZodiac />
      <Ywkukun />
      <Panchang />
      {/* <NakshatraMart /> */}
      <OfflinePuja />
      {/* <OnlinePuja /> */}
      <NeushopSlider />
      <LatestBlog />
      {/* <Reviews /> */}
      <Astrology />
      {/* <Celebrity/> */}
      {/* <News /> */}
    </>
  );
}

export default Home;

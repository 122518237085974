import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import BirthDetailsForm from "../../../components/BirthDetailsForm";
import { scrollToTop } from "../../../utils/scrollToTop";
import LatLngTimezoneService from "../../../api/services/latLngTimezoneService";
import { convertToLocalDate } from "../../../utils/utcToLocalTimeConvertor";

export default function KundaliMilanForm() {
  const navigate = useNavigate();
  const firstFormIdentifier = "firstForm";
  const secondFormIdentifier = "secondForm";
  const [error, setError] = useState("");
  const firstFormData = useRef({});
  const secondFormData = useRef({});
  const { getTimezone } = LatLngTimezoneService();

  const handleInputChange = (name, value, formIdentifier) => {
    if (formIdentifier === firstFormIdentifier) {
      firstFormData.current[name] = value;
    } else {
      secondFormData.current[name] = value;
    }
  };
  const createMaleData = (data) => {
    return {
      m_day: data.day,
      m_month: data.month,
      m_year: data.year,
      m_hour: data.hour,
      m_min: data.min,
      m_lat: data.lat,
      m_lon: data.lng,
      m_name: data.name,
      m_gender: data.gender,
      m_address: data.address,
    };
  };
  const createFemaleData = (data) => {
    return {
      f_day: data.day,
      f_month: data.month,
      f_year: data.year,
      f_hour: data.hour,
      f_min: data.min,
      f_lat: data.lat,
      f_lon: data.lng,
      f_name: data.name,
      f_gender: data.gender,
      f_address: data.address,
    };
  };

  const createFormData = () => {
    let maleData =
      firstFormData.current.gender == "male"
        ? createMaleData(firstFormData.current)
        : createMaleData(secondFormData.current);
    let femaleData =
      firstFormData.current.gender == "female"
        ? createFemaleData(firstFormData.current)
        : createFemaleData(secondFormData.current);
    return { ...maleData, ...femaleData };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = createFormData();

    if (!formData.m_day) {
      setError("Please Select a Male Birth Day.");
      return;
    }

    if (!formData.m_month) {
      setError("Please Select a Male Birth Month .");
      return;
    }

    if (!formData.m_year) {
      setError("Please select a Male Birth year.");
      return;
    }

    if (!formData.m_hour) {
      setError("Please select a Male Birth Hour.");
      return;
    }

    if (!formData.m_min) {
      setError("Please a Male Birth minute.");
      return;
    }

    if (!formData.m_name) {
      setError("Please Enter a Male Name.");
      return;
    }

    if (!formData.m_gender) {
      setError("Please select a  Gender.");
      return;
    }

    if (!formData.m_address) {
      setError("Please select a BirthPlace of Male.");
      return;
    }

    if (!formData.f_day) {
      setError("Please Select a FeMale Birth Day.");
      return;
    }
    if (!formData.f_month) {
      setError("Please Select a FeMale Birth Month .");
      return;
    }
    if (!formData.f_year) {
      setError("Please select a FeMale Birth year.");
      return;
    }

    if (!formData.f_hour) {
      setError("Please select a FeMale Birth Hour.");
      return;
    }
    if (!formData.f_min) {
      setError("Please Enter a FeMale Birth minute.");
      return;
    }
    if (!formData.f_name) {
      setError("Please Enter a FeMale Name.");
      return;
    }
    if (!formData.f_gender) {
      setError("Please select a Gender.");
      return;
    }

    if (!formData.f_address) {
      setError("Please select a BirthPlace of Female.");
      return;
    }

    let f_timezone = "";
    let m_timezone = "";
    try {
      const f_dob = convertToLocalDate(
        formData.f_year,
        formData.f_month,
        formData.f_day
      );
      const m_dob = convertToLocalDate(
        formData.m_year,
        formData.m_month,
        formData.m_day
      );
      const fetchTimezones = await Promise.all([
        getTimezone(formData.f_lat, formData.f_lon, f_dob),
        getTimezone(formData.m_lat, formData.m_lon, m_dob),
      ]);

      const [femaleTimezoneDetails, maleTimezoneDetails] = fetchTimezones;
      f_timezone = femaleTimezoneDetails.timezone?.toString();
      m_timezone = maleTimezoneDetails.timezone?.toString();
    } catch (error) {
      setError("Faced an issue in fetching timezone!");
    }

    const data = {
      m_day: Number(formData.m_day),
      m_month: Number(formData.m_month),
      m_year: Number(formData.m_year),
      m_hour: Number(formData.m_hour),
      m_min: Number(formData.m_min),
      m_lat: parseFloat(formData.m_lat),
      m_lon: parseFloat(formData.m_lon),
      m_name: formData.m_name,
      m_address: formData.m_address,
      m_tzone: m_timezone,
      f_day: Number(formData.f_day),
      f_month: Number(formData.f_month),
      f_year: Number(formData.f_year),
      f_hour: Number(formData.f_hour),
      f_min: Number(formData.f_min),
      f_lat: parseFloat(formData.f_lat),
      f_lon: parseFloat(formData.f_lon),
      f_name: formData.f_name,
      f_address: formData.f_address,
      f_tzone: f_timezone,
    };

    navigate("/kundali_report", { state: { responseDataofbirth: data } });
    scrollToTop();
  };

  return (
    <section className="seller-grid-section" id="kundali-milan-form">
      <div className="container-fluid-lg">
        <h2>Fill Your&apos;s And Partner&apos;s Details</h2>
        <br />

        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="seller-grid-box">
              <div className="grid-contain">
                <div className="col-lg-12">
                  <div className="right-sidebar-box">
                    <font>
                      {error && <p className="tw-text-red-500">{error}</p>}
                    </font>
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        {/* Male Details */}
                        <div className="col-lg-6 col-md-6">
                          <BirthDetailsForm
                            propogateInputChanges={handleInputChange}
                            formIdentifier={firstFormIdentifier}
                          />
                        </div>
                        <div className="col-lg-6 col-md-6">
                          {/* Female Details */}
                          <BirthDetailsForm
                            propogateInputChanges={handleInputChange}
                            formIdentifier={secondFormIdentifier}
                          />
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn tw-bg-theme-color btn-md fw-bold tw-rounded-lg tw-text-white tw-shadow-lg hover:tw-shadow-sm tw-mt-6 tw-w-fit hover:tw-bg-opacity-80 tw-mx-auto"
                        style={{ marginTop: "10px" }}
                      >
                        Match Kundli
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

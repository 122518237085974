import axios from "../api/axios";

const useAxiosHook = () => {
  const setAuthHeader = (authToken) => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
    return axios;
  };

  return { axios, setAuthHeader };
};

export default useAxiosHook;

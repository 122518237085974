import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./context/AuthProvider";
import "./index.css";
import { Provider } from "react-redux";
import { store } from "./redux/Store";
import { Toaster } from "react-hot-toast";
import GTMSetup from "./components/GTMSetup";
import { googleTagManagerKey } from "./pages/config";
import { HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      {googleTagManagerKey && <GTMSetup />}
      <Toaster />
      <HelmetProvider>
        <AuthProvider>
          <Provider store={store}>
            <Routes>
              <Route path="/*" element={<App />} />
            </Routes>
          </Provider>
        </AuthProvider>
      </HelmetProvider>
    </BrowserRouter>
  </React.StrictMode>
);

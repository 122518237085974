import { useContext, useEffect } from "react";
import { axiosPrivate } from "../api/axios";
import useRefreshToken from "./useRefreshToken";
import AuthContext from "../context/AuthProvider";

const useAxiosPrivateHook = () => {
    const { auth } = useContext(AuthContext);
    const refresh = useRefreshToken();

    // Define request/response and error interceptors
    const reqResInterceptor = (config) => {
        config.headers['Authorization'] = `Bearer ${auth?.authToken}`;
        return config;
    };

    const reqErrInterceptor = async (error) => Promise.reject(error);

    const resResInterceptor = async (response) => {
        // Handle successful responses as needed
        return response;
    };

    const resErrInterceptor = async (error) => {
        const originalRequest = error.config;
        if (error.response.result === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            try {
                const newAccessToken = await refresh();
                if (!newAccessToken) { return Promise.reject(error); }
                originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                return axiosPrivate(originalRequest);
            } catch (error) {
                console.error('Token refresh failed', error);
            }
        }
        return Promise.reject(error);
    };

    // Set up the interceptors with useEffect
    useEffect(() => {
        const reqInterceptor = axiosPrivate.interceptors.request.use(
            reqResInterceptor,
            reqErrInterceptor,
        );

        const resInterceptor = axiosPrivate.interceptors.response.use(
            resResInterceptor,
            resErrInterceptor,
        );

        // Cleanup function
        return () => {
            axiosPrivate.interceptors.request.eject(reqInterceptor);
            axiosPrivate.interceptors.response.eject(resInterceptor);
        };
    }, [auth]);

    return axiosPrivate;
};

export default useAxiosPrivateHook


import apiUrl from "../../pages/config";
import ApiRequestType from "../apiRequestType";
import ApiRequestor from "../apiRequestor";
import axios from "../axios";

const LatLngTimezoneService = () => {
  const requestor = new ApiRequestor(axios);

  const getTimezone = async (latitude, longitude, birthDate) => {
    const reqData = {
      "latitude": parseFloat(latitude),
      "longitude": parseFloat(longitude),
      "date": birthDate,
    }
    const url = `${apiUrl}/ext/astrology/timezone_with_dst`;
    const apiResponse = await requestor.makeRequest(
      ApiRequestType.POST,
      url,
      reqData
    );
    if (apiResponse.result) return apiResponse.data;
    return null;
  };

  return {
    getTimezone,
  };
};

export default LatLngTimezoneService;
